import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  MIDDLE_WARE_BASE_URL,
  CALL_PPOR_API,
} from "../../../../utils/CONSTANTS";

const initialState = {
  empDetailsState: "pending",
  profileData: null,
};

export const empProfile: any = createAsyncThunk(
  "get/empProfile",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), //NON_LOGIN_CALLS_API(), // change it to CALL_PPOR_API
      data
    );
    return response;
  }
);

const empProfileSlice = createSlice({
  name: "empProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(empProfile.pending, (profileState) => {
        profileState.empDetailsState = "pending";
      })
      .addCase(empProfile.fulfilled, (profileState, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        profileState.empDetailsState = "fulfilled";
        // console.log(tempRes?.response);
        profileState.profileData = tempRes?.response?.PersonalDet?.result;

        // console.log(profileState.profileData);
      })
      .addCase(empProfile.rejected, (profileState) => {
        profileState.empDetailsState = "failed";
      }),
});

// export const state = (state: any) => state["profile"].state;
// export const empProfileData = (state: any) => state.empProfile;
// export const empProfileData = (state: any) => state["profile"].profileData;

export const empProfileDetails = (state: any) => state.empProfile;

export default empProfileSlice.reducer;
