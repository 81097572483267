import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  empProfileDetails,
  empProfile,
} from "../ProfileLanding/Profile/ProfileSlice";
import { updateImage } from "../profilePage/uploadImageSlice";

import {
  profilePage,
  InsertProfilePage,
} from "../profilePage/profilePageSlice";
import jwt_decode from "jwt-decode";
import HeaderSection from "../shared-components/headerSection";
import FooterSection from "../shared-components/footerSection";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Message } from "primereact/message";
import { InputSwitch } from "primereact/inputswitch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import { ChangePassword } from "../../components/Auth/login/changePassword";

import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";

import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import "./profilePage.scss";
import { profileImagePage } from "./uploadImageSlice";
import { UnsubscribeTTEConnect, UnsubscribeUpdate } from "./unsubscribedSlice";
import {
  checkAlreadyUnsubscribe,
  getAlreadyUnsubscribeflag,
  getcheckAlreadyUnsubscribe,
} from "./checkAlreadyUnsubscribeSlice";

export function PrOfilePage() {
  /* Popup Window and Image upload starts here */

  const [displayBasic, setDisplayBasic] = useState(false);

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  function onClickShowPop(name: any) {
    dialogFuncMap["displayBasic"](true);
  }

  function onHide(name: any) {
    dialogFuncMap["displayBasic"](false);
  }

  const [imgDelete, setImgDelete] = useState<any>("");

  const accept = () => {
    setImgDelete("delete");
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestDeletedata = {
        request: {
          action: "uploadImage",
          id: profileData?.automation?.employee?.employee_uid,
          imageEncode: "Delete",
          oracleId: oracleId,
        },
      };
      dispatch<any>(profileImagePage(requestDeletedata));
    }
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const [visible, setVisible] = useState<boolean>(false);
  const [removeDeleteBtn, setRemoveDeleteBtn] = useState<boolean>(false);
  const [imgUpload, setImgUpload] = useState<any>("");

  const customBase64Uploader = async (event: any) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;

      if (
        base64data !== null &&
        base64data !== undefined &&
        base64data !== "0" &&
        oracleId !== null &&
        oracleId !== undefined &&
        oracleId !== "0"
      ) {
        const requestdata = {
          request: {
            action: "uploadImage",
            id: profileData?.automation?.employee?.employee_uid,
            imageEncode: base64data,
            oracleId: oracleId,
          },
        };
        dispatch<any>(profileImagePage(requestdata));
        onHide("displaybasic");
        setImgUpload("upload");
      } else {
        setSomethingWrongMsg(true);
      }
    };
  };

  const chooseOptions = { label: "Choose", icon: "pi pi-fw pi-plus" };
  const uploadOptions = {
    label: "Upload",
    icon: "pi pi-upload",
    className: "p-button-success",
  };
  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger d-none",
  };

  /* Popup Window and Image upload Ends here */

  const { empDetailsState, profileData } = useSelector(empProfileDetails);
  const { state } = useSelector(InsertProfilePage);
  const { ImageState } = useSelector(updateImage);
  const [empImage, setEmpImage] = useState("");

  const [phoneValue, setPhoneValue] = useState(true);
  const [phoneInput, setPhoneInput] = useState(false);
  const [phoneFlag, setPhoneFlag] = useState(true);
  const [oracleId, setOracleId] = useState("0");

  const [nameValue, setNameValue] = useState(true);
  const [nameInput, setNameInput] = useState(false);
  const [nameFlag, setNameFlag] = useState(false);
  const toast = useRef<any>(null);
  const dispatch = useDispatch();
  const AlreadyUnsubscribeflag = useSelector(getAlreadyUnsubscribeflag);
  const AlreadyUnsubscribeState = useSelector(getcheckAlreadyUnsubscribe);
  const [somethingWrongMsg, setSomethingWrongMsg] = useState(false);
  let countryName = profileData?.automation?.employee?.location_country;

  const subscribeUnsubscribeToast = useRef<any>(null);
  const unsubscribeUpdate = useSelector(UnsubscribeUpdate);

  useEffect(() => {
    if (
      oracleId !== null &&
      oracleId !== undefined &&
      oracleId !== "0" &&
      countryName !== null &&
      countryName !== undefined &&
      countryName !== ""
    ) {
      const data = {
        request: {
          action: "checkAlreadyUnsubscribe",
          oracleId: oracleId,
          country: countryName,
        },
      };
      dispatch(checkAlreadyUnsubscribe(data));
    }
  }, [dispatch, oracleId, countryName]);
  let UnSubState = AlreadyUnsubscribeState?.AlreadyUnsubscribeState;
  let subscribeUnsubscribeFlag = AlreadyUnsubscribeflag?.AlreadyUnsubscribeflag;
  const [inputSwitchSubscribe, setInputSwitchSubscribe] = useState(false);

  const handleSwitchChange = (e: any) => {
    if (
      oracleId !== null &&
      oracleId !== undefined &&
      oracleId !== "0" &&
      countryName !== null &&
      countryName !== undefined &&
      countryName !== ""
    ) {
      if (e.value === true) {
        const data = {
          request: {
            action: "UnsubscribeTTEConnect",
            oracleId: oracleId,
            country: countryName,
            flag: "Unsubscribe",
          },
        };
        dispatch(UnsubscribeTTEConnect(data));
        setInputSwitchSubscribe(e.value);
      } else if (e.value === false) {
        const data = {
          request: {
            action: "UnsubscribeTTEConnect",
            oracleId: oracleId,
            country: countryName,
            flag: "Subscribe",
          },
        };
        dispatch(UnsubscribeTTEConnect(data));
        setInputSwitchSubscribe(e.value);
      }
    }
  };

  useEffect(() => {
    if (subscribeUnsubscribeFlag === "true") {
      setInputSwitchSubscribe(true);
    } else {
      setInputSwitchSubscribe(false);
    }
  }, [subscribeUnsubscribeFlag]);

  const [text, setText] = useState("");

  const [prefName, setPrefName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<number | null>();
  const [isSSOLogin, setisSSOLogin] = useState(false);
  const [selectedCity1, setSelectedCity1] = useState<any>(null);

  const [nameToast, setNameToast] = useState("");
  const [numToast, setNumToast] = useState("");

  const countryOptionTemplate = (option: any) => {
    return (
      <div className="country-item">
        <div>{option.code + " " + option.dial_code}</div>
      </div>
    );
  };

  const selectedCountryTemplate = (option: { dial_code: any }) => {
    if (option !== null) {
      return (
        <div className="country-item country-item-value">
          <div>{option.dial_code}</div>
        </div>
      );
    } else {
      return <span>Select country code</span>;
    }
  };

  const codes = [
    {
      name: "+Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Aland Islands",
      dial_code: "+358",
      code: "AX",
    },
    {
      name: "Albania",
      dial_code: "+355",
      code: "AL",
    },
    {
      name: "Algeria",
      dial_code: "+213",
      code: "DZ",
    },
    {
      name: "AmericanSamoa",
      dial_code: "+1684",
      code: "AS",
    },
    {
      name: "Andorra",
      dial_code: "+376",
      code: "AD",
    },
    {
      name: "Angola",
      dial_code: "+244",
      code: "AO",
    },
    {
      name: "Anguilla",
      dial_code: "+1264",
      code: "AI",
    },
    {
      name: "Antarctica",
      dial_code: "+672",
      code: "AQ",
    },
    {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      code: "AG",
    },
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "Aruba",
      dial_code: "+297",
      code: "AW",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "Azerbaijan",
      dial_code: "+994",
      code: "AZ",
    },
    {
      name: "Bahamas",
      dial_code: "+1242",
      code: "BS",
    },
    {
      name: "Bahrain",
      dial_code: "+973",
      code: "BH",
    },
    {
      name: "Bangladesh",
      dial_code: "+880",
      code: "BD",
    },
    {
      name: "Barbados",
      dial_code: "+1246",
      code: "BB",
    },
    {
      name: "Belarus",
      dial_code: "+375",
      code: "BY",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "Belize",
      dial_code: "+501",
      code: "BZ",
    },
    {
      name: "Benin",
      dial_code: "+229",
      code: "BJ",
    },
    {
      name: "Bermuda",
      dial_code: "+1441",
      code: "BM",
    },
    {
      name: "Bhutan",
      dial_code: "+975",
      code: "BT",
    },
    {
      name: "Bolivia, Plurinational State of",
      dial_code: "+591",
      code: "BO",
    },
    {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      code: "BA",
    },
    {
      name: "Botswana",
      dial_code: "+267",
      code: "BW",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      code: "IO",
    },
    {
      name: "Brunei Darussalam",
      dial_code: "+673",
      code: "BN",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "Burundi",
      dial_code: "+257",
      code: "BI",
    },
    {
      name: "Cambodia",
      dial_code: "+855",
      code: "KH",
    },
    {
      name: "Cameroon",
      dial_code: "+237",
      code: "CM",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
    },
    {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
    },
    {
      name: "Cayman Islands",
      dial_code: "+ 345",
      code: "KY",
    },
    {
      name: "Central African Republic",
      dial_code: "+236",
      code: "CF",
    },
    {
      name: "Chad",
      dial_code: "+235",
      code: "TD",
    },
    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
    },
    {
      name: "Christmas Island",
      dial_code: "+61",
      code: "CX",
    },
    {
      name: "Cocos (Keeling) Islands",
      dial_code: "+61",
      code: "CC",
    },
    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "Comoros",
      dial_code: "+269",
      code: "KM",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      dial_code: "+243",
      code: "CD",
    },
    {
      name: "Cook Islands",
      dial_code: "+682",
      code: "CK",
    },
    {
      name: "Costa Rica",
      dial_code: "+506",
      code: "CR",
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
    },
    {
      name: "Croatia",
      dial_code: "+385",
      code: "HR",
    },
    {
      name: "Cuba",
      dial_code: "+53",
      code: "CU",
    },
    {
      name: "Cyprus",
      dial_code: "+357",
      code: "CY",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "Dominica",
      dial_code: "+1767",
      code: "DM",
    },
    {
      name: "Dominican Republic",
      dial_code: "+1849",
      code: "DO",
    },
    {
      name: "Ecuador",
      dial_code: "+593",
      code: "EC",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "El Salvador",
      dial_code: "+503",
      code: "SV",
    },
    {
      name: "Equatorial Guinea",
      dial_code: "+240",
      code: "GQ",
    },
    {
      name: "Eritrea",
      dial_code: "+291",
      code: "ER",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "Ethiopia",
      dial_code: "+251",
      code: "ET",
    },
    {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      code: "FK",
    },
    {
      name: "Faroe Islands",
      dial_code: "+298",
      code: "FO",
    },
    {
      name: "Fiji",
      dial_code: "+679",
      code: "FJ",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "FI",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "French Guiana",
      dial_code: "+594",
      code: "GF",
    },
    {
      name: "French Polynesia",
      dial_code: "+689",
      code: "PF",
    },
    {
      name: "Gabon",
      dial_code: "+241",
      code: "GA",
    },
    {
      name: "Gambia",
      dial_code: "+220",
      code: "GM",
    },
    {
      name: "Georgia",
      dial_code: "+995",
      code: "GE",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "Ghana",
      dial_code: "+233",
      code: "GH",
    },
    {
      name: "Gibraltar",
      dial_code: "+350",
      code: "GI",
    },
    {
      name: "Greece",
      dial_code: "+30",
      code: "GR",
    },
    {
      name: "Greenland",
      dial_code: "+299",
      code: "GL",
    },
    {
      name: "Grenada",
      dial_code: "+1473",
      code: "GD",
    },
    {
      name: "Guadeloupe",
      dial_code: "+590",
      code: "GP",
    },
    {
      name: "Guam",
      dial_code: "+1671",
      code: "GU",
    },
    {
      name: "Guatemala",
      dial_code: "+502",
      code: "GT",
    },
    {
      name: "Guernsey",
      dial_code: "+44",
      code: "GG",
    },
    {
      name: "Guinea",
      dial_code: "+224",
      code: "GN",
    },
    {
      name: "Guinea-Bissau",
      dial_code: "+245",
      code: "GW",
    },
    {
      name: "Guyana",
      dial_code: "+595",
      code: "GY",
    },
    {
      name: "Haiti",
      dial_code: "+509",
      code: "HT",
    },
    {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      code: "VA",
    },
    {
      name: "Honduras",
      dial_code: "+504",
      code: "HN",
    },
    {
      name: "Hong Kong",
      dial_code: "+852",
      code: "HK",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "Iceland",
      dial_code: "+354",
      code: "IS",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Iraq",
      dial_code: "+964",
      code: "IQ",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "Isle of Man",
      dial_code: "+44",
      code: "IM",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "Jamaica",
      dial_code: "+1876",
      code: "JM",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "Jersey",
      dial_code: "+44",
      code: "JE",
    },
    {
      name: "Jordan",
      dial_code: "+962",
      code: "JO",
    },
    {
      name: "Kazakhstan",
      dial_code: "+77",
      code: "KZ",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KE",
    },
    {
      name: "Kiribati",
      dial_code: "+686",
      code: "KI",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "Kyrgyzstan",
      dial_code: "+996",
      code: "KG",
    },
    {
      name: "Laos",
      dial_code: "+856",
      code: "LA",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "Lebanon",
      dial_code: "+961",
      code: "LB",
    },
    {
      name: "Lesotho",
      dial_code: "+266",
      code: "LS",
    },
    {
      name: "Liberia",
      dial_code: "+231",
      code: "LR",
    },
    {
      name: "Libyan Arab Jamahiriya",
      dial_code: "+218",
      code: "LY",
    },
    {
      name: "Liechtenstein",
      dial_code: "+423",
      code: "LI",
    },
    {
      name: "Lithuania",
      dial_code: "+370",
      code: "LT",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "Macao",
      dial_code: "+853",
      code: "MO",
    },
    {
      name: "Macedonia",
      dial_code: "+389",
      code: "MK",
    },
    {
      name: "Madagascar",
      dial_code: "+261",
      code: "MG",
    },
    {
      name: "Malawi",
      dial_code: "+265",
      code: "MW",
    },
    {
      name: "Malaysia",
      dial_code: "+60",
      code: "MY",
    },
    {
      name: "Maldives",
      dial_code: "+960",
      code: "MV",
    },
    {
      name: "Mali",
      dial_code: "+223",
      code: "ML",
    },
    {
      name: "Malta",
      dial_code: "+356",
      code: "MT",
    },
    {
      name: "Marshall Islands",
      dial_code: "+692",
      code: "MH",
    },
    {
      name: "Martinique",
      dial_code: "+596",
      code: "MQ",
    },
    {
      name: "Mauritania",
      dial_code: "+222",
      code: "MR",
    },
    {
      name: "Mauritius",
      dial_code: "+230",
      code: "MU",
    },
    {
      name: "Mayotte",
      dial_code: "+262",
      code: "YT",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      dial_code: "+691",
      code: "FM",
    },
    {
      name: "Moldova",
      dial_code: "+373",
      code: "MD",
    },
    {
      name: "Monaco",
      dial_code: "+377",
      code: "MC",
    },
    {
      name: "Mongolia",
      dial_code: "+976",
      code: "MN",
    },
    {
      name: "Montenegro",
      dial_code: "+382",
      code: "ME",
    },
    {
      name: "Montserrat",
      dial_code: "+1664",
      code: "MS",
    },
    {
      name: "Morocco",
      dial_code: "+212",
      code: "MA",
    },
    {
      name: "Mozambique",
      dial_code: "+258",
      code: "MZ",
    },
    {
      name: "Myanmar",
      dial_code: "+95",
      code: "MM",
    },
    {
      name: "Namibia",
      dial_code: "+264",
      code: "NA",
    },
    {
      name: "Nauru",
      dial_code: "+674",
      code: "NR",
    },
    {
      name: "Nepal",
      dial_code: "+977",
      code: "NP",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
    },
    {
      name: "New Caledonia",
      dial_code: "+687",
      code: "NC",
    },
    {
      name: "New Zealand",
      dial_code: "+64",
      code: "NZ",
    },
    {
      name: "Nicaragua",
      dial_code: "+505",
      code: "NI",
    },
    {
      name: "Niger",
      dial_code: "+227",
      code: "NE",
    },
    {
      name: "Nigeria",
      dial_code: "+234",
      code: "NG",
    },
    {
      name: "Niue",
      dial_code: "+683",
      code: "NU",
    },
    {
      name: "Norfolk Island",
      dial_code: "+672",
      code: "NF",
    },
    {
      name: "Northern Mariana Islands",
      dial_code: "+1670",
      code: "MP",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Palau",
      dial_code: "+680",
      code: "PW",
    },
    {
      name: "Palestinian Territory, Occupied",
      dial_code: "+970",
      code: "PS",
    },
    {
      name: "Panama",
      dial_code: "+507",
      code: "PA",
    },
    {
      name: "Papua New Guinea",
      dial_code: "+675",
      code: "PG",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
    {
      name: "Pitcairn",
      dial_code: "+872",
      code: "PN",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "Puerto Rico",
      dial_code: "+1939",
      code: "PR",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "Rwanda",
      dial_code: "+250",
      code: "RW",
    },
    {
      name: "Reunion",
      dial_code: "+262",
      code: "RE",
    },
    {
      name: "Saint Barthelemy",
      dial_code: "+590",
      code: "BL",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      code: "SH",
    },
    {
      name: "Saint Kitts and Nevis",
      dial_code: "+1869",
      code: "KN",
    },
    {
      name: "Saint Lucia",
      dial_code: "+1758",
      code: "LC",
    },
    {
      name: "Saint Martin",
      dial_code: "+590",
      code: "MF",
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
    },
    {
      name: "Saint Vincent and the Grenadines",
      dial_code: "+1784",
      code: "VC",
    },
    {
      name: "Samoa",
      dial_code: "+685",
      code: "WS",
    },
    {
      name: "San Marino",
      dial_code: "+378",
      code: "SM",
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "Senegal",
      dial_code: "+221",
      code: "SN",
    },
    {
      name: "Serbia",
      dial_code: "+381",
      code: "RS",
    },
    {
      name: "Seychelles",
      dial_code: "+248",
      code: "SC",
    },
    {
      name: "Sierra Leone",
      dial_code: "+232",
      code: "SL",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "Slovakia",
      dial_code: "+421",
      code: "SK",
    },
    {
      name: "Slovenia",
      dial_code: "+386",
      code: "SI",
    },
    {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
    },
    {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
    },
    {
      name: "South Africa",
      dial_code: "+27",
      code: "ZA",
    },
    {
      name: "South Sudan",
      dial_code: "+211",
      code: "SS",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      code: "GS",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "Sri Lanka",
      dial_code: "+94",
      code: "LK",
    },
    {
      name: "Sudan",
      dial_code: "+249",
      code: "SD",
    },
    {
      name: "Suriname",
      dial_code: "+597",
      code: "SR",
    },
    {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      code: "SJ",
    },
    {
      name: "Swaziland",
      dial_code: "+268",
      code: "SZ",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "Syrian Arab Republic",
      dial_code: "+963",
      code: "SY",
    },
    {
      name: "Taiwan",
      dial_code: "+886",
      code: "TW",
    },
    {
      name: "Tajikistan",
      dial_code: "+992",
      code: "TJ",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      dial_code: "+255",
      code: "TZ",
    },
    {
      name: "Thailand",
      dial_code: "+66",
      code: "TH",
    },
    {
      name: "Timor-Leste",
      dial_code: "+670",
      code: "TL",
    },
    {
      name: "Togo",
      dial_code: "+228",
      code: "TG",
    },
    {
      name: "Tokelau",
      dial_code: "+690",
      code: "TK",
    },
    {
      name: "Tonga",
      dial_code: "+676",
      code: "TO",
    },
    {
      name: "Trinidad and Tobago",
      dial_code: "+1868",
      code: "TT",
    },
    {
      name: "Tunisia",
      dial_code: "+216",
      code: "TN",
    },
    {
      name: "Turkey",
      dial_code: "+90",
      code: "TR",
    },
    {
      name: "Turkmenistan",
      dial_code: "+993",
      code: "TM",
    },
    {
      name: "Turks and Caicos Islands",
      dial_code: "+1649",
      code: "TC",
    },
    {
      name: "Tuvalu",
      dial_code: "+688",
      code: "TV",
    },
    {
      name: "Uganda",
      dial_code: "+256",
      code: "UG",
    },
    {
      name: "Ukraine",
      dial_code: "+380",
      code: "UA",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
    },
    {
      name: "Uruguay",
      dial_code: "+598",
      code: "UY",
    },
    {
      name: "Uzbekistan",
      dial_code: "+998",
      code: "UZ",
    },
    {
      name: "Vanuatu",
      dial_code: "+678",
      code: "VU",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      dial_code: "+58",
      code: "VE",
    },
    {
      name: "Vietnam",
      dial_code: "+84",
      code: "VN",
    },
    {
      name: "Virgin Islands, British",
      dial_code: "+1284",
      code: "VG",
    },
    {
      name: "Virgin Islands, U.S.",
      dial_code: "+1340",
      code: "VI",
    },
    {
      name: "Wallis and Futuna",
      dial_code: "+681",
      code: "WF",
    },
    {
      name: "Yemen",
      dial_code: "+967",
      code: "YE",
    },
    {
      name: "Zambia",
      dial_code: "+260",
      code: "ZM",
    },
    {
      name: "Zimbabwe",
      dial_code: "+263",
      code: "ZW",
    },
  ];

  const onCityChange = (e: { value: any }) => {
    setSelectedCity1(e.value);
  };

  const [profileImageToolTip, setProfileImageToolTip] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const token: any = localStorage.getItem("AccessToken");
    setisSSOLogin(token ? true : false);
  });

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestdata = {
        request: {
          action: "getEmpDetails",
          oracleId: oracleId,
          astt: "N",
        },
      };
      dispatch<any>(empProfile(requestdata));
    }
  }, [dispatch, oracleId]);

  const phoneToggle = () => {
    if (phoneFlag === true) {
      setPhoneValue(false);
      setPhoneInput(true);
      setPhoneFlag(false);
    } else {
      setPhoneValue(true);
      setPhoneInput(false);
      setPhoneFlag(true);
      setPhoneNumber(null);
    }
  };

  const phoneTempToggle = () => {
    setPhoneValue(true);
    setPhoneInput(false);
    setPhoneNumber(null);
  };

  const nameToggle = () => {
    if (nameFlag === true) {
      setNameValue(false);
      setNameInput(true);
      setNameFlag(false);
    } else {
      setNameValue(true);
      setNameInput(false);
      setNameFlag(true);
      setPrefName("");
      setPhoneNumber(null);
    }
  };

  const handleChange = (e: any) => {
    const allowedCharacters = /^[a-zA-Z ]*$/;
    if (allowedCharacters.test(e.target.value)) {
      setPrefName(e.target.value);
    }
  };

  const handleNumberChange = (e: any) => {
    if (
      e?.value?.toString().length === 10 &&
      e?.value?.toString().length !== undefined
    ) {
      setPhoneNumber(e.value);
    }
  };

  useEffect(() => {
    if (state === "fulfilled") {
      if (nameToast === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Preferred name updated",
          life: 3000,
        });
        setNameToast("");
        if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
          const requestdata = {
            request: {
              action: "getEmpDetails",
              oracleId: oracleId,
              astt: "N",
            },
          };
          dispatch<any>(empProfile(requestdata));
        }
      }
      if (numToast === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Phone number updated",
          life: 3000,
        });
        setNumToast("");
        if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
          const requestdata = {
            request: {
              action: "getEmpDetails",
              oracleId: oracleId,
              astt: "N",
            },
          };
          dispatch<any>(empProfile(requestdata));
        }
      }
    }
  }, [state, oracleId, dispatch, nameToast, numToast]);

  const onSubmitPhoneTemp = () => {
    if (
      phoneNumber !== null &&
      phoneNumber !== undefined &&
      selectedCity1 !== null &&
      selectedCity1 !== undefined
    ) {
      phoneTempToggle();
      setInitialPhoneNumber(`${selectedCity1?.dial_code} ${phoneNumber}`);

      if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
        setNumToast("success");
        const requestdata = {
          request: {
            action: "insertProfileDetails",
            oracleId: oracleId,
            phoneNumber: `${selectedCity1?.dial_code}${phoneNumber}`,
          },
        };
        dispatch<any>(profilePage(requestdata));
      }
    }
  };

  const onSubmitPhone = () => {
    if (
      phoneNumber !== null &&
      phoneNumber !== undefined &&
      selectedCity1 !== null &&
      selectedCity1 !== undefined
    ) {
      phoneToggle();
      setInitialPhoneNumber(`${selectedCity1?.dial_code} ${phoneNumber}`);

      if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
        setNumToast("success");
        const requestdata = {
          request: {
            action: "insertProfileDetails",
            oracleId: oracleId,
            phoneNumber: `${selectedCity1?.dial_code}${phoneNumber}`,
          },
        };
        dispatch<any>(profilePage(requestdata));
      }
    }
  };

  const onSubmitName = () => {
    if (prefName !== "") {
      nameToggle();
      let [firstChar, ...restChar] = prefName ? prefName : "";
      let finalDisplayName = firstChar?.toUpperCase() + restChar.join("");
      setMainPrefName(finalDisplayName);
      if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
        setNameToast("success");
        const requestdata = {
          request: {
            action: "insertProfileDetails",
            oracleId: oracleId,
            preferredName: prefName,
          },
        };
        dispatch<any>(profilePage(requestdata));
      }
    }
  };

  let roleNPosition = profileData?.oracle?.employee?.designation
    ? profileData?.oracle?.employee?.designation
    : "";
  const role = roleNPosition.split(".");

  let [firstChar, ...restChar] = profileData?.automation?.employee
    ?.preferred_name
    ? profileData?.automation?.employee?.preferred_name
    : "";
  let initialDisplayName = firstChar?.toUpperCase() + restChar.join("");

  const [mainPrefName, setMainPrefName] = useState("");
  const [initialPhoneNumber, setInitialPhoneNumber] = useState("");
  const [phno, setPhNo] = useState("");

  useEffect(() => {
    setMainPrefName(initialDisplayName);
  }, [initialDisplayName]);

  useEffect(() => {
    setInitialPhoneNumber(profileData?.mobileNumber?.row?.mobile);
  }, [profileData?.mobileNumber?.row?.mobile]);

  useEffect(() => {
    return setText(profileData?.automation?.employee?.preferred_name || " ");
  }, [profileData?.automation?.employee?.preferred_name]);

  useEffect(() => {
    return setPhNo(profileData?.mobileNumber?.row?.mobile || "");
  }, [profileData?.mobileNumber?.row?.mobile]);

  useEffect(() => {
    localStorage.setItem("preferredName", JSON.stringify(initialDisplayName));
  });

  useEffect(() => {
    setEmpImage(
      profileData?.image?.row?.image || "./images/accountDefaultImage.png"
    );

    if (profileData?.image?.row?.image === undefined) {
      setProfileImageToolTip("Add profile photo");
      setRemoveDeleteBtn(false);
    } else {
      setProfileImageToolTip("Change profile photo");
      setRemoveDeleteBtn(true);
    }
  }, [profileData?.image?.row?.image]);

  useEffect(() => {
    if (ImageState === "fulfilled") {
      if (imgDelete === "delete") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image deleted",
          life: 3000,
        });
        setImgDelete("");
      } else if (imgUpload === "upload") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image updated",
          life: 3000,
        });
        setImgUpload("");
      }

      if (empDetailsState === "fulfilled") {
        if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
          const requestdata = {
            request: {
              action: "getEmpDetails",
              oracleId: oracleId,
              astt: "N",
            },
          };
          dispatch<any>(empProfile(requestdata));
        }
        setEmpImage(
          profileData?.image?.row?.image || "./images/accountDefaultImage.png"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ImageState, oracleId, dispatch]);

  const showSuccess = (mesg: any) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: mesg,
      life: 3000,
    });
  };

  useEffect(() => {
    if (
      unsubscribeUpdate?.UnsubscribeState === "fulfilled" &&
      unsubscribeUpdate?.UnsubscribeUpdate === "Form Unsubscribe successfully"
    ) {
      showSuccess("Unsubscribed Successfully");
    } else if (
      unsubscribeUpdate?.UnsubscribeState === "fulfilled" &&
      unsubscribeUpdate?.UnsubscribeUpdate === "Form Subscribed successfully"
    ) {
      showSuccess("Subscribed Successfully");
    }
  }, [unsubscribeUpdate]);

  return (
    <>
      <HeaderSection />
      <div className="container-fluid px-5 main-container mb-5">
        <h1 className="display-5 mt-3 text-start">My Profile</h1>
        <hr />
        <img
          src="./images/flores-amarillas-wallpaper.png"
          className="w-100 desktopImage"
          alt="DesktopImage"
          style={{
            height: '350px',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        />
        <img
          src="./images/flores-amarillas-wallpaperMobile.png"
          className="w-100 mobileImage"
          alt="MobileImage"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        />
        <div className="card social-prof">
          <div className="card-body">
            <div className="wrapper">
              <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} />
              <img
                src={empImage}
                alt=""
                className="logo user-profile cursorPointer shadow"
                onClick={() => onClickShowPop("displayBasic")}
                width={"100%"}
                height={"100%"}
                style={{ backgroundColor: "rgb(241 241 247)" }}
                data-pr-tooltip={profileImageToolTip}
              />

              <div
                className="row"
                style={{ position: "relative", top: "-10px" }}
              >
                <div className="col-md-12">
                  {removeDeleteBtn && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="cursorPointer removeImageCursor"
                      aria-label="Remove"
                      onClick={() => setVisible(true)}
                    />
                  )}
                  <FontAwesomeIcon
                    icon={faPen}
                    className="cursorPointer editImageCursor"
                    aria-label="Edit"
                    onClick={() => onClickShowPop("displayBasic")}
                  />
                </div>
              </div>
              <br />
              <h3 className="mt-3">
                {profileData?.oracle?.employee?.first_name
                  ? profileData?.oracle?.employee?.first_name +
                  " " +
                  profileData?.oracle?.employee.last_name
                  : ""}
              </h3>
              <p>{role[1]}</p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12">
                <ul
                  className="nav nav-pills justify-content-center mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  {isSSOLogin && <h4>Contact Information</h4>}
                  {!isSSOLogin && (
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Contact Information
                      </button>
                    </li>
                  )}

                  {!isSSOLogin && (
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="pills-messages"
                        aria-selected="false"
                      >
                        Change Password
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix">&nbsp;</div>

        <div className="row">
          <div className="col-md-12">
            <div className="card shadow-lg rounded">
              <div className="card-body">
                <div className="d-flex align-items-start w-100">
                  <div className="tab-content w-100" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active w-100"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="row">
                        <div className="col-md-12 ms-2">
                          {profileData?.automation?.employee?.preferred_name ? (
                            <div className="row">
                              <div className="col-md-12">
                                <h5>
                                  Preferred Name{" "}
                                  <span
                                    className="pwdtooltip"
                                    data-tooltip="Please keep your Preferred Name professional as it may be seen by internal and external audiences."
                                  >
                                    {" "}
                                    i
                                  </span>
                                </h5>

                                {nameValue && (
                                  <>
                                    {text ? (
                                      <span className="align-top mt-3 pt-3">
                                        {mainPrefName ? mainPrefName : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    <FontAwesomeIcon
                                      icon={faPen}
                                      className="ms-3 cursorPointer"
                                      style={{ fontSize: "1.2rem" }}
                                      aria-label="Edit"
                                      onClick={nameToggle}
                                    />
                                  </>
                                )}

                                {nameInput && (
                                  <div className="row">
                                    <div className="col-md-5 col-lg-3">
                                      <span className="p-input-icon-right w-100">
                                        <i className="pi pi-user" />
                                        <InputText
                                          className="w-100"
                                          value={prefName}
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                          placeholder={mainPrefName}
                                          maxLength={20}
                                        />
                                      </span>
                                    </div>

                                    <div className="col-md-6 col-lg-7 mt-3 mt-md-0 mt-lg-0 toast-demo">
                                      {prefName !== null &&
                                        prefName !== undefined &&
                                        prefName !== "" &&
                                        prefName !== mainPrefName &&
                                        prefName !==
                                        mainPrefName.toLowerCase() ? (
                                        <Button
                                          label="Submit"
                                          icon="pi pi-check p-button-success"
                                          onClick={onSubmitName}
                                        />
                                      ) : (
                                        <Button
                                          label="Submit"
                                          icon="pi pi-check p-button-success"
                                          disabled
                                        />
                                      )}

                                      <Button
                                        label="Cancel"
                                        icon="pi pi-times"
                                        className="ms-3"
                                        onClick={nameToggle}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <h5>
                                Preferred Name{" "}
                                <span
                                  className="pwdtooltip"
                                  data-tooltip="Please keep the Preferred Name professional, as the exact text you enter will be used to address you."
                                >
                                  {" "}
                                  i
                                </span>
                              </h5>
                              <div className="col-md-5 col-lg-3">
                                <span className="p-input-icon-right w-100">
                                  <i className="pi pi-user" />
                                  <InputText
                                    className="w-100"
                                    value={prefName}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    placeholder={
                                      "Please enter your preferred name"
                                    }
                                    maxLength={20}
                                  />
                                </span>
                              </div>

                              <div className="col-md-7 col-lg-8 mt-3 mt-md-0 mt-lg-0 toast-demo">
                                {prefName !== null &&
                                  prefName !== undefined &&
                                  prefName !== "" ? (
                                  <Button
                                    label="Submit"
                                    icon="pi pi-check p-button-success"
                                    onClick={onSubmitName}
                                  />
                                ) : (
                                  <Button
                                    label="Submit"
                                    icon="pi pi-check p-button-success"
                                    disabled
                                  />
                                )}
                                <Button
                                  label="Cancel"
                                  icon="pi pi-times"
                                  className="ms-3"
                                  onClick={nameToggle}
                                />
                              </div>
                            </div>
                          )}

                          <div className="clearfix">&nbsp;</div>

                          {profileData?.mobileNumber?.row?.mobile ? (
                            <div className="row">
                              <div className="col-md-12">
                                <h5>Phone Number</h5>

                                {phoneValue && (
                                  <>
                                    {phno ? (
                                      <span className="align-top mt-3 pt-3">
                                        {initialPhoneNumber
                                          ? initialPhoneNumber
                                          : ""}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {profileData?.mobileNumber?.row?.mobile ? (
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        className="ms-3 cursorPointer"
                                        style={{ fontSize: "1.2rem" }}
                                        aria-label="Edit"
                                        onClick={phoneToggle}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}

                                {phoneInput && (
                                  <div className="row">
                                    <div className="col-md-1 col-lg-2 dropdown-demo">
                                      <Dropdown
                                        value={selectedCity1}
                                        options={codes}
                                        onChange={onCityChange}
                                        optionLabel="dial_code"
                                        filter
                                        showClear
                                        filterBy="code"
                                        placeholder="Select Country Code"
                                        valueTemplate={selectedCountryTemplate}
                                        itemTemplate={countryOptionTemplate}
                                      />
                                    </div>
                                    <div className="col-md-5 col-lg-3">
                                      <span className="p-input-icon-right w-100">
                                        <i className="pi pi-phone" />
                                        <InputNumber
                                          className="w-100"
                                          value={phoneNumber}
                                          useGrouping={false}
                                          onChange={(e) => {
                                            handleNumberChange(e);
                                          }}
                                          maxLength={10}
                                          placeholder={initialPhoneNumber}
                                        />
                                      </span>
                                    </div>

                                    <div className="col-md-6 col-lg-7 mt-3 mt-md-0 mt-lg-0 toast-demo">
                                      {selectedCity1?.dial_code !== null &&
                                        selectedCity1?.dial_code !== undefined &&
                                        phoneNumber !== null &&
                                        phoneNumber !== undefined &&
                                        selectedCity1?.dial_code + phoneNumber !==
                                        initialPhoneNumber &&
                                        phoneNumber.toString().trim().length ===
                                        10 ? (
                                        <Button
                                          label="Submit"
                                          icon="pi pi-check p-button-success"
                                          onClick={onSubmitPhone}
                                        />
                                      ) : (
                                        <Button
                                          label="Submit"
                                          icon="pi pi-check p-button-success"
                                          disabled
                                        />
                                      )}

                                      <Button
                                        label="Cancel"
                                        icon="pi pi-times"
                                        className="ms-3"
                                        onClick={phoneToggle}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <h5>Phone Number</h5>
                              <div className="col-md-1 col-lg-2 dropdown-demo">
                                <Dropdown
                                  value={selectedCity1}
                                  options={codes}
                                  onChange={onCityChange}
                                  optionLabel="dial_code"
                                  filter
                                  showClear
                                  filterBy="code"
                                  placeholder="Select Country Code"
                                  valueTemplate={selectedCountryTemplate}
                                  itemTemplate={countryOptionTemplate}
                                />
                              </div>
                              <div className="col-md-5 col-lg-3">
                                <span className="p-input-icon-right w-100">
                                  <i className="pi pi-phone" />
                                  <InputNumber
                                    className="w-100"
                                    value={phoneNumber}
                                    useGrouping={false}
                                    onChange={(e) => {
                                      handleNumberChange(e);
                                    }}
                                    maxLength={10}
                                    placeholder={"Enter the phone number"}
                                  />
                                </span>
                              </div>

                              <div className="col-md-6 col-lg-7 mt-3 mt-md-0 mt-lg-0 toast-demo">
                                {selectedCity1?.dial_code !== null &&
                                  selectedCity1?.dial_code !== undefined &&
                                  phoneNumber !== null &&
                                  phoneNumber !== undefined &&
                                  phoneNumber.toString().trim().length === 10 ? (
                                  <Button
                                    label="Submit"
                                    icon="pi pi-check p-button-success"
                                    onClick={onSubmitPhoneTemp}
                                  />
                                ) : (
                                  <Button
                                    label="Submit"
                                    icon="pi pi-check p-button-success"
                                    disabled
                                  />
                                )}

                                <Button
                                  label="Cancel"
                                  icon="pi pi-times"
                                  className="ms-3"
                                  onClick={phoneToggle}
                                />
                              </div>
                            </div>
                          )}

                          <div className="clearfix">&nbsp;</div>

                          <div className="row">
                            <div className="col-md-12">
                              <h5>Email</h5>
                              <span className="align-top mt-3 pt-3">
                                {profileData?.oracleAyehuDb?.work_email}
                              </span>
                            </div>
                          </div>

                          <div className="clearfix">&nbsp;</div>

                          <div className="row">
                            <div className="col-md-12">
                              <h5>Location</h5>
                              <span className="align-top mt-3 pt-3">
                                {profileData?.oracle?.employee?.location_site}
                              </span>
                            </div>
                          </div>

                          <div className="clearfix">&nbsp;</div>

                          <div className="row">
                            <div className="col-md-12">
                              <h5>Department</h5>
                              <span className="align-top mt-3 pt-3">
                                {profileData?.oracle?.employee?.department}
                              </span>
                            </div>
                          </div>

                          <div className="clearfix">&nbsp;</div>

                          {UnSubState === "fulfilled" && (
                            <div className="row">
                              <div className="col-md-12">
                                <h5>
                                  Do we want to disable both TTEConnect + Louie
                                  emails/SMS?
                                </h5>
                                <InputSwitch
                                  checked={inputSwitchSubscribe}
                                  onChange={(e) => handleSwitchChange(e)}
                                />
                                <figure className="mt-3">
                                  <figcaption className="blockquote-footer mailUnsubscribe">
                                    <cite title="Source Title">
                                      <strong>Please note</strong>, when you
                                      unsubscribe, you will be unsubscribed from
                                      both TTEConnect and My Louie Tasks emails.
                                    </cite>
                                  </figcaption>
                                </figure>
                              </div>
                            </div>
                          )}
                          <div className="clearfix">&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    {!isSSOLogin && (
                      <div
                        className="tab-pane fade w-100"
                        id="pills-messages"
                        role="tabpanel"
                        aria-labelledby="pills-messages-tab"
                      >
                        <div className="row">
                          <div className="col-md-12 ms-2">
                            <ChangePassword />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />

      <Toast ref={subscribeUnsubscribeToast} />

      <FooterSection />

      <Dialog
        visible={displayBasic}
        style={{ width: "60vw" }}
        onHide={() => onHide("displayBasic")}
      >
        {somethingWrongMsg && (
          <Message
            severity="warn"
            text="Something went wrong. Please try again later."
            className="w-100 mb-3"
          />
        )}

        <div className="card">
          <FileUpload
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            name="FileUpload"
            accept="image/*"
            customUpload={true}
            uploadHandler={customBase64Uploader}
            maxFileSize={1000000}
            emptyTemplate={
              <p className="m-0">Drag and drop files to here to upload.</p>
            }
          />
        </div>
      </Dialog>

      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed with deleting of the profile image?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
}
