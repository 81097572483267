import React from "react";

import Header from "../app/components/shared-components/header";
import Footer from '../app/components/shared-components/footer';

export const RouterConfig = () => {

  return (
    <div>
        <Header />
        <Footer />
    </div>
  );
};
