import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, NON_SSO_CHANGE_PASSWORD } from '../../../../utils/CONSTANTS';
import axios from '../../../../utils/axios'; 

const initialState = {
    chgpwdStatus : null,
    chgpwdState : null,
};

export const chgpwd = createAsyncThunk(
    'post/chgpwd', async(data : any) =>  {
       const response = await axios.postRequest(MIDDLE_WARE_BASE_URL() + NON_SSO_CHANGE_PASSWORD(), data);
       return response;
});

export const chgpwdSlice = createSlice({
    name : 'chgpwd',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(chgpwd.pending, (state : any) =>
       {
            state.chgpwdState = 'pending';
       })
       .addCase(chgpwd.fulfilled, (state : any,action : any) =>
       {
        let json = JSON.stringify(action?.payload?.data?.response);
        json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
           state.chgpwdState = 'fulfilled';
           state.chgpwdStatus = 'success';
           if(JSON.parse(json)?.response?.status === "'400'")
           {
            state.chgpwdState = 'failed';
            state.chgpwdStatus = 'fail'; 
           }
        })
       .addCase(chgpwd.rejected, (state : any) =>
       {
            state.chgpwdState = 'failed';
            state.chgpwdStatus = 'fail';
       })
});

export const selectchgpwdDetails = ((state : any) => state.chgpwd || {});
 
export default chgpwdSlice.reducer;