import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../../app/components/Counter/counterSlice";
import personReducer from "../../app/components/User/user-slice";
import genOTPandSaveUserReducer from "../../app/components/Auth/login/genOTPandSaveUserSlice";
import registerUserReducer from "../../app/components/Auth/login/registerUserSlice";
import composeEmailReducer from "../../app/components/myMessages/compose/composeEmailSlice";
import inboxEmailSliceReducer from "../../app/components/myMessages/inbox/inboxEmailSlice";
import updateEmailSliceReducer from "../../app/components/myMessages/inbox/updateEmailSlice";
import replyEmailSliceReducer from "../../app/components/myMessages/viewEmail/replyEmailSlice";
import newHireChartReducer from "../../app/components/Home/NewHireStat/newHireStatSlice";
import LouieRatingStatReducer from "../../app/components/Home/LouieRatingStat/louieRatingStatSlice";
import supervisorChartReducer from "../../app/components/Home/SupervisorStat/supervisorStatSlice";
import sendMailReducer from "../../app/components/Auth/login/forgotPasswordSlice";
import genOTPandSavePwdReducer from "../../app/components/Auth/login/genOTPandSavePwdSlice";
import empProfileReducer from "../../app/components/ProfileLanding/Profile/ProfileSlice";
import PizzaTrackerReducer from "../../app/components/ProfileLanding/PizzaTrackerSlice";
import asttEmpProfileReducer from "../../app/components/ProfileLanding/Profile/asttProfileSlice";
import nonSSOLoginReducer from "../../app/components/Auth/login/nonSSOLoginSlice";
import stagesListReducer from "../../app/components/ProfileLanding/Tasks/StageSlice";
import tasksListReducer from "../../app/components/ProfileLanding/Tasks/TaskSlice";
import moodMeterReducer from "../../app/components/ProfileLanding/Tasks/MoodMeterSlice";
import chgpwdReducer from "../../app/components/Auth/login/changePasswordSlice";
import profilePageReducer from "../../app/components/profilePage/profilePageSlice";
import profileImageReducer from "../../app/components/profilePage/uploadImageSlice";
import myLouieTasksReducer from "../../app/components/ProfileLanding/Resources/myLouieTasksSlice";
import moodMeterOnloadReducer from "../../app/components/ProfileLanding/moodMeterOnLoadSlice";
import louieLinkDataReducer from "../../app/components/louie/LouieLink/LouieLinkSlice";
import NonLouielogicReducer from "../../app/components/louie/NonLouielogic/NonLouielogicSlice";
import FedExDetailsReducer from "../../app/components/ProfileLanding/Resources/getFedExDetails"
import managerProfileReducer from "../../app/components/profilePage/managerProfileSlice";
import { persistReducer, persistStore } from "redux-persist";
import UnsubscribeSliceReducer from "../../app/components/profilePage/unsubscribedSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import checkAlreadyUnsubscribeSliceReducer from "../../app/components/profilePage/checkAlreadyUnsubscribeSlice";
import NhtpSliceReducer from "../../app/components/ProfileLanding/Tasks/getNHTPSlice";

const persistConfig = {
  key: "counter",
  storage,
};

const persistedCounterReducer = persistReducer(persistConfig, counterReducer);

export const store = configureStore({
  reducer: {
    counter: persistedCounterReducer,
    person: personReducer,
    newHireChart: newHireChartReducer,
    supervisorChart: supervisorChartReducer,
    louieRatingChart: LouieRatingStatReducer,
    sendMail: sendMailReducer,
    genOTPandSavePwd: genOTPandSavePwdReducer,
    genOTPandSaveUser: genOTPandSaveUserReducer,
    registerUser: registerUserReducer,
    empProfile: empProfileReducer,
    nonSSOLogin: nonSSOLoginReducer,
    pizzaTracker: PizzaTrackerReducer,
    asttEmpProfile: asttEmpProfileReducer,
    stagesList: stagesListReducer,
    tasksList: tasksListReducer,
    moodMeter: moodMeterReducer,
    chgpwd: chgpwdReducer,
    profilePage: profilePageReducer,
    composeEmail: composeEmailReducer,
    inboxEmail: inboxEmailSliceReducer,
    updateEmail: updateEmailSliceReducer,
    replyEmail: replyEmailSliceReducer,
    profileImagePage: profileImageReducer,
    louieSteps: myLouieTasksReducer,
    moodMeterOnload: moodMeterOnloadReducer,
    louieLinkData: louieLinkDataReducer,
    NonLouielogic: NonLouielogicReducer,
    FedExDetails: FedExDetailsReducer,
    UnsubscribeTTEConnect: UnsubscribeSliceReducer,
    checkAlreadyUnsubscribe: checkAlreadyUnsubscribeSliceReducer,
    Nhtp: NhtpSliceReducer,
    ManagerDialogueBox: managerProfileReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store as any);
