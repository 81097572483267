import React, { useState, useEffect, useContext } from 'react';
import { genOTPandSavePwd, selectOTPandPwdDetails } from './genOTPandSavePwdSlice';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, InferType } from "yup";
import { useForm } from "react-hook-form";
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN } from "../../../../utils/CONSTANTS";
import "./otpVerification.scss";
import CountDownTimer from './otpCountDownTimer';
import { nonSSOLogin, selectNonSSOLoginDetails } from './nonSSOLoginSlice';
import AuthContext from "../../shared-components/authContext";
import { genOTPandSaveUser, selectOTPandUserDetails } from './genOTPandSaveUserSlice';
import { registerUser, selectUserDetails } from './registerUserSlice';
import { Button } from 'primereact/button';

export function OTPVerification(props: any) {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, state, requestType } = useSelector(selectOTPandPwdDetails);
  const { nonSSOStatus, nonSSOState, nonSSOReqType, accesstoken, refreshtoken } = useSelector(selectNonSSOLoginDetails);
  const [otp, setOtp] = useState('');
  const { otpstate, otpstatus, regrequestType } = useSelector(selectOTPandUserDetails);
  const { regStatus, regState, userRequestType } = useSelector(selectUserDetails);
  const [userRegStatus, setUserRegStatus] = useState(regStatus);
  const [isResendClicked, setisResendClicked] = useState(false);
  const { login } = useContext(AuthContext);
  const buffer = require('buffer/').Buffer;

  const otpSchema = object({
    otp: string()
      .required("Please enter the OTP")
      .min(6, "OTP minimum digit is 6")
      .max(6, "OTP maximum digit is 6")
  });

  type otpProps = InferType<typeof otpSchema>;

  const { register: otpRegister, handleSubmit: otpHandle, formState: { errors: otpErrors }, reset: otpReset } =
    useForm<otpProps>({
      resolver: yupResolver(otpSchema),
    });


  function handleSubmitOTP() {
    setisResendClicked(false);
    if (requestType === 'genOtp' || requestType === 'savePwd') {
      const data = {
        "input": {
          "employeeUid": buffer.from(params.id, 'base64').toString('ascii'),
          "password": props.details.pwd,
          "otpNumber": otp,
          "action": "ResetPassword",
          "subAction": "reset",
          "linkUid": params.token
        },
        "requestType": {
          "type": 'savePwd'
        }
      };
      dispatch<any>(genOTPandSavePwd(data));
    }

    if (props !== undefined && props !== null && (props.details?.type === 'login'|| props.details?.type === 'validate')) {
      const data = {
        "input": {
          "username": props.details.mail,
          "password": '',
          "otp": otp,
          "subAction": ""
        },
        "nonSSOReqType": {
          "type": "validate"
        }
      }
      dispatch<any>(nonSSOLogin(data));
    }

    if (regrequestType === 'genOtp') {
      const data = {
        "employeeUid": props.details.emp_uid,
        "otpNumber": otp,
        "password": props.details.pwd,
        "action": "RegisterUser",
      }
      dispatch<any>(registerUser(data));

    }
    setUserRegStatus(regStatus);
  }

  function handleResendOTP() {
    setisResendClicked(true);
    let phoneNo = "";
    let mail = "";
    let empType = "";
    let type = "";

    if (regrequestType === 'genOtp') {
      mail = props.details.email;
      phoneNo = props.details.phoneNumber;
      empType = "SignUp";
      type = "genOtp";
    }

    if (requestType === 'genOtp' || requestType === 'savePwd') {
      mail = buffer.from(params.email, 'base64').toString('ascii');
      phoneNo = "";
      empType = "";
      type="reset";
    }

    if (props !== undefined && props !== null && props.details?.type === 'login') {
      mail = props.details.mail;
      phoneNo = "";
      empType = "";
      type= "login";
    }

    const data = {
      "input": {
        "request": {
          "action": "ValidateNewUser",
          "personalEmailAddress": mail,
          "phoneNumber": phoneNo,
          "subAction": empType
        }
      },
      "requestType": {
        "type": type
      }
    }
    dispatch<any>(genOTPandSaveUser(data));
    if (regrequestType === 'genOtp') {
      otpReset();
      setUserRegStatus(null);
    }
  }

  useEffect(() => {
    if (nonSSOState === 'fulfilled' && nonSSOStatus === 'success' && nonSSOReqType === 'validate') {
      const data = {
        accessToken: accesstoken,
        refreshToken: refreshtoken,
      }
       login(data);
    }
  }, [nonSSOState, nonSSOStatus, nonSSOReqType, accesstoken, refreshtoken, login]);

  useEffect(() => {
    setUserRegStatus(regStatus);
  }, [regStatus]);

  useEffect(() => {
    if (otpstate !== '' && otpstate !== 'pending') {
      setisResendClicked(false);
    }
  }, [otpstate]);

  function refreshPage() {
    navigate(LOGIN);
    window.location.reload();
  }

  return (
    <div className='row mx-0 otpContainer mt-5'>
      <div className='col-md-8 mx-auto border shadow-lg'>
        <div className='row'>
          <div className='col-lg-9 order-last order-xs-last order-sm-first order-md-first order-lg-first height-700 otpLeftColumn bg-white'>

            <form className="Auth-form" /* onSubmit={otpHandle(handleSubmitOTP)} */ >

                {(((requestType === 'genOtp') && (state === 'fulfilled' || status === '200')) ||
                (requestType === 'savePwd' && (state === 'pending' || status === '400')) ||
                ((props !== undefined && props !== null && props.details.type === 'login') || (nonSSOReqType === 'validate')) ||
                (regStatus !== '200' && (otpstatus === '200' || otpstatus === '400') && (regrequestType === 'genOtp'))) && 
                <div className="col-12">
                  <div className='row justify-content-center'>
                    <div className='col-md-8'>
                      <h1 className="h3 mb-3 fw-normal mt-5 mb-5 ps-2">One Time Password (OTP) Verification</h1>

                     {(!isResendClicked && (nonSSOState !== 'pending' || otpstate !== 'pending' || state !== 'pending')) &&
                      <div className="alert alert-success">An One Time Password (OTP) has been sent to your mobile number and email address.</div>
                         }
                      <div className="form-floating mb-3 mt-2">
                        <input type="number" className="form-control" id="floatingInput" placeholder="Enter One Time Password (OTP)" {...otpRegister("otp")} onKeyUp={event => setOtp(event.currentTarget.value)} onInput={(e) => e.currentTarget.value = e.currentTarget.value.slice(0, 6)}/>
                        <label htmlFor="floatingInput">Enter One Time Password (OTP)</label>
                      </div>
                      <p className="frmErrors mt-2 pb-2"> {(((requestType === 'savePwd' && status === '400' && state !== 'pending') || (nonSSOStatus === 'fail' && nonSSOState !== 'pending') || (userRegStatus === '400' && regState !== 'pending')) && 'Please enter correct OTP') || (otpErrors?.otp && otpErrors?.otp?.message)}</p>

                      {(!isResendClicked && otpstate !== 'pending') && <CountDownTimer hours={0} minutes={10} seconds={0o0} />}
                      {(!isResendClicked && otpstate !== 'pending') && <div className="resend mt-0 pb-2">Didn't receive the code? &nbsp;<Link to="" onClick={handleResendOTP}>Resend Code</Link></div>}
                      {(isResendClicked && otpstate === 'pending') && <div className="resending mt-0 pb-2">Resending Code... &nbsp; <div className="counter"></div></div>}

                      {/* <button type="submit" disabled={(!isResendClicked && (nonSSOState === 'pending' || state === 'pending' || regState === 'pending' || otpstate === 'pending')) ? true : false} className="w-100 fb-btn mt-5 mobileSSO-btn submit btnSubmit-mail"><div className="">Verify OTP  &nbsp; &nbsp;</div></button> */}

                      <Button onClick={otpHandle(handleSubmitOTP)} className="w-100 fb-btn mt-5 mobileSSO-btn submit btnSubmit-mail" style={{display: 'grid'}}>Verify OTP</Button>

                    </div>
                  </div>
                </div>
                } 
              {userRequestType === 'saveUser' && regStatus === '200' && regState === 'fulfilled' &&
                <div>
                  <h2> Welcome to TTEC.</h2>
                  <div className="alert alert-success">You have registered successfully. You can now login with your registered email and password.</div>
                  <div className="mb-4 mt-5 text-center">
                    <Link to="" onClick={refreshPage}>Back to Sign In</Link>
                  </div>
                </div>
              }
              {nonSSOReqType !== 'login' && requestType === 'savePwd' && status === '200' && state === 'fulfilled' && <div><div className="alert alert-success">Your password has been reset successfully. You can now login with your new password.</div>
                <div className="mb-4 mt-5 text-center">
                  <Link to="" onClick={refreshPage}>Back to Sign In</Link>
                </div>
              </div>}
            </form>
          </div>
          <div className='col-lg-3 order-first order-xs-first order-sm-last order-md-last order-lg-last height-700 immg-column pt-5 text-start ps-lg-5'>
            <h2 className='text-start text-md-center'>Mobile One Time Password (OTP)</h2>
            <p className='text-md-center'>Please enter One Time Password (OTP) which is sent to your mobile and email.</p>
          </div>
        </div>
      </div>
    </div>
  )
}