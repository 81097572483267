import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIDDLE_WARE_BASE_URL, NON_LOGIN_CALLS_API } from "../../../../utils/CONSTANTS";
import axios from "../../../../utils/axios";

const initialState = {
    status : '',
    state : ''
};

export const sendMail = createAsyncThunk("post/sendMail", async (data: any) => {
  const response = axios.postStringRequest(MIDDLE_WARE_BASE_URL() + NON_LOGIN_CALLS_API(), data);
  return response;
});

export const sendMailSlice = createSlice({
    name : 'sendMail',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(sendMail.pending, (state : any) =>
       {
            state.state = 'pending';
       })
       .addCase(sendMail.fulfilled, (state : any,action : any) =>
       {
           let json = JSON.stringify(action?.payload?.data?.response);
           json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
           state.status = JSON.parse(json)?.response?.status;
           state.state = 'fulfilled';
       })
       .addCase(sendMail.rejected, (state : any) =>
       {
            state.state = 'failed';
       })
});

export const selectDetails = ((state : any) => state.sendMail || {});

export default sendMailSlice.reducer;
