import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, NON_LOGIN_RESET_PASSWORD_API, NON_LOGIN_CALLS_API } from '../../../../utils/CONSTANTS';
import axios from '../../../../utils/axios'; 

let requestType : any = null;

const initialState = {
    message : '',
    status : null,
    state : '',
    requestType : ''
};

export const genOTPandSavePwd = createAsyncThunk(
    'post/genOTPnSavePwd', async(data : any) => {
    let response =null;
    requestType = data.requestType;
     if(requestType.type === 'genOtp')
     {
      response =  axios.postStringRequest(MIDDLE_WARE_BASE_URL() + NON_LOGIN_CALLS_API(), data.input);
    }
    else if(requestType.type === 'savePwd' || requestType.type === 'validateLink')
    {
        response =  axios.postRequest(MIDDLE_WARE_BASE_URL() + NON_LOGIN_RESET_PASSWORD_API(), data.input);
    }
    return response;
    }
);

export const genOTPandSavePwdSlice = createSlice({
    name : 'genOTPandSavePwd',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(genOTPandSavePwd.pending, (state : any) =>
       {
            state.state = 'pending';
       })
       .addCase(genOTPandSavePwd.fulfilled, (state : any,action : any) =>
       {
        let json = JSON.stringify(action?.payload?.data?.response);
        json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');

      //  state.message = JSON.parse(json)?.response?.validate_message;
        state.status = JSON.parse(json)?.response?.status;
        state.requestType = requestType.type; 
        state.state = 'fulfilled';
       })
       .addCase(genOTPandSavePwd.rejected, (state : any) =>
       {
            state.state = 'failed';
       })
});

export const selectOTPandPwdDetails = ((state : any) => state.genOTPandSavePwd || {});
 
export default genOTPandSavePwdSlice.reducer;