import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, NON_LOGIN_REGISTER_API } from '../../../../utils/CONSTANTS';
import axios from '../../../../utils/axios';

// let requestType: string = '';

const initialState = {
    password: null,
    otp_number: null,
    emp_uid: null,
    regStatus: null,
    validate_message: null,
    message: '',
    regState: '',
    userRequestType: 'saveUser'
};

export const registerUser = createAsyncThunk(
    'post/registerUser', async (data: any) => {
        let response = null;
        response = axios.postRequest(MIDDLE_WARE_BASE_URL() + NON_LOGIN_REGISTER_API(), data);
        return response;
    }
);

export const registerUserSlice = createSlice({
    name: 'registerUser',
    initialState,
    reducers: {},
    extraReducers: (builder: any) =>
        builder
            .addCase(registerUser.pending, (state: any) => {
                state.regState = 'pending';
            }
            )
            .addCase(registerUser.fulfilled, (state: any, action: any) => {
                let json = JSON.stringify(action?.payload?.data?.response);
                json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"', '}');
                state.regStatus = JSON.parse(json)?.response?.status;
               // state.validate_message = JSON.parse(json)?.response?.validate_message;
                state.userRequestType = 'saveUser';
                state.regState = 'fulfilled';
            })
            .addCase(registerUser.rejected, (state: any) => {
                state.regState = 'failed';
            })
});



export const selectUserDetails = ((state: any) => state.registerUser || {});

export default registerUserSlice.reducer;