import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./homePageCarousel.scss";
import { Button } from "primereact/button";

import welcomeBanner from "../../../images/hero-jobs-2560x600-2021-1.png";
import welcomeBannerMobile from "../../../images/hero-jobs-2021-1-mobile.png";

import HBD from "../../../images/BirthdayTemplate.png";
import HBDMOBILE from "../../../images/BirthdayTemplateMobile.png";
import welcomeMyLouieTasks from "../../../images/welcomeMyLouieTasks.png";
import welcomeMyLouieTasksMobile from "../../../images/welcomeMyLouieTasksMobile.png";
import AnniDay from "../../../images/Anniversary Banner.png";
import AnniDayMobile from "../../../images/Anniversary Banner Mobile.png";
import HXconnect from "../../../images/HXconnect Banner.png";
import HXconnectMobile from "../../../images/HXconnect Banner Mobile.png";
import ViewPay from "../../../images/Banner__Payment.png";
import ViewPayMobile from "../../../images/ViewPayMobile.png";
import ASKNow from "../../../images/ASK Now.png";
import ASKNowMobile from "../../../images/AskNow Mobile.png";
import EmployeeReferralProgram from "../../../images/Emp_referral_img.png";
import EmployeeReferralProgramMobile from "../../../images/Emp_referral_mobile_img.png";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import DialogBox from "../ProfileLanding/Resources/DialogBox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { getNonLouielogic } from "../louie/NonLouielogic/NonLouielogicSlice";

export function HomePageCarousel(props: any) {
  const navigateMyLouieTasks = useNavigate();
  const { NonLouielogicflag } = useSelector(getNonLouielogic);
  const carouselTitles: any = [];
  carouselTitles.push("Welcome to TTEC");
  props?.isBirthDay && carouselTitles.push("Birthday Wishes");
  props?.isAnniDay && carouselTitles.push("Happy Anniversary");
  NonLouielogicflag === "false" && carouselTitles.push("My Louie Tasks");
  props?.isSSOLogin === true && carouselTitles.push("HXconnect");
  props?.isSSOLogin === true && carouselTitles.push("ASKNow");
  props?.isSSOLogin === true && carouselTitles.push("View Payment Details");
  props?.isSSOLogin === true && carouselTitles.push("Employee Referral Program");
  const [visible, setVisible] = useState(false);
  const [playBtnVisibility, setPlayBtnVisibility] = useState(true);

  const sliderContainer = useRef<any>(null);

  const sliderHeightValue = sliderContainer?.current?.innerSlider?.list
    ?.getAttribute("style")
    ?.split("height:")[1]
    ?.split("px")[0];

  const [sliderHeight, setSliderHeight] = useState(sliderHeightValue);

  const footerContent = (
    <div>
      <Button label="OK" onClick={() => setVisible(false)} autoFocus />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: function (dots: any) {
      return (
        <div
          style={{
            padding: "10px",
            position: "relative",
            /* height: "356px", */
            height: sliderHeight - 6 + "px",
            minHeight: sliderHeight - 6 + "px",
            overflow: "auto",
            backgroundColor: "#c4ff85",
          }}
        >
          <ul style={{ margin: "0px", paddingLeft: "0px" }}> {dots} </ul>
        </div>
      );
    },
    customPaging: function (i: any) {
      return <div className="btn7">{carouselTitles[i]}</div>;
    },
  };

  const navigateToMyLouieTasks = () => {
    navigateMyLouieTasks("/mylouietasks");
  };

  const onClick = (name: any) => {
    props.dialogFuncMap[`${name}`](true);
  };

  const onHide = (name: any) => {
    props.dialogFuncMap[`${name}`](false);
  };

  useEffect(() => {
    setSliderHeight(sliderHeightValue);
  }, [sliderHeightValue]);

  const playSlider = () => {
    sliderContainer?.current?.slickPlay();
    setPlayBtnVisibility(true);
  };

  const pauseSlider = () => {
    sliderContainer?.current?.slickPause();
    setPlayBtnVisibility(false);
  };
  let countryName = props?.profileData?.automation?.employee?.location_country;
  return (
    <>
      <Slider {...settings} ref={sliderContainer}>
        <div className="card border-0 mb-0 pb-0">
          <img
            src={welcomeBanner}
            className="w-100 img-fluid desktopImage"
            alt="Welcome to TTEC"
          />
          <img
            src={welcomeBannerMobile}
            className="w-100 img-fluid mobileImage"
            alt="Welcome to TTEC"
          />
          <div className="card-img-overlay text-start">
            <div className="row text-end">
              <div className="col-md-12 carousel-button-demo me-3">
                <div className="template welcomeSlideTemplate">
                  <Button
                    className="youtube p-0 me-0"
                    aria-label="Youtube"
                    onClick={() => onClick("displayBasic")}
                  >
                    <i className="pi pi-youtube p-3"></i>
                    <span className="px-2 px-md-3">
                      Watch the welcome video!
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="row position-absolute bottom-0 end-0 mb-4">
              <div className="col-md-12">
                {props?.profileData?.oracle?.employee?.first_name ? (
                  <h2 className="text-end me-5 pe-5">
                    Welcome to TTEC, {props?.displayName}
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {props?.isBirthDay && (
          <div className="card border-0 mb-0 pb-0">
            <img
              src={HBD}
              className="w-100 img-fluid desktopImage"
              alt="Birthday Wishes"
            />
            <img
              src={HBDMOBILE}
              className="w-100 img-fluid mobileImage"
              alt="Birthday Wishes"
            />
            <div className="card-img-overlay text-start">
              <div className="row mb-4">
                <div className="col-md-12 pt-3 ms-2 ms-md-5">
                  {props?.profileData?.oracle?.employee?.first_name ? (
                    <>
                      <h2 className="text-start mb-4">
                        Happy Birthday, {props?.displayName}
                      </h2>
                      <p className="text-start">
                        The TTEC family wishes you Happy Birthday!
                      </p>
                      <p className="text-start">
                        We wish you all the good things that life can bring you
                        on this special day!
                      </p>
                      <p className="text-start">TTEC Family</p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {props?.isAnniDay && (
          <div className="card border-0 mb-0 pb-0">
            <img
              src={AnniDay}
              className="w-100 img-fluid desktopImage"
              alt="Anniversary Wishes"
            />
            <img
              src={AnniDayMobile}
              className="w-100 img-fluid mobileImage"
              alt="Anniversary Wishes"
            />
            <div className="card-img-overlay text-start">
              <div className="row mb-4">
                <div className="col-md-12 pt-0 pt-md-3 ms-2 ms-md-5">
                  {props?.profileData?.oracle?.employee?.first_name ? (
                    <>
                      <h2 className="text-start mb-2 mb-md-4">
                        Happy Anniversary {props?.displayName}!!!
                      </h2>
                      <p className="text-start mb-1">
                        The TTEC family wishes you Happy Anniversary! We want to
                        congratulate you on your {props?.expYears}-year
                        anniversary!
                      </p>
                      <p className="text-start mb-1">
                        Your hard work and dedication are vital to TTEC's
                        success.
                      </p>
                      <p className="text-start">TTEC Family</p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {NonLouielogicflag === "false" && (
          <div className="card border-0 mb-0 pb-0">
            <img
              src={welcomeMyLouieTasks}
              className="w-100 desktopImage"
              alt="My Louie Task"
            />
            <img
              src={welcomeMyLouieTasksMobile}
              className="w-100 mobileImage"
              alt="My Louie Task"
            />
            <div className="card-img-overlay text-end">
              <div className="row mb-4">
                <div className="col-md-12 pt-3 text-end me-5 pe-4">
                  <div className="template">
                    <Button
                      className="me-0 mb-5"
                      onClick={() => navigateToMyLouieTasks()}
                    >
                      My Louie Tasks
                    </Button>

                    <h2 className="position-absolute bottom-0 text-end mb-5 end-0 pe-4">
                      Complete My Louie Tasks
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <img
              src={HXconnect}
              className="w-100 img-fluid desktopImage"
              alt="HXconnect"
            />
            <img
              src={HXconnectMobile}
              className="w-100 img-fluid mobileImage"
              alt="HXconnect"
            />
            <div className="card-img-overlay text-start">
              <div className="row mb-4">
                <div className="col-md-12 pt-3">
                  {props?.profileData?.oracle?.employee?.first_name ? (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <h2 className="text-end">
                            Do you have any question related to Human Resources?
                          </h2>
                        </div>
                      </div>

                      <div className="row position-absolute bottom-0 end-0 mb-3 me-4">
                        <div className="col-md-12">
                          <p className="text-end">
                            Visit{" "}
                            <a
                              href="https://asknow.service-now.com/esc"
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              HXconnect
                            </a>{" "}
                            or contact the Human <br /> Capital representative
                            for your site.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <img
              src={ASKNow}
              className="w-100 img-fluid desktopImage"
              alt="ASKNow"
            />
            <img
              src={ASKNowMobile}
              className="w-100 img-fluid mobileImage"
              alt="ASKNow"
            />
            <div className="card-img-overlay text-start">
              <div className="row mt-0 mt-md-4">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="text-end me-5 pe-4 desktopTextWhite">
                        Is technology playing tricks on you?
                      </h2>
                    </div>
                  </div>

                  <div className="row position-absolute bottom-0 end-0 mb-0 me-2 ms-2 mb-md-3 me-md-4 desktopTextWhite">
                    <div className="col-md-12">
                      <p className="text-end pe-4">
                        Visit{" "}
                        <a
                          href="https://asknow.service-now.com/asknow/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          ASKNow
                        </a>{" "}
                        ,TTEC's IT support Service Desk.
                        <br /> Here, you'll find instructions on how to access
                        tools and <br />
                        troubleshooting tips that will help you solve your issue
                        quickly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <img
              src={ViewPay}
              className="w-100 img-fluid desktopImage"
              alt="Your pay matters"
            />
            <img
              src={ViewPayMobile}
              className="w-100 img-fluid mobileImage"
              alt="Your pay matters"
            />
            <div className="card-img-overlay text-start">
              <div className="row mb-4">
                <div className="col-md-12 pt-3 ms-5">
                  <div className="row mb-5">
                    <div className="col-md-12 mb-3">
                      <h1 className="text-start">Your pay matters</h1>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Button
                        className="me-0 text-black text-start d-block mt-3 text-white"
                        onClick={() => setVisible(true)}
                      >
                        View Payment Details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(countryName === "BG" ||
          countryName === "GR" ||
          countryName === "IE" || countryName === "PL" ||
          countryName === "UK") && props?.isSSOLogin === true && (
            <div className="card border-0 mb-0 pb-0">
              <a
                href="https://linktr.ee/ttecreferralEMEA"
                target={"_blank"}
                rel="noreferrer"
              ><img
                  src={EmployeeReferralProgram}
                  className="w-100 img-fluid desktopImage"
                  alt="Employee Referral Program"
                /></a>
              <a
                href="https://linktr.ee/ttecreferralEMEA"
                target={"_blank"}
                rel="noreferrer"
              ><img
                  src={EmployeeReferralProgramMobile}
                  className="w-100 img-fluid mobileImage"
                  alt="Employee Referral Program"
                /></a>
            </div>
          )}
        {(countryName === "US" || countryName === "CA") && props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <a
              href="https://linktr.ee/TTECReferral"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgram}
                className="w-100 img-fluid desktopImage"
                alt="Employee Referral Program"
              /></a>
            <a
              href="https://linktr.ee/TTECReferral"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgramMobile}
                className="w-100 img-fluid mobileImage"
                alt="Employee Referral Program"
              /></a>
          </div>
        )}
        {countryName === "MX" && props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <a
              href="https://linktr.ee/TTECmx"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgram}
                className="w-100 img-fluid desktopImage"
                alt="Employee Referral Program"
              /></a>
            <a
              href="https://linktr.ee/TTECmx"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgramMobile}
                className="w-100 img-fluid mobileImage"
                alt="Employee Referral Program"
              /></a>
          </div>
        )}{countryName === "PH" && props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <a
              href="https://teletechinc.sharepoint.com/teams/Employee_Referral_Program/SitePages/Philippines.aspx"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgram}
                className="w-100 img-fluid desktopImage"
                alt="Employee Referral Program"
              /></a>
            <a
              href="https://teletechinc.sharepoint.com/teams/Employee_Referral_Program/SitePages/Philippines.aspx"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgramMobile}
                className="w-100 img-fluid mobileImage"
                alt="Employee Referral Program"
              /></a>
          </div>
        )}
        {countryName === "CO" && props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <a
              href="https://linktr.ee/TTEC_Colombia"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgram}
                className="w-100 img-fluid desktopImage"
                alt="Employee Referral Program"
              /></a>
            <a
              href="https://linktr.ee/TTEC_Colombia"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgramMobile}
                className="w-100 img-fluid mobileImage"
                alt="Employee Referral Program"
              /></a>
          </div>
        )}
        {countryName === "BR" && props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <a
              href="https://linktr.ee/TTECbr"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgram}
                className="w-100 img-fluid desktopImage"
                alt="Employee Referral Program"
              /></a>
            <a
              href="https://linktr.ee/TTECbr"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgramMobile}
                className="w-100 img-fluid mobileImage"
                alt="Employee Referral Program"
              /></a>
          </div>
        )}
        {countryName === "AU" && props?.isSSOLogin === true && (
          <div className="card border-0 mb-0 pb-0">
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=UJpcXVTIo06wXRY4ixTVs6n2n0y1UthMlSgAfOM8HGRURU0xMExJSVhVT1lZMDBSRFpKTEZESFBQTSQlQCN0PWcu"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgram}
                className="w-100 img-fluid desktopImage"
                alt="Employee Referral Program"
              /></a>
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=UJpcXVTIo06wXRY4ixTVs6n2n0y1UthMlSgAfOM8HGRURU0xMExJSVhVT1lZMDBSRFpKTEZESFBQTSQlQCN0PWcu"
              target={"_blank"}
              rel="noreferrer"
            ><img
                src={EmployeeReferralProgramMobile}
                className="w-100 img-fluid mobileImage"
                alt="Employee Referral Program"
              /></a>
          </div>
        )}

      </Slider>
      <div className="playPauseBtnContainer">
        {playBtnVisibility === true ? (
          <button className="button playPauseBtn" onClick={pauseSlider}>
            <FontAwesomeIcon icon={faPause} className="playPauseBtnIcon" />
          </button>
        ) : (
          <button className="button playPauseBtn" onClick={playSlider}>
            <FontAwesomeIcon icon={faPlay} className="playPauseBtnIcon" />
          </button>
        )}
      </div>

      <Dialog
        header="Your Pay Matters"
        visible={visible}
        style={{ width: "60vw" }}
        onHide={() => {
          setVisible(false);
        }}
        footer={footerContent}
      >
        <DialogBox></DialogBox>
      </Dialog>

      <Dialog
        visible={props.displayBasic}
        onHide={() => onHide("displayBasic")}
        className="displayBasicDialog"
      >
        <iframe
          title="welcome video"
          width="100%"
          height="100%"
          src="https://ttecvideos.wistia.com/medias/0900bzdjp2"
        ></iframe>
      </Dialog>
    </>
  );
}
