import LouieData from './LouieData/LouieData';

const Louie = () => {
  return (
    <>
      <LouieData />
    </>
  );
};
export default Louie;
