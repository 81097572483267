import "./timeLineItem.scss";

import React, { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from "react-redux";
import { louieLinkData, getLouieLinkData } from "../louie/LouieLink/LouieLinkSlice";
import { ScrollPanel } from "primereact/scrollpanel";
import jwt_decode from "jwt-decode";


export default function TimelineItem(data: any) {
    var colorBgStatus = '';

    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    /* const [plainText, setPlainText] = useState(''); */
    const [oracleId, setOracleId] = useState("0");
    const { louiehtmlContent,louieLinkState } = useSelector(getLouieLinkData);

    useEffect(() => {
        const SSOToken = localStorage.getItem("AccessToken");
        const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
        let id: any;
        if (SSOToken) {
            id = localStorage.getItem("oracleId");
        }
        if (nonSSOToken) {
            const decodedToken: any = jwt_decode(nonSSOToken);
            id = decodedToken?.oracleID;
        }
        setOracleId(id);
    }, []);


    const footerContent = (
        <div>
            <Button label="OK" onClick={() => setVisible(false)} autoFocus />
        </div>
    );
    if (data?.data?.status === 'Completed') {
        colorBgStatus = '#2aa154'
    }
    else if (data?.data?.status === 'Overdue') {
        colorBgStatus = '#FF0000'
    }
    else if (data?.data?.status === 'In-Progress') {
        colorBgStatus = '#FFD700'
    }
    else if (data?.data?.status === 'Upcoming') {
        colorBgStatus = '#9da194'
    }
    else {
        colorBgStatus = '#36454F'
    }
    const sched_step_uid = useState(data?.data?.stepId);


    const LinkHandler = () => {
        setVisible(true)
        const data = {
            request: {
                "action": "getLouieLinkData",
                "oracleId": oracleId,
                "stepId": sched_step_uid
            }
        };
        dispatch(louieLinkData(data));
    }



    return (<div className="timeline-item">
        <div className="timeline-item-content">
            <span className="tag" style={{ background: colorBgStatus }}>
                {data?.data?.status}
            </span>
            <time>Scheduled Date : {data?.data?.sched_date}</time>
            <time>Completed Date : {data?.data?.complete_date ? data?.data?.complete_date : "NA"}</time>
            <p>{data?.data?.email_subject}</p>
            <>
                {data?.data?.microsite_URL && (
                    <a
                        href={data?.data?.microsite_URL}
                        // target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click here to know more
                    </a>
                )}
                {data?.data?.status === 'Completed' && (
                    <><Button label="Click here to know more" onClick={LinkHandler} className="p-button-text" />
                        {louieLinkState === 'fulfilled' && (<Dialog header="New Hire" visible={visible} style={{ width: "60vw" }} onHide={() => setVisible(false)} footer={footerContent}>

                            <ScrollPanel style={{ width: "auto", maxHeight: "400px", overflow: "auto" }}>

                                <div >{louiehtmlContent}</div>                        
                            </ScrollPanel>

                        </Dialog>)}

                    </>

                )}

            </>

            <span className="circle" />
        </div>
    </div>
    )
}