import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../utils/CONSTANTS";

const initialState = {
  pizzaState: "pending",
  pizzaTrackerData: null,
};

export const pizzaTracker = createAsyncThunk(
  "get/pizzaTracker",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), //NON_LOGIN_CALLS_API(), // change it to CALL_PPOR_API
      data
    );
    return response;
  }
);

const pizzaTrackerSlice = createSlice({
  name: "pizzaTracker",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(pizzaTracker.pending, (pizzaTrackerState) => {
        pizzaTrackerState.pizzaState = "pending";
      })
      .addCase(pizzaTracker.fulfilled, (pizzaTrackerState, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        pizzaTrackerState.pizzaState = "fulfilled";
        pizzaTrackerState.pizzaTrackerData =
          tempRes?.response?.PizzaTracker?.stages.stage;
        // console.log(profileState.profileData);
      })
      .addCase(pizzaTracker.rejected, (pizzaTrackerState) => {
        pizzaTrackerState.pizzaState = "failed";
      }),
});

// export const state = (state: any) => state["profile"].state;
// export const empProfileData = (state: any) => state.empProfile;
// export const empProfileData = (state: any) => state["profile"].profileData;

// export const empProfileDetails = (state: any) => state.empProfile;

export const pizzaTrackerDetails = (state: any) => state.pizzaTracker;

export default pizzaTrackerSlice.reducer;
