import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  MIDDLE_WARE_BASE_URL,
  CALL_PPOR_API
} from "../../../../utils/CONSTANTS";

const initialState = {
  state: "pending",
  tasksData: "",
  stepId:""
};

export const tasksList = createAsyncThunk(
  "get/taskList",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), 
      data
    );
    return response;
  }
);

const tasksListSlice = createSlice({
  name: "tasksList",
  initialState,
  reducers: {getStepId:(state,action)=>{
    state.stepId=action.payload;
  }},
  extraReducers: (builder) =>
    builder
      .addCase(tasksList.pending, (state) => {
        state.state = "pending";
      })
      .addCase(tasksList.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.state = "fulfilled";
        state.tasksData = tempRes?.response?.data?.tasks?.row;
        
      })
      .addCase(tasksList.rejected, (stageState) => {
        stageState.state = "failed";
      }),
});

export const tasksListDetails = (state: any) => state["tasksList"].tasksData;
export const stepIdDetails=(state: any) => state["tasksList"].stepId;
export const tasksDetails = (state: any) => state["tasksList"].state;

export const {getStepId}=tasksListSlice.actions;
export default tasksListSlice.reducer;