import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../utils/CONSTANTS";

const initialState = {
  state: "pending",
  insertProfileUpdate: null,
};

export const profilePage: any = createAsyncThunk(
  "get/profilePage",
  async (data: any) => {
    const response = await axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const profilePageSlice = createSlice({
  name: "profilePage",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(profilePage.pending, (profileState) => {
        profileState.state = "pending";
      })
      .addCase(profilePage.fulfilled, (profileState, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        profileState.state = "fulfilled";
        profileState.insertProfileUpdate = tempRes?.response;
      })
      .addCase(profilePage.rejected, (profileState) => {
        profileState.state = "failed";
      }),
});

// export const state = (state: any) => state["profile"].state;
// export const empProfileData = (state: any) => state.empProfile;
// export const empProfileData = (state: any) => state["profile"].profileData;

export const InsertProfilePage = (state: any) => state.profilePage;

export default profilePageSlice.reducer;
