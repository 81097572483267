/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck,
  faReceipt,
  faEnvelope,
  faUserPlus,
  faArrowRightToBracket,
  faListCheck,
  faQuestionCircle,
  faCheckToSlot,
  faBriefcase,
  faLandmark,
  faMoneyBill,
  faPersonDotsFromLine,
  faCalendarDays,
  faCreditCard,
  faTshirt, faGift, faBookOpen, faCheckSquare, faQuestion, faDatabase
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "primereact/badge";
import "./resources.scss";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import {
  inboxEmail,
  selectInboxEmail,
} from "../../myMessages/inbox/inboxEmailSlice";
import jwt_decode from "jwt-decode";
import { empProfileDetails } from "../Profile/ProfileSlice";
import { MYLOUIETASKS } from "../../../../utils/CONSTANTS";
import DialogBox from "./DialogBox";
import {
  getNonLouielogic,
  NonLouielogic,
} from "../../louie/NonLouielogic/NonLouielogicSlice";
import { ScrollPanel } from "primereact/scrollpanel";
import { FedExDetails, getlistFedExDetails } from "./getFedExDetails";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Resources = () => {
  const { inboxData } = useSelector(selectInboxEmail);
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [oracleId, setOracleId] = useState("0");
  const dispatch = useDispatch();
  const { profileData } = useSelector(empProfileDetails);
  const FedExDetailsData = useSelector(getlistFedExDetails);
  const listFedExDetails = useSelector(getlistFedExDetails);
  const { NonLouielogicflag } = useSelector(getNonLouielogic);
  const [isSSOLogin, setIsSSOLogin] = useState(false);
  const workArrangementType =
    profileData?.automation?.employee?.work_arrangement_type;
  const POD = profileData?.POD;
  const linkStyle: React.CSSProperties = {
    color: "orange",
  };
  const footerContent = (
    <div>
      <Button label="OK" onClick={() => setVisible(false)} autoFocus />
    </div>
  );
  const [visible, setVisible] = useState(false);
  const [pluxeeCard, setPluxeeCard] = useState(false);
  const [EquipmentTracker, setEquipmentTracker] = useState(false);
  const [DressCode, setDresscode] = useState(false);

  const footerContentPC = (
    <div>
      <Button label="OK" onClick={() => setPluxeeCard(false)} autoFocus />
    </div>
  );
  const footerContentDC = (
    <div>
      <Button label="OK" onClick={() => setDresscode(false)} autoFocus />
    </div>
  );
  const footerContentET = (
    <div>
      <Button
        label="Close"
        onClick={() => setEquipmentTracker(false)}
        autoFocus
      />
    </div>
  );
  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
      setIsSSOLogin(true);
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId"), localStorage.getItem("email")]);

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const data = {
        input: {
          request: {
            action: "Email",
            emailType: "EmailInbox",
            userOracleId: oracleId,
          },
        },
      };
      dispatch<any>(inboxEmail(data));
    }
  }, [dispatch, oracleId]);
  let countryName = profileData?.automation?.employee?.location_country;

  useEffect(() => {
    if (
      oracleId !== null &&
      oracleId !== undefined &&
      oracleId !== "0" &&
      countryName !== null &&
      countryName !== undefined &&
      countryName !== ""
    ) {
      const data = {
        request: {
          action: "NonLouielogic",
          oracleId: oracleId,
          country: countryName,
          processName: countryName + " Onboarding",
        },
      };
      dispatch<any>(NonLouielogic(data));
    }
  }, [dispatch, oracleId, countryName]);

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const data = {
        request: {
          action: "getFedExDetails",
          oracleId: oracleId,
        },
      };
      dispatch<any>(FedExDetails(data));
    }
  }, [dispatch, oracleId]);
  useEffect(() => {
    setMessages(inboxData);
  }, [inboxData]);

  const getLink = (rowData: any) => {
    return (
      <a
        href={`https://www.fedex.com/fedextrack/?trknbr=${rowData.TrackingNumber}`}
        target={"_blank"}
        rel="noreferrer"
      >
        {rowData.TrackingNumber}
      </a>
    );
  };
  useEffect(() => {
    const unreadMessages = messages.filter(
      (item: any) =>
        item.emailMain.status === "unread" &&
        item.emailMain?.emailDetails[0]?.sender_oracle_id !== oracleId
    );
    const unreadMessagesCount = unreadMessages.length;
    setUnreadCount(unreadMessagesCount);
  }, [messages, oracleId]);

  return (
    <div className="card h-100 shadow-lg heightAuto">
      <div className="card-body mt-3">
        <ul className="list-unstyled customListStyle ms-3 myResourcesSectionLinks">
          {countryName === "PL" && (<>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faDatabase}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a href="https://welcome.ttecjobs.com/krakow/"> View Onboarding Details</a>
            </li></>
          )}
          {workArrangementType === "Work At Home" && POD === "TRUE" && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              {unreadCount !== 0 && (
                <a href="/messages">
                  <span style={{ color: "rgb(255, 153, 51)" }}>
                    My Messages
                  </span>{" "}
                  <Badge
                    value={unreadCount}
                    className="mr-2"
                    severity="warning"
                  ></Badge>
                </a>
              )}
              {unreadCount === 0 && <a href="/messages">My Messages</a>}
            </li>
          )}
          <li className="mb-3 fw-bold text-break-all pe-3">
            <FontAwesomeIcon
              icon={faUserCheck}
              className="me-3"
              style={{ fontSize: "1rem" }}
            />
            <a href="/profile">My Profile</a>
          </li>
          {countryName === "MX" && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faReceipt}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://mybenefits.ttec.com/mex/associates/new-hires//"
                target={"_blank"}
                rel="noreferrer"
              >
                My Benefits
              </a>
            </li>
          )}
          {countryName === "PL" && isSSOLogin === true && (<>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a href="https://welcome.ttecjobs.com/krakow/benefits/" target="_blank" rel="noreferrer">My Benefits</a>
            </li></>
          )}
          {countryName === "PL" && isSSOLogin !== true && (<>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a href="mailto:krakowta@ttec.com" target="_blank" rel="noreferrer">My Benefits</a>
            </li></>
          )}

          {countryName === "PH" && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faReceipt}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://mybenefits.ttec.com/phl/"
                target={"_blank"}
                rel="noreferrer"
              >
                My Benefits
              </a>
            </li>
          )}
          {countryName === "US" && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faReceipt}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="http://www.hellottecbenefits.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                Hello TTEC Benefits
              </a>
            </li>
          )}
          <li className="mb-3 fw-bold text-break-all pe-3">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="me-3"
              style={{ fontSize: "1rem" }}
            />
            <a
              href="https://www.ttecjobs.com/en/faqs"
              target={"_blank"}
              rel="noreferrer"
            >
              New Hire FAQs
            </a>
          </li>
          {NonLouielogicflag === "false" && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faListCheck}
                className="me-3"
                style={{ color: "rgb(255, 153, 51)", fontSize: "1rem" }}
              />
              <a href={MYLOUIETASKS} style={linkStyle}>
                My Louie Tasks
              </a>
            </li>
          )}

          {(countryName === "US" || countryName === "CA") && isSSOLogin === true &&  (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://linktr.ee/TTECReferral"
                target={"_blank"}
                rel="noreferrer"
              >
                Employee Referral Program
              </a>
            </li>
          )}
          {countryName === "MX" && isSSOLogin === true && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://linktr.ee/TTECmx"
                target={"_blank"}
                rel="noreferrer"
              >
                Employee Referral Program
              </a>
            </li>
          )}
          {countryName === "CO" && isSSOLogin === true && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://linktr.ee/TTEC_Colombia"
                target={"_blank"}
                rel="noreferrer"
              >
                Employee Referral Program
              </a>
            </li>
          )}

          {countryName === "BR" && isSSOLogin === true && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://linktr.ee/TTECbr"
                target={"_blank"}
                rel="noreferrer"
              >
                Employee Referral Program
              </a>
            </li>
          )}
          {countryName === "AU" && isSSOLogin === true && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=UJpcXVTIo06wXRY4ixTVs6n2n0y1UthMlSgAfOM8HGRURU0xMExJSVhVT1lZMDBSRFpKTEZESFBQTSQlQCN0PWcu"
                target={"_blank"}
                rel="noreferrer"
              >
                Employee Referral Program
              </a>
            </li>
          )}
          {(countryName === "BG" ||
            countryName === "GR" || 
            countryName === "IE" || countryName === "PL" ||
            countryName === "UK") && isSSOLogin === true && (
              <li className="mb-3 fw-bold">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://linktr.ee/TTECReferralEMEA"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Employee Referral Program
                </a>
              </li>
            )}
          {countryName === "PH" && isSSOLogin === true && (<>
            <li className="mb-3 fw-bold">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://teletechinc.sharepoint.com/teams/Employee_Referral_Program/SitePages/Philippines.aspx"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Employee Referral Program
                </a>
              </li>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faGift}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://asknow.service-now.com/esc?id=hr_kb_article_view&sysparm_article=KB0033536&sys_kb_id=a0d2fcf0937c0e50589fb22d6cba10a0&spa=1"
                target={"_blank"}
                rel="noreferrer"
              >
                BAG Sheet (Benefits at a Glance)
              </a>
            </li>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faBookOpen}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://asknow.service-now.com/now/nav/ui/classic/params/target/%24viewer.do%3Fsysparm_stack%3Dno%26sysparm_sys_id%3D1176a1a14712b110bb82e54a436d43f7"
                target={"_blank"}
                rel="noreferrer"
              >
                Statutory Guide for New Hires
              </a>
            </li>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://asknow.service-now.com/sys_attachment.do?sys_id=c9dd99a1479e7110bb82e54a436d4363&view=true"
                target={"_blank"}
                rel="noreferrer"
              >
                BPI Survey Guide
              </a>
            </li>
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faQuestion}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://asknow.service-now.com/sys_attachment.do?sys_id=ef3ed9a1479e7110bb82e54a436d439f&view=true"
                target={"_blank"}
                rel="noreferrer"
              >
                BPI Payroll Account Opening FAQs
              </a>
            </li></>
          )}
          {countryName === "US" && isSSOLogin === true && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faArrowRightToBracket}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                href="https://oracle.ttec.com/OA_HTML/OA.jsp?OAFunc=OANEWHOMEPAGE"
                target={"_blank"}
                rel="noreferrer"
              >
                Oracle Sign In
              </a>
            </li>
          )}
          {isSSOLogin === true && (
            <>
              <li className="mb-3 fw-bold text-break-all pe-3">
                <FontAwesomeIcon
                  icon={faArrowRightToBracket}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://ttectalent.sumtotal.host/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  TTEC Talent
                </a>
              </li>
              <li className="mb-3 fw-bold text-break-all pe-3">
                <FontAwesomeIcon
                  icon={faCheckToSlot}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://teletechinc.sharepoint.com/sites/mosaicIntranetProdPolicies/SitePages/Home.aspx"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Policies
                </a>
              </li>
              <li className="mb-3 fw-bold text-break-all pe-3">
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://teletechinc.sharepoint.com/sites/mosaicIntranetProdHome"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Mosaic
                </a>
              </li>
              <li className="mb-3 fw-bold text-break-all pe-3">
                <FontAwesomeIcon
                  icon={faLandmark}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://asknow.service-now.com/esc"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  HXconnect
                </a>
              </li>
              <li className="mb-3 fw-bold text-break-all pe-3">
                <FontAwesomeIcon
                  icon={faPersonDotsFromLine}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://asknow.service-now.com/asknow/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  ASKNow
                </a>
              </li>
              <li className="mb-3 fw-bold text-break-all pe-3">
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="me-3"
                  style={{ fontSize: "1rem" }}
                />
                <a
                  href="https://teletechinc.sharepoint.com/teams/Enterprise_Services/SitePages/Payroll-Calendars.aspx/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  View Payroll Calendar
                </a>
              </li>
              {countryName === "MX" && (
                <li className="mb-3 fw-bold text-break-all pe-3">
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className="me-3"
                    style={{ fontSize: "1rem" }}
                  />
                  <a
                    onClick={() => setPluxeeCard(true)}
                    style={{
                      color: "var(--bs-link-color)",
                      cursor: "pointer",
                    }}
                  >
                    My Pluxee Card
                  </a>
                </li>
              )}
              {(countryName === "US" ||
                countryName === "MX" ||
                countryName === "PL" ||
                countryName === "PH") && (
                  <li className="mb-3 fw-bold text-break-all pe-3">
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="me-3"
                      style={{ fontSize: "1rem" }}
                    />
                    <a
                      onClick={() => setVisible(true)}
                      style={{
                        color: "var(--bs-link-color)",
                        cursor: "pointer",
                      }}
                    >
                      View Payment Details
                    </a>
                  </li>
                )}
            </>
          )}
          <li className="mb-3 fw-bold text-break-all pe-3">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-3"
              style={{ fontSize: "1rem" }}
            />
            <a
              onClick={() => setEquipmentTracker(true)}
              style={{
                color: "var(--bs-link-color)",
                cursor: "pointer",
              }}
            >
              Equipment Tracker
            </a>
          </li>

          {countryName === "PH" && (
            <li className="mb-3 fw-bold text-break-all pe-3">
              <FontAwesomeIcon
                icon={faTshirt}
                className="me-3"
                style={{ fontSize: "1rem" }}
              />
              <a
                onClick={() => setDresscode(true)}
                style={{
                  color: "var(--bs-link-color)",
                  cursor: "pointer",
                }}
              >
                Dress Code
              </a>
            </li>
          )}
        </ul>
        <Dialog
          header="Your Pay Matters"
          visible={visible}
          style={{ width: "60vw" }}
          onHide={() => setVisible(false)}
          footer={footerContent}
        >
          <DialogBox></DialogBox>
        </Dialog>
        <Dialog
          header="Dress Code"
          visible={DressCode}
          style={{ width: "80vw", height: "100%" }}
          onHide={() => setDresscode(false)}
          footer={footerContentDC}
        >
          <iframe
            title="Dress Code"
            width="100%"
            height="100%"
            src="docs/PH Dresscode.pdf"
          ></iframe>
        </Dialog>
        <Dialog
          header="My Pluxee Card"
          visible={pluxeeCard}
          style={{ width: "60vw" }}
          onHide={() => setPluxeeCard(false)}
          footer={footerContentPC}
        >
          <ScrollPanel style={{ width: "auto", overflow: "auto" }}>
            <p className="text-center">
              <img
                src="./images/My Pluxee Card.png"
                className="img-fluid w-50"
                alt="Pluxee Card"
              ></img>
            </p>
            <p>
              You will receive Pluxee restaurant card as soon as you meet the 90
              days hire date. Download the Pluxee México app below to view the
              list of establishments, where you can use the card to purchase
              meals. <strong>Note</strong>: This benefit is prorated based on
              your hire date.
            </p>
            <p>
              {" "}
              <a
                href="  https://play.google.com/store/apps/details?id=agency.equilibrio.sodexomexico&hl=en_US&gl=US"
                target={"_blank"}
                rel="noreferrer"
              >
                Android Pluxee México App{" "}
              </a>{" "}
            </p>

            <p>
              <a
                href="https://apps.apple.com/si/app/pluxee/id1437173271"
                target={"_blank"}
                rel="noreferrer"
              >
                Apple Pluxee México App{" "}
              </a>{" "}
            </p>
          </ScrollPanel>
        </Dialog>

        <Dialog
          header="FedEx tracker details "
          visible={EquipmentTracker}
          style={{ width: "60vw" }}
          onHide={() => setEquipmentTracker(false)}
          footer={footerContentET}
        >
          {FedExDetailsData !== undefined && (
            <DataTable
              value={listFedExDetails}
              responsiveLayout="scroll"
              className="vertical"
            >
              <Column field="CorpShipDate" header="Date"></Column>
              <Column
                field="TrackingNumber"
                header="Tracking Number"
                body={getLink}
              ></Column>
              <Column
                field="FedExTrackingStatus"
                header="Tracking Status"
              ></Column>
              <Column
                field="FedExTrackingStatusModifiedDate"
                header="Delivered on"
              ></Column>
            </DataTable>
          )}
          {FedExDetailsData === undefined && <p>No record found</p>}
        </Dialog>
        <div className="row mt-5 pt-5">
          <div className="col-md-4">
            <img
              src="./images/louie_book_animation-opimized.gif"
              className="img-fluid"
              alt="Louie Book"
            />
          </div>
          <div className="col-md-8">
            <h2 className="mt-4 text-start">What makes TTEC tick?</h2>
            <p>
              Take a tour with our buddy Louie to learn more about the driving
              purpose, guiding values, and amazing people at the heart of TTEC.
            </p>
            <div className="d-grid gap-2 col-12 col-md-6">
              <a
                className="btn btn-primary p-3"
                type="button"
                style={{ backgroundColor: "#1A2749" }}
                href="https://online.flippingbook.com/view/108941/"
                target={"_blank"}
                rel="noreferrer"
              >
                Read all about it
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
