/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import './changePassword.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, InferType, ref } from "yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { nonSSOLogin, selectNonSSOLoginDetails } from './nonSSOLoginSlice';
import { chgpwd, selectchgpwdDetails } from './changePasswordSlice';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import CountDownTimer from './otpCountDownTimer';
import { genOTPandSaveUser, selectOTPandUserDetails } from './genOTPandSaveUserSlice';

const changePwdSchema = object({
    currentPassword: string()
        .required("Please enter the Password")
        .matches(
            /(?=.*?[A-Z])/,
            "Please enter atleast one Uppercase"
        )
        .matches(
            /(?=.*?[a-z])/,
            "Please enter atleast one Lowercase"
        )
        .matches(
            /(?=.*?[0-9])/,
            "Please enter atleast one digit"
        )
        .matches(
            /(?=.*?[#?!@$%^&*-])/,
            "Please enter atleast one Special Character"
        )
        .matches(
            /.{8,}/,
            "Please enter minumum 8 characters"
        ),
    newPassword: string()
        .required("Please enter the Password")
        .matches(
            /(?=.*?[A-Z])/,
            "Please enter atleast one Uppercase"
        )
        .matches(
            /(?=.*?[a-z])/,
            "Please enter atleast one Lowercase"
        )
        .matches(
            /(?=.*?[0-9])/,
            "Please enter atleast one digit"
        )
        .matches(
            /(?=.*?[#?!@$%^&*-])/,
            "Please enter atleast one Special Character"
        )
        .matches(
            /.{8,}/,
            "Please enter minumum 8 characters"
        ),
    confirmPassword: string()
        .required("Please enter the Password")
        .oneOf([ref("newPassword")], "Confirm Password does not match"),
});


const changePwdOTPSchema = object({
    otp: string()
        .required("Please enter the OTP")
        .min(6, "OTP minimum digit is 6")
        .max(6, "OTP maximum digit is 6"),
});

type changePwdProps = InferType<typeof changePwdSchema>;
type changePwdOTPProps = InferType<typeof changePwdOTPSchema>;

export function ChangePassword() {

    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const { nonSSOStatus, nonSSOState, nonSSOReqType } = useSelector(selectNonSSOLoginDetails);
    const [otp, setOtp] = useState('');
    const [pwdError, setPwdError] = useState('');
    const { chgpwdState, chgpwdStatus } = useSelector(selectchgpwdDetails);
    let decodedToken: any = null;
    const { otpstate, otpstatus, regrequestType } = useSelector(selectOTPandUserDetails);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { register: changePwdRegister, handleSubmit: changePwdHandle, formState: { errors: changePwdErrors }, reset: changePwdchange } =
        useForm<changePwdProps>({
            resolver: yupResolver(changePwdSchema),
        });

    const { register: changePwdOTPRegister, handleSubmit: changePwdOTPHandle, formState: { errors: changePwdOTPErrors }, reset: changePwdOTPchange } =
        useForm<changePwdOTPProps>({
            resolver: yupResolver(changePwdOTPSchema),
        });

    function handleChangePwdSubmit() {
        if(currentPassword === password)
        {
            setPwdError("Current Password and New password should be different");
            return;
        }
        else
        {
         setPwdError("");   
        }

        const token: any = localStorage.getItem("AccessToken-NonSSO");
        if (token) {
            decodedToken = jwt_decode(token);
        }
        const data = {
            "input": {
                "username": decodedToken?.upn,
                "password": currentPassword,
                "otp": "",
                "subAction": ""
            },
            "nonSSOReqType": {
                "type": "ChgPwd"
            }
        }
        dispatch<any>(nonSSOLogin(data));
    }

    function handleChangePwdOtp() {
        const token: any = localStorage.getItem("AccessToken-NonSSO");
        if (token) {
            decodedToken = jwt_decode(token);
        }
        const data = {
            "personalEmailAddress": decodedToken?.upn,
            "currentPassword": currentPassword,
            "NewPassword": password,
            "subAction": "validateOTPandSave",
            "otp": otp,
            "action":""
        }
        dispatch<any>(chgpwd(data));
    }

    function handleResendOTP() {
        const token: any = localStorage.getItem("AccessToken-NonSSO");
        if (token) {
            decodedToken = jwt_decode(token);
        }

        const data = {
            "input": {
              "request": {
                "action": "ValidateNewUser",
                "personalEmailAddress": decodedToken?.upn,
                "phoneNumber": "",
                "subAction": ""
              }
            },
            "requestType": {
              "type": "changePwd"
            }
          }
          dispatch<any>(genOTPandSaveUser(data));
    }

    function refreshPage() {
        window.location.reload();
      }   


    return (
        <div className='row mx-0 changePwdContainer mt-5'>
            <div className='col-md-8 mx-auto border shadow-lg'>
                <div className='row'>
                    <div className='col-lg-12 order-last order-xs-last order-sm-first order-md-first order-lg-first height-500 changeLeftColumn bg-white'>
                        <form className="Auth-form" onSubmit={changePwdHandle(handleChangePwdSubmit)} >
                            <div className="col-12">
                                <div className='row justify-content-center'>
                                    <div className='col-md-8'>
                                        {(nonSSOStatus !== "fulfilled" && nonSSOState !== "success" && nonSSOReqType === "") &&
                                            <div>
                                                <h1 className="h3 mb-3 fw-normal mt-5 mb-5 ps-2">Change Password</h1>
                                                {nonSSOState === 'failed' && <div className="alert otp-fail">Current Password does not match</div>}
                                                <div className="form-floating mb-3 mt-5">
                                                    <input type="password" className="form-control" id="floatingCurrentInput" placeholder="Enter Current Password"  {...changePwdRegister("currentPassword")} onKeyUp={(event) => setCurrentPassword(event.currentTarget.value)} />
                                                    <label htmlFor="floatingInput">ENTER CURRENT PASSWORD</label>
                                                </div>
                                                {changePwdErrors?.currentPassword && <p className="frmErrors mt-2">{changePwdErrors?.currentPassword?.message}</p>}
                                                <div className="form-floating mb-3 mt-2">
                                                    <input type="password" className="form-control" id="floatingPasswordInput" placeholder="Enter New Password"  {...changePwdRegister("newPassword")} onKeyUp={(event) => setPassword(event.currentTarget.value)}/>
                                                    <label htmlFor="floatingInput">ENTER NEW PASSWORD</label>
                                                </div>
                                                {(changePwdErrors?.newPassword || pwdError !== '') && <p className="frmErrors mt-2">{pwdError !== '' ? pwdError : changePwdErrors?.newPassword?.message}</p>}
                                                <div className="form-floating">
                                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Re-enter New Password" {...changePwdRegister("confirmPassword")} />
                                                    <label htmlFor="floatingPassword">RE-ENTER NEW PASSWORD</label>
                                                </div>
                                                <p className="frmErrors mt-2">{changePwdErrors?.confirmPassword && changePwdErrors?.confirmPassword?.message}</p>
                                                <button type="submit" className="w-100 fb-btn mt-3 mb-5 mobileSSO-btn">Send OTP</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form className="Auth-form" onSubmit={changePwdOTPHandle(handleChangePwdOtp)} >
                            <div className="col-12">
                                <div className='row justify-content-center'>
                                    <div className='col-md-8'>
                                        {(nonSSOStatus === "success" && nonSSOState === "fulfilled" && (nonSSOReqType === "login" || nonSSOReqType === "ChgPwd")) && (chgpwdState !== "success" && chgpwdState !== "fulfilled") &&
                                            <div>
                                                <h1 className="h3 mb-3 fw-normal mt-5 mb-5 ps-2">One Time Password (OTP)</h1>
                                                <div className="alert alert-success mt-3-">An One Time Password (OTP) has been sent to your mobile number and email address.</div>
                                                <div className="form-floating mb-3 mt-4">
                                                    <input type="number" className="form-control" id="floatingInput" placeholder="Enter ONE TIME PASSWORD(OTP)" {...changePwdOTPRegister("otp")} onKeyUp={event => setOtp(event.currentTarget.value)} onInput={(e) => e.currentTarget.value = e.currentTarget.value.slice(0, 6)} />
                                                    <label htmlFor="floatingPassword">ENTER ONE TIME PASSWORD(OTP)</label>
                                                </div>
                                                <p className="frmErrors mt-2 pb-2"> {(chgpwdState === 'failed' && 'Please enter correct OTP') || (changePwdOTPErrors?.otp && changePwdOTPErrors?.otp?.message)}</p>
                                                {otpstate !== 'pending' && <CountDownTimer hours={0} minutes={10} seconds={0o0} />}
                                                {otpstate !== 'pending' &&<div className="resend mt-0 pb-2">Didn't receive the code? &nbsp;<Link to="" onClick={handleResendOTP}>Resend Code</Link></div>}
                                                {otpstate === 'pending' && <div className="resending mt-0 pb-2">Resending Code... &nbsp; <div className="counter"></div></div>}
                                                <button type="submit" className="w-100 fb-btn mt-3 mb-5 mobileSSO-btn">Change Password</button>
                                            </div>
                                        }
                                        {chgpwdState === "fulfilled" && chgpwdStatus === "success" &&
                                            <div>
                                                <div className="alert alert-success">You password was changed successfully</div>
                                                <div className="mb-4 mt-5 text-center">
                                                 <Link to="" onClick={refreshPage}>Back to Profile</Link>
                                                 </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

