import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    chartData: ({
        labels: ['A', 'B', 'C'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]
            }
        ]
    }),

    lightOptions: ({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    })

}

export const NewHireStatSlice = createSlice({
    name: 'newHireChart',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        /* increment: (state: any) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.status = 'idle';
            state.value += 1;
        },
        decrement: (state: any) => {
            state.status = 'idle';
            state.value -= 1;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        incrementByAmount: (state: any, action: any) => {
            state.status = 'idle';
            state.value += action.payload;
        }, */
    },

});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCountChart = ((state : any) => state["newHireChart"].chartData);
export const selectCountChart2 = ((state : any) => state["newHireChart"].lightOptions);

export default NewHireStatSlice.reducer;