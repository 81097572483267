import { ProfileLanding } from "../ProfileLanding/ProfileLanding";
import Louie from '../louie/Louie';

export function Home() {

    return (
        <div>
            <ProfileLanding />
            <Louie/>
            {/* <div className="kore-chat-window droppable liteTheme-one minimize ui-draggable ui-resizable" style={{left: "1503px", width: "400px"}}>
                <div className="kr-wiz-menu-chat defaultTheme-kore">
                </div>
                <div className="minimized-title">Need help? Let's chat!</div>
                <div className="minimized"><span className="messages"></span></div>
            </div> */}

        </div>
    )
}
