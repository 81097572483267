import React, { useState, useEffect } from "react";
import './resetPassword.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, InferType, ref } from "yup";
import { useForm } from "react-hook-form";
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { genOTPandSavePwd, selectOTPandPwdDetails } from './genOTPandSavePwdSlice';
import { OTPVerification } from './otpVerification';
import { LOGIN } from "../../../../utils/CONSTANTS";
import { Button } from "primereact/button";

const resetPwdSchema = object({
  password: string()
    .required("Please enter the Password")
    .matches(
      /(?=.*?[A-Z])/,
      "Please enter atleast one Uppercase"
    )
    .matches(
      /(?=.*?[a-z])/,
      "Please enter atleast one Lowercase"
    )
    .matches(
      /(?=.*?[0-9])/,
      "Please enter atleast one digit"
    )
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      "Please enter atleast one Special Character"
    )
    .matches(
      /.{8,}/,
      "Please enter minumum 8 characters"
    ),
  confirmPassword: string()
    .required("Please enter the Password")
    .oneOf([ref("password")], "Confirm Password does not match"),
});

type resetPwdProps = InferType<typeof resetPwdSchema>;

export function ResetPassword() {
  const params = useParams();
  const dispatch = useDispatch();
  const { status, state, requestType } = useSelector(selectOTPandPwdDetails);
  const [password, setPassword] = useState('');
  const buffer = require('buffer/').Buffer;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { register: resetPwdRegister, handleSubmit: resetPwdHandle, formState: { errors: resetPwdErrors }, reset: resetPwdReset } =
    useForm<resetPwdProps>({
      resolver: yupResolver(resetPwdSchema),
    });

  function handleSubmit() {
    const data = {
      "input": {
        "request": {
          "action": "ValidateNewUser",
          "personalEmailAddress": buffer.from(params.email, 'base64').toString('ascii'),
          "phoneNumber": "",
          "subAction": ""
          }
      },
      "requestType": {
        "type": 'genOtp'
      }
    }
    dispatch<any>(genOTPandSavePwd(data));
  }

  useEffect(() => {
    const data = {
      "input": {
        "employeeUid": buffer.from(params.id, 'base64').toString('ascii'),
        "password": "",
        "otpNumber": "",
        "action": "ResetPassword",
        "subAction": "validate",
        "linkUid": params.token
      },
      "requestType": {
        "type": 'validateLink'
      }
    };
    dispatch<any>(genOTPandSavePwd(data));
  }, [buffer, dispatch, params.id, params.token]);

  return (
    <div id="divReset">
      {requestType === 'validateLink' && status !== '200' && state !== 'pending' && 
         <div className="margin-auto-container">
          <div className="margin-auto-inner"> <h3 className="divText">Link Expired or Invalid</h3>
            <p className="divText pb-5">for security reasons, password reset links expire after one hour. If you still need to reset your password, you can request a new reset link below.</p>
            <Link to={LOGIN} className="btnResetLink">Request a new reset link</Link></div>
        </div> 
       } 
       {(status === '200' && (state === 'fulfilled' || state === 'pending') && requestType === 'validateLink') && 
         <div className='row mx-0 resetPwdContainer mt-5'>
          <div className='col-md-8 mx-auto border shadow-lg'>
            <div className='row'>
              <div className='col-lg-9 order-last order-xs-last order-sm-first order-md-first order-lg-first height-700 resetLeftColumn bg-white'>

                <form className="Auth-form" /* onSubmit={resetPwdHandle(handleSubmit)} */ >
                  <div className="col-12">
                    <div className='row justify-content-center'>
                      <div className='col-md-8'>

                        <h1 className="h3 mb-3 fw-normal mt-5 mb-5 ps-2">Reset Password</h1>
                        <div className="form-floating mb-3 mt-2">
                          <input type="password" className="form-control" id="floatingInput" placeholder="Enter Password"  {...resetPwdRegister("password")} onChange={event => setPassword(event.target.value)} />
                          <label htmlFor="floatingInput">ENTER PASSWORD</label>
                        </div>
                        {resetPwdErrors?.password && <p className="frmErrors mt-2">{resetPwdErrors?.password?.message}</p>}
                        <div className="form-floating">
                          <input type="password" className="form-control" id="floatingPassword" placeholder="Re-enter Password" {...resetPwdRegister("confirmPassword")} />
                          <label htmlFor="floatingPassword">RE-ENTER PASSWORD</label>
                        </div>
                        <p className="frmErrors mt-2">{resetPwdErrors?.confirmPassword && resetPwdErrors?.confirmPassword?.message}</p>
                        {/* <button type="submit" disabled={state === 'pending' ? true : false} className="w-100 fb-btn mt-5 mobileSSO-btn btnSubmit btnSubmit-otp">Send OTP</button> */}
                        <Button onClick={resetPwdHandle(handleSubmit)} className="w-100 fb-btn mt-5 mobileSSO-btn btnSubmit btnSubmit-otp">Send OTP</Button>
                      </div>
                    </div>
                  </div>
            </form>
           </div> 
            {requestType === 'validateLink' && (state === 'fulfilled' || state === 'pending') && 
                     <div className='col-lg-3 order-first order-xs-first order-sm-last order-md-last order-lg-last height-700 immg-column pt-5 text-start ps-lg-5'>
                        <h2 className='text-start text-md-center'>Reset Password</h2>
                        <p className='text-md-center'>Please reset your password here.</p>
                    </div>
                     } 
                 </div>
            </div>
        </div> 
       } 
       {(((requestType === 'genOtp') && (state === 'fulfilled' || status === '200')) || (requestType === 'savePwd')) && 
        <OTPVerification details={{ pwd: password }} />
       } 
    </div>

  )
}

