import React from 'react';

import './footerSection.scss';

export default function FooterSection() {

    return (

        <div className='footer'>
            <div className="container-fluid px-5">
                <footer className="py-5">
                    <div className="row">
                        <div className="col-md-3">
                            <img src='./Logo_white.png' className='img-fluid mb-5' alt='' width={"auto"} height = {"auto"}/>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="https://www.ttec.com/" className="nav-link p-0" target={'_blank'} rel="noreferrer">TTEC.com</a></li>
                                <li className="nav-item mb-2"><a href="https://www.ttecjobs.com/en/privacy" className="nav-link p-0" target={'_blank'} rel="noreferrer">Privacy Policy</a></li>
                                <li className="nav-item mb-2"><a href="https://www.ttec.com/about-us/corporate-responsibility" className="nav-link p-0" target={'_blank'} rel="noreferrer">Corporate Responsibility</a></li>
                            </ul>
                        </div>

                        <div className="col-md-3">
                            <h5 className='mb-5'>Navigation</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="https://www.ttecjobs.com/en/who-we-are" className="nav-link p-0" target={'_blank'} rel="noreferrer">About Us</a></li>
                                <li className="nav-item mb-2"><a href="https://www.ttecjobs.com/" className="nav-link p-0" target={'_blank'} rel="noreferrer">Career Path</a></li>
                                <li className="nav-item mb-2"><a href="https://www.ttecjobs.com/en/contact-us" className="nav-link p-0" target={'_blank'} rel="noreferrer">Contact Us</a></li>
                                <li className="nav-item mb-2"><a href="https://www.ttecjobs.com/en/blog" className="nav-link p-0" target={'_blank'} rel="noreferrer">Blog</a></li>
                            </ul>
                        </div>

                        <div className="col-md-2">
                            <h5 className='mb-5'>Jobs</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="https://www.ttecjobs.com/en/search-jobs" className="nav-link p-0" target={'_blank'} rel="noreferrer">All Jobs</a></li>
                            </ul>
                        </div>
{/* 
                        <div className="col-md-4">
                            <form>
                                <h5 className='mb-5'>Contact With Us</h5>
                                <a className='communityBtn' href='https://www.jobs.net/jobs/ttec/en-us/' target={'_blank'} rel="noreferrer">Join our Talent<br />Community</a>
                            </form>
                        </div> */}
                    </div>

                    <div className="d-flex justify-content-between py-4 my-4 mb-0 pb-0 border-top text-center copyRightFooterText">
                        <p>TTEC is proud to be an <a className='copyLink' href='https://www.ttecjobs.com/en/contact-us#eeo' target={'_blank'} rel="noreferrer">equal opportunity employer</a>. TTEC embraces and is committed to building a diverse and inclusive workforce that respects and empowers the cultures and perspectives within our global teams. We strive to reflect the communities we serve by not only delivering amazing service and technology, but also humanity. We make it a point to make sure all our employees feel valued and comfortable being their authentic selves at work. As a global company, we know diversity is our strength. It enables us to view projects and ideas from different vantage points and allows every individual to bring value to the table in their own unique way.</p>
                    </div>

                    <div className="d-flex justify-content-between py-4 my-4 mb-0 pb-0 border-top copyRightFooterText">
                        <p className='text-center w-100'>© 2024 TTEC, All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </div>

    );
}