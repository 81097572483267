import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
 FedExDetailsState: '',
 FedExDetailsData: '',
 listFedExDetailsData: '',
};

export const FedExDetails = createAsyncThunk(
  "get/getFedExDetails",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const FedExDetailsSlice = createSlice({
  name: "FedExDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(FedExDetails.pending, (state) => {
        state.FedExDetailsState = "pending";
      })
      .addCase(FedExDetails.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.FedExDetailsState = "fulfilled";
        state.FedExDetailsData = tempRes?.response;
        state.listFedExDetailsData = tempRes?.response?.root;
      })
      .addCase(FedExDetails.rejected, (state) => {
        state.FedExDetailsState = "failed";
      })
});

export const getFedExDetails = (state: any) => state.FedExDetails;
export const getlistFedExDetails = (state: any) => state["FedExDetails"].listFedExDetailsData;


export default FedExDetailsSlice.reducer;
