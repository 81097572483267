import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../../utils/CONSTANTS";
import axios from "../../../../utils/axios";


const initialState = {
    eStatusStatus : '',
    eStatusState : '',

};

export const updateEmail = createAsyncThunk("post/updateEmail", async (data: any) => {
  const response = axios.postStringRequest(MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), data.input);
  return response;
});

export const updateEmailSlice = createSlice({
    name : 'updateEmail',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(updateEmail.pending, (state : any) =>
       {
            state.eStatusState = 'pending';

       })
       .addCase(updateEmail.fulfilled, (state : any,action : any) =>
       {
           let json = JSON.stringify(action?.payload?.data?.response);
           json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
           state.eStatusStatus = JSON.parse(json)?.response?.status;
           state.eStatusState = 'fulfilled';
       })
       .addCase(updateEmail.rejected, (state : any) =>
       {
            state.eStatusState = 'failed';
       })
});

export const selectupdateEmail = ((state : any) => state.updateEmail || {});

export default updateEmailSlice.reducer;
