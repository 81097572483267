import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  stagesListDetails,
  stagesList,
  countListDetails,
  stagesListDetailsData,
} from "./StageSlice";
import {
  faClock,
  faLock,
  faTimesCircle,
  faCheck,
  faBarsProgress,
} from "@fortawesome/free-solid-svg-icons";
import { Tasks } from "./Tasks";
import jwt_decode from "jwt-decode";
import { empProfileDetails } from "../Profile/ProfileSlice";

export function TaskSection() {
  const [clickedStageIdValue, setClickedStageIdValue] = useState(0);

  const stagesListDetailsValue = useSelector(stagesListDetailsData);
  const { profileData } = useSelector(empProfileDetails);

  const [oracleId, setOracleId] = useState("0");

  const dispatch = useDispatch();

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);
  let countryName = profileData?.automation?.employee?.location_country;

  useEffect(() => {
    if (
      oracleId !== null &&
      oracleId !== undefined &&
      oracleId !== "0" &&
      countryName !== null &&
      countryName !== undefined &&
      countryName !== ""
    ) {
      const requestdata = {
        request: {
          action: "getStages",
          /*oracleId: "7504147",*/
          oracleId: oracleId,
          country: countryName,
          processName: "Onboarding Reimagine",
        },
      };
      //console.log(countryName);
      dispatch<any>(stagesList(requestdata));
    }
  }, [dispatch, oracleId, countryName]);

  const stagesData = useSelector(stagesListDetails);

  let stagesListEle = [];

  if (
    stagesListDetailsValue === "fulfilled" &&
    stagesData !== undefined &&
    countryName !== null &&
    countryName !== undefined &&
    countryName !== ""
  ) {if(stagesData.status === "Completed" || stagesData.status === "Overdue" || stagesData.status === "In-Progress" || stagesData.status === "Not-Started"  )
  {
    let stagesListEleements = (
      <Fragment key={0}>
        <button
          className={
            stagesData.status === "Completed"
              ? "btn btn-success fs-5 mb-3 p-4"
              : stagesData.status === "Overdue"
              ? "btn btn-danger fs-5 mb-3 p-4"
              : stagesData.status === "In-Progress"
              ? "btn btn-warning fs-5 mb-3 p-4"
              : stagesData.status === "Not-Started"
              ? "btn btn-secondary fs-5 mb-3 p-4"
              : "btn btn-light fs-5 mb-3 p-4"
          }
          type="button"
          value={
            stagesData
              ? stagesData.automation_process_employee_uid
              : 0
          }
          onClick={(e: any) => setClickedStageIdValue(e.target.value)}
        >
          {" "}
          {stagesData.stage} <br />
          <FontAwesomeIcon
            icon={
              stagesData.status === "Completed"
                ? faCheck
                : stagesData.status === "In-Progress"
                ? faBarsProgress
                : stagesData.status === "Not-Started"
                ? faLock
                : stagesData.status === "Overdue"
                ? faTimesCircle
                : faClock
            }
            className="me-3"
            style={{ fontSize: "1.2rem" }}
          />
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {stagesData.status}{" "}
          </span>
        </button>
      </Fragment>
    );

    stagesListEle.push(stagesListEleements);
  }else{
    for (let element = 0; element < stagesData.length; element++) {
      let stagesListEleements = (
        <Fragment key={element}>
          <button
            className={
              stagesData[element].status === "Completed"
                ? "btn btn-success fs-5 mb-3 p-4"
                : stagesData[element].status === "Overdue"
                ? "btn btn-danger fs-5 mb-3 p-4"
                : stagesData[element].status === "In-Progress"
                ? "btn btn-warning fs-5 mb-3 p-4"
                : stagesData[element].status === "Not-Started"
                ? "btn btn-secondary fs-5 mb-3 p-4"
                : "btn btn-light fs-5 mb-3 p-4"
            }
            type="button"
            value={
              stagesData[element]
                ? stagesData[element].automation_process_employee_uid
                : 0
            }
            onClick={(e: any) => setClickedStageIdValue(e.target.value)}
          >
            {" "}
            {stagesData[element].stage} <br />
            <FontAwesomeIcon
              icon={
                stagesData[element].status === "Completed"
                  ? faCheck
                  : stagesData[element].status === "In-Progress"
                  ? faBarsProgress
                  : stagesData[element].status === "Not-Started"
                  ? faLock
                  : stagesData[element].status === "Overdue"
                  ? faTimesCircle
                  : faClock
              }
              className="me-3"
              style={{ fontSize: "1.2rem" }}
            />
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {stagesData[element].status}{" "}
            </span>
          </button>
        </Fragment>
      );

      stagesListEle.push(stagesListEleements);
    }}
  }

  const countData = useSelector(countListDetails);

  return (
    <>
      {stagesData && (
        <div className="col-md-12">
          <h2 className="text-start mb-4 mt-md-3 ps-4">My Tasks</h2>

          <div className="card border-0">
            <div className="card-body shadow-lg">
              <div className="row">
                <div className="col-md-3 order-last order-lg-first">
                  <div className="card h-100 border-0">
                    <h3 className="text-start mt-3 position-absolute bottom-0 start-0 ms-3">
                      Stages
                    </h3>
                  </div>
                </div>
                <div className="col-md-9 order-first order-lg-second mb-5 mb-md-0">
                  <div className="row row-cols-1 row-cols-md-4 g-4">
                    <div className="col">
                      <div className="card h-100 text-center border rounded-0">
                        <div className="card-body">
                          <h5 className="card-title fs-1">
                            {countData?.inprogress}
                          </h5>
                          <p className="card-text mt-3 fw-bold">In Progress</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card h-100 text-center border rounded-0">
                        <div className="card-body">
                          <h5 className="card-title fs-1">
                            {countData?.notstarted}
                          </h5>
                          <p className="card-text mt-3 fw-bold">Not Started</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card h-100 text-center border rounded-0">
                        <div className="card-body">
                          <h5 className="card-title fs-1">
                            {countData?.overdue}
                          </h5>
                          <p className="card-text mt-3 fw-bold">Overdue</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card h-100 text-center border rounded-0">
                        <div className="card-body">
                          <h5 className="card-title fs-1">
                            {countData?.completed}
                          </h5>
                          <p className="card-text mt-3 fw-bold">Completed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clearfix">&nbsp;</div>

              <div className="row">
                <div className="col-md-3">
                  <div className="d-grid gap-2">{stagesListEle}</div>
                </div>
                <Tasks clickedStageIdValue={clickedStageIdValue}></Tasks>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
