import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../../utils/CONSTANTS";
import axios from "../../../../utils/axios";


const initialState = {
    status : '',
    state : '',
    inboxData : ''
};

export const replyEmail = createAsyncThunk("post/replyEmail", async (data: any) => {
  const response = axios.postStringRequest(MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), data.input);
  return response;
});

export const replyEmailSlice = createSlice({
    name : 'replyEmail',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(replyEmail.pending, (state : any) =>
       {
            state.state = 'pending';

       })
       .addCase(replyEmail.fulfilled, (state : any,action : any) =>
       {
           let json = JSON.stringify(action?.payload?.data?.response);
           json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
           state.status = JSON.parse(json)?.response?.email_status;
           state.state = 'fulfilled';
       })
       .addCase(replyEmail.rejected, (state : any) =>
       {
            state.state = 'failed';
       })
});

export const selectReplyEmail = ((state : any) => state.replyEmail || {});

export default replyEmailSlice.reducer;
