import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
  getNhtpState: "",
  getNhtpData: "",
};

export const Nhtp: any = createAsyncThunk(
  "get/getNhtpLink",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const NhtpSlice = createSlice({
  name: "Nhtp",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(Nhtp.pending, (state) => {
        state.getNhtpState = "pending";
      })
      .addCase(Nhtp.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        const parse = require('html-react-parser');
        
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.getNhtpState = "fulfilled";
        const displayData = parse(tempRes?.response);
        state.getNhtpData = displayData;
        // state.getNhtpLinkData = tempRes?.response
      })
      .addCase(Nhtp.rejected, (state) => {
        state.getNhtpState = "failed";
      }),
});

export const getNhtpLinkDetails = (state: any) => state.Nhtp;

export default NhtpSlice.reducer;
