import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {showLoader, hideLoader} from '../../../utils/msal-utils';
import './initialLoadPage.scss';
import { MYMESSAGES, HOME } from "../../../utils/CONSTANTS";

export function InitialLoadPage() {

    const token: any = React.useRef(localStorage.getItem("AccessToken") || localStorage.getItem("AccessToken-NonSSO") || '');
    const navigate = useNavigate();

    useEffect(() => {
        showLoader();
    });

    useEffect(() => {
        if (token.current !== "") {
            if (localStorage.getItem("UserRole") === "OG") {              
                navigate(MYMESSAGES);
            }
            else {
                navigate(HOME);
            }
            hideLoader();
        }
        // else{
        //     navigate(LOGIN);
        //     hideLoader();
        // }
    }, [token,navigate])

    return (
    <div className = "clsLoader" >
        Loading data...Please wait...
    </div>
    )
}

