import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import App from "./App";
import "./app/scss/font.scss";
import { msalConfig } from "./app/components/Auth/mslconfig/MslConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { PersistGate } from "redux-persist/integration/react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./app/scss/index.scss";
import "./app/scss/flag.scss";

import "bootstrap/dist/js/bootstrap.bundle.min";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

/* import Footer from './app/components/shared-components/footer'; */

const msalInstance = new PublicClientApplication(msalConfig);

const container: any = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store as any}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <App />
          {/* <Footer /> */}
        </MsalProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
