import React, { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "primereact/button";
import {
  handleLoginRedirect,
  acquireTokenSilent,
} from "../../../../utils/msal-utils";
import "./login.scss";
import HeaderSection from "../../shared-components/headerSection";
import FooterSection from "../../shared-components/footerSection";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, InferType, ref } from "yup";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendMail, selectDetails } from "./forgotPasswordSlice";
import { nonSSOLogin, selectNonSSOLoginDetails } from "./nonSSOLoginSlice";
import { OTPVerification } from "./otpVerification";
import {
  genOTPandSaveUser,
  selectOTPandUserDetails,
} from "./genOTPandSaveUserSlice";
import Spinner from "../../../../utils/spinner";

function handleSSOLogin(instance: any) {
  handleLoginRedirect(instance);
}

const signInSchema = object({
  signinEmail: string()
    .email("Enter a valid Email address")
    .required("Please enter the Email"),
  password: string()
    .required("Please enter the Password")
    .matches(/(?=.*?[A-Z])/, "Please enter atleast one Uppercase")
    .matches(/(?=.*?[a-z])/, "Please enter atleast one Lowercase")
    .matches(/(?=.*?[0-9])/, "Please enter atleast one digit")
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      "Please enter atleast one Special Character"
    )
    .matches(/.{8,}/, "Please enter minumum 8 characters"),
});

const forgotPwdSchema = object({
  forgotPwdEmail: string()
    .email("Enter a valid Email address")
    .required("Please enter the Email"),
});

const signUpSchema = object({
  signUpEmail: string()
    .email("Enter a valid Email address")
    .required("Please enter the Email"),
  verifyemail: string()
    .email("Enter a valid Email address")
    .required("Please re-enter the email to verify")
    .oneOf([ref("signUpEmail")], "Email does not match"),
  signUpPassword: string()
    .required("Please enter password")
    .matches(/(?=.*?[A-Z])/, "Please enter atleast one Uppercase")
    .matches(/(?=.*?[a-z])/, "Please enter atleast one Lowercase")
    .matches(/(?=.*?[0-9])/, "Please enter atleast one digit")
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      "Please enter atleast one Special Character"
    )
    .matches(/.{8,}/, "Please enter minumum 8 characters"),
  verifypassword: string()
    .required("Please re-enter password to verify")
    .oneOf([ref("signUpPassword")], "Password does not match"),
  mobileNumber: string()
    .required("Please enter the Mobile Number")
    .typeError("Mobile Number must be a number")
    .min(10, "Please provide 10 digit number"),
  otpCode: string()
    // .required("Please enter the OTP sent to your phone number.")
    .max(6, "The maximum length is 6")
    .min(6, "The minimum length is 6"),
  countryCode: string().required("Please select the country code"),
});

type signInProps = InferType<typeof signInSchema>;
type signUpProps = InferType<typeof signUpSchema>;
type forgotPwdProps = InferType<typeof forgotPwdSchema>;
type signInMobileProps = InferType<typeof signInSchema>;
type signUpMobileProps = InferType<typeof signUpSchema>;
type forgotPwdMobileProps = InferType<typeof forgotPwdSchema>;

const Login = (props: any) => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const { status, state } = useSelector(selectDetails);
  const [isVisibleSignUp, setIsVisibleSignUp] = useState(false);
  const [isVisibleLogIn, setIsVisibleLogIn] = useState(true);
  const [isForgotPwd, setisForgotPwd] = useState(false);
  const [fpMail, setfpMail] = useState("");
  const [mail, setLoginMail] = useState("");
  const [password, setLoginPassword] = useState("");
  const [mailStatus, setmailStatus] = useState(status);
  const { nonSSOStatus, nonSSOState, nonSSOReqType, nonSSOMsg } = useSelector(
    selectNonSSOLoginDetails
  );
  const { emp_uid, otpstate, otpstatus, regrequestType } = useSelector(
    selectOTPandUserDetails
  );
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userCountryCode, setUserCountryCode] = useState("");
  const [userPersonalEmail, setUserPersonalEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userEmp_uid, setUserEmpUid] = useState(emp_uid);
  const [emailStatus, setemailStatus] = useState(otpstatus);
  const [isFirstFPCall, setIsFirstFPCall] = useState(false);
  const [isFirstLoginCall, setIsFirstLoginCall] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [path] = useState(process.env.REACT_APP_RESET_PASSWORD_URL);
  const { state: locationState } = useLocation();
  const [redirectSSO, setRedirectSSO] = useState(false);
  const {
    register: signInRegister,
    handleSubmit: signInHandle,
    formState: { errors: signInErrors },
    reset: signInReset,
  } = useForm<signInProps>({
    resolver: yupResolver(signInSchema),
  });
  const {
    register: signInMobileRegister,
    handleSubmit: signInMobileHandle,
    formState: { errors: signInMobileErrors },
    reset: signInMobileReset,
  } = useForm<signInMobileProps>({
    resolver: yupResolver(signInSchema),
  });
  const {
    register: signUpRegister,
    handleSubmit: signUpHandle,
    formState: { errors: signUpErrors },
    reset: signUpReset,
  } = useForm<signUpProps>({
    resolver: yupResolver(signUpSchema),
  });
  const {
    register: signUpMobileRegister,
    handleSubmit: signUpMobileHandle,
    formState: { errors: signUpMobileErrors },
    reset: signUpMobileReset,
  } = useForm<signUpMobileProps>({
    resolver: yupResolver(signUpSchema),
  });
  const {
    register: forgotPwdRegister,
    handleSubmit: forgotPwdHandle,
    formState: { errors: forgotPwdErrors },
    reset: forgotPwdReset,
  } = useForm<forgotPwdProps>({
    resolver: yupResolver(forgotPwdSchema),
  });
  const {
    register: forgotPwdMobileRegister,
    handleSubmit: forgotPwdMobileHandle,
    formState: { errors: forgotPwdMobileErrors },
    reset: forgotPwdMobileReset,
  } = useForm<forgotPwdMobileProps>({
    resolver: yupResolver(forgotPwdSchema),
  });
  function handleForgotPassword() {
    setIsVisibleLogIn(true);
    setisForgotPwd(true);
    signInReset();
    signInMobileReset();
    forgotPwdReset();
    forgotPwdMobileReset();
    setfpMail("");
    setLoginMail("");
    setLoginPassword("");
    setmailStatus("");
    setIsFirstFPCall(true);
    setIsFirstLoginCall(false);
  }

  function handleBackSignIn() {
    setIsVisibleLogIn(true);
    setisForgotPwd(false);
    signInReset();
    signInMobileReset();
    forgotPwdReset();
    forgotPwdMobileReset();
    setmailStatus("");
    setfpMail("");
    setIsFirstFPCall(false);
    setIsFirstLoginCall(true);
  }

  function handleForgetPassword() {
    setIsFirstFPCall(false);

    let data = {
      request: {
        action: "ForgotPassword",
        personalEmailAddress: fpMail,
        path: path,
      },
    };

    if (fpMail !== "") {
      dispatch<any>(sendMail(data));
    }
  }

  const handleSignUp = () => {
    const data = {
      input: {
        request: {
          action: "ValidateNewUser",
          personalEmailAddress: userPersonalEmail,
          phoneNumber: userCountryCode + userPhoneNumber,
          subAction: "SignUp",
        },
      },
      requestType: {
        type: "genOtp",
      },
    };
    if (
      userPersonalEmail !== "" &&
      userPhoneNumber !== "" &&
      emailStatus !== "200"
    ) {
      dispatch<any>(genOTPandSaveUser(data));
    }
    setemailStatus(otpstatus);
  };

  useEffect(() => {
    if (accounts.length > 0) {
      acquireTokenSilent(instance, accounts, false);
    }
  });

  useEffect(() => {
    setmailStatus(status);
  }, [status]);

  useEffect(() => {
    setemailStatus(otpstatus);
  }, [otpstatus]);

  useEffect(() => {
    setUserEmpUid(emp_uid);
  }, [emp_uid]);

  useEffect(() => {
    if (locationState && instance) {
      handleSSOLogin(instance);
      setRedirectSSO(true);
    }
  }, [instance, locationState, accounts]);

  const navRef = useRef<HTMLHeadingElement>(null);

  const onToggleClick = () => {
    document.getElementsByClassName("cont")[0].classList.toggle("s--signup");
    signInReset();
    signInMobileReset();
    signUpReset();
    signUpMobileReset();
    forgotPwdReset();
    forgotPwdMobileReset();
    setemailStatus(null);
    setIsLogin(!isLogin);
    setIsFirstLoginCall(true);
  };

  const showSignUp = () => {
    signInMobileReset();
    setIsVisibleSignUp(true);
    setIsVisibleLogIn(false);
    setIsLogin(false);
    setIsFirstLoginCall(true);
  };

  const showLogIn = () => {
    signUpMobileReset();
    setIsVisibleSignUp(false);
    setIsVisibleLogIn(true);
    setIsLogin(true);
  };

  const handleNonSSOLogin = async () => {
    setIsFirstLoginCall(false);
    const data = {
      input: {
        username: mail,
        password: password,
        otp: "",
        subAction: "",
      },
      nonSSOReqType: {
        type: "login",
      },
    };
    dispatch<any>(nonSSOLogin(data));
  };

  useEffect(() => {
    if (props.isForgotPwd) {
      setisForgotPwd(props.isForgotPwd);
    }
  }, [props.isForgotPwd]);

  return (
    <>
      {redirectSSO === true && <Spinner />}
      <div id="loginMobile">
        {((isLogin &&
          nonSSOReqType === "" &&
          nonSSOState !== "fulfilled" &&
          nonSSOStatus !== "success") ||
          (!isLogin && otpstatus !== "200")) && (
          <main className="form-signin pt-5">
            <div className="container">
              <div className="text-center">
                <img
                  src="./ttecLogo-black.png"
                  className="img-fluid"
                  alt=""
                  width={"auto"}
                />
              </div>
              {isVisibleLogIn && (
                <div className="row" id="signInContainer">
                  {!isForgotPwd && (
                    <form
                      className="Auth-form"
                      onSubmit={signInMobileHandle(handleNonSSOLogin)}
                    >
                      <div className="col-12">
                        <h1 className="h3 mb-3 fw-normal mt-5 mb-4">
                          Welcome Back!
                        </h1>
                        <p className="mt-4 ms-3"
                          style={{ color: "red"}}>
                          <strong>Login Note : </strong>
                        </p>
                        <ul>
                          <li>
                            <strong>Before joining TTEC: </strong> Please use
                            the <strong> SIGN IN</strong> button. Use the same
                            personal email address you provided to TTEC during
                            your employment application.
                          </li>
                          <li>
                            <strong>After joining TTEC:</strong> Please use
                            <strong> Connect with SSO</strong> button. Use your
                            work email address and login with SSO option.
                          </li>
                          <li>
                            <strong>For Internal transfers:</strong> If you are
                            an internal transfer, you do not need to Sign Up.
                            Please login with your TTEC SSO credentials and use
                            Connect with SSO button.
                          </li>
                        </ul>

                        {!isFirstLoginCall &&
                          nonSSOStatus !== "success" &&
                          nonSSOState === "failed" && (
                            <div className="alert otp-fail">{nonSSOMsg}</div>
                          )}
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            {...signInMobileRegister("signinEmail")}
                            onKeyUp={(event) =>
                              setLoginMail(event.currentTarget.value)
                            }
                          />
                          <label htmlFor="floatingInput">Email address</label>
                          {signInMobileErrors?.signinEmail && (
                            <p className="frmErrors mt-2">
                              {signInMobileErrors?.signinEmail?.message}
                            </p>
                          )}
                        </div>
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            {...signInMobileRegister("password")}
                            onKeyUp={(event) =>
                              setLoginPassword(event.currentTarget.value)
                            }
                          />
                          <label htmlFor="floatingPassword">Password</label>
                          {signInMobileErrors?.password && (
                            <p className="frmErrors mt-2">
                              {signInMobileErrors?.password?.message}
                            </p>
                          )}
                        </div>

                        <div className="mb-4 mt-4 text-center">
                          <Link to="" onClick={handleForgotPassword}>
                            Forgot Password?
                          </Link>
                        </div>

                        <button
                          disabled={nonSSOState === "pending" ? true : false}
                          className="w-100 btn btn-lg btn-primary mobileSubmit"
                          type="submit"
                        >
                          <strong>SIGN IN</strong>
                        </button>
                        <button
                          type="button"
                          className="w-100 fb-btn mt-3 mobileSSO-btn"
                          onClick={() => handleSSOLogin(instance)}
                        >
                          <strong>
                            Connect with <span>SSO</span>
                          </strong>
                        </button>
                        <h4 className="pt-3">New Here?</h4>
                        <button
                          disabled={otpstate === "pending" ? true : false}
                          type="submit"
                          className="w-100 submit mobileSignUp mt-0"
                          onClick={showSignUp}
                        >
                          <strong>SIGN UP</strong>
                        </button>
                        <p className="mt-5 mb-3 text-muted text-center">
                          © 2024 TTEC, All rights reserved.
                        </p>
                      </div>
                    </form>
                  )}
                  {isForgotPwd && (
                    <form
                      className="Auth-form"
                      /* onSubmit={forgotPwdMobileHandle(handleForgetPassword)} */
                    >
                      <div id="divforgotPassword col-12">
                        <h1 className="h3 mb-3 fw-normal mt-5 mb-4">
                          Forgot Password?
                        </h1>
                        {!isFirstFPCall &&
                          mailStatus !== "200" &&
                          fpMail !== "" &&
                          state === "fulfilled" && (
                            <div className="alert otp-fail">
                              Please enter the valid email
                            </div>
                          )}
                        {mailStatus !== "200" && (
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder="name@example.com"
                              {...forgotPwdMobileRegister("forgotPwdEmail")}
                              onChange={(event) =>
                                setfpMail(event.target.value)
                              }
                            />
                            <label htmlFor="floatingInput">Email</label>
                          </div>
                        )}
                        <p className="frmErrors pb-1 mt-2">
                          {forgotPwdMobileErrors?.forgotPwdEmail &&
                            forgotPwdMobileErrors?.forgotPwdEmail?.message}
                        </p>
                        {mailStatus === "200" && (
                          <div className="alert alert-success">
                            Your password reset email has been sent. If you
                            don't receive it please wait few minutes and try
                            again.
                          </div>
                        )}
                        <div className="mb-4 text-center pb-5">
                          <Link to="" onClick={handleBackSignIn}>
                            Back to Sign In
                          </Link>
                        </div>
                        {mailStatus !== "200" && (
                          <Button
                            className="w-100 btn btn-lg btn-primary mobileSubmit"
                            onClick={forgotPwdMobileHandle(
                              handleForgetPassword
                            )}
                          >
                            Send Email
                          </Button>
                        )}
                      </div>
                    </form>
                  )}
                </div>
              )}

              <br />

              {isVisibleSignUp && emailStatus !== "200" && (
                <div className="row" id="signUpContainer">
                  <form
                    className="Auth-form-sub"
                    onSubmit={signUpMobileHandle(handleSignUp)}
                  >
                    <div className="col-12">
                      {/* <form> */}
                      <h5 className="mb-3 mt-4 mb-4">
                        Please use the same personal email address you provided
                        to TTEC during your employment application.
                      </h5>
                      {emailStatus === "302" && otpstate !== "pending" && (
                        <div className="alert otp-fail mb-3 mt-0">
                          Invalid or already registered email.
                        </div>
                      )}
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          {...signUpMobileRegister("signUpEmail")}
                          onChange={(event) =>
                            setUserPersonalEmail(event.target.value)
                          }
                        />
                        <label htmlFor="floatingInput">
                          Email address <span className="errorText">*</span>
                        </label>
                        <p className="frmErrors mt-2">
                          {(emailStatus === "400" &&
                            otpstate !== "pending" &&
                            "Invalid or already registered email.") ||
                            (signUpMobileErrors?.signUpEmail &&
                              signUpMobileErrors?.signUpEmail?.message)}
                        </p>
                      </div>
                      {emailStatus !== "200" && (
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput2"
                            placeholder="name@example.com"
                            {...signUpMobileRegister("verifyemail")}
                          />
                          <label htmlFor="floatingInput2">
                            Verify email address{" "}
                            <span className="errorText">*</span>
                          </label>
                        </div>
                      )}
                      {signUpMobileErrors?.verifyemail && (
                        <p className="frmErrors mt-2">
                          {signUpMobileErrors?.verifyemail?.message}
                        </p>
                      )}
                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          {...signUpMobileRegister("signUpPassword")}
                          onChange={(event) =>
                            setUserPassword(event.target.value)
                          }
                        />
                        <label htmlFor="floatingPassword">
                          Password <span className="errorText">*</span>
                        </label>
                        {signUpMobileErrors?.signUpPassword && (
                          <p className="frmErrors mt-2">
                            {signUpMobileErrors?.signUpPassword?.message}
                          </p>
                        )}
                      </div>
                      <div className="form-floating mb-4">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword2"
                          placeholder="Password"
                          {...signUpMobileRegister("verifypassword")}
                        />
                        <label htmlFor="floatingPassword2">
                          Verify Password <span className="errorText">*</span>
                        </label>
                        {signUpMobileErrors?.verifypassword && (
                          <p className="frmErrors mt-2">
                            {signUpMobileErrors?.verifypassword?.message}
                          </p>
                        )}
                      </div>

                      <span className="d-block mt-4 mb-4 mobileHeader">
                        Mobile Phone <span className="errorText">*</span>
                      </span>
                      <div className="row">
                        <div className="form-floating col-md-12 mb-3">
                          <select
                            className="form-select"
                            {...signUpMobileRegister("countryCode")}
                            onChange={(event) =>
                              setUserCountryCode(event.target.value)
                            }
                          >
                            <option value=""></option>
                            <option value="+376">AD (+376)</option>
                            <option value="+971">AE (+971)</option>
                            <option value="+1268">AG (+1268)</option>
                            <option value="+1264">AI (+1264)</option>
                            <option value="+374">AM (+374)</option>
                            <option value="+244">AO (+244)</option>
                            <option value="+54">AR (+54)</option>
                            <option value="+43">AT (+43)</option>
                            <option value="+61">AU (+61)</option>
                            <option value="+297">AW (+297)</option>
                            <option value="+994">AZ (+994)</option>
                            <option value="+387">BA (+387)</option>
                            <option value="+1246">BB (+1246)</option>
                            <option value="+880">BD (+880)</option>
                            <option value="+32">BE (+32)</option>
                            <option value="+226">BF (+226)</option>
                            <option value="+359">BG (+359)</option>
                            <option value="+973">BH (+973)</option>
                            <option value="+257">BI (+257)</option>
                            <option value="+229">BJ (+229)</option>
                            <option value="+1441">BM (+1441)</option>
                            <option value="+673">BN (+673)</option>
                            <option value="+591">BO (+591)</option>
                            <option value="+55">BR (+55)</option>
                            <option value="+1242">BS (+1242)</option>
                            <option value="+975">BT (+975)</option>
                            <option value="+267">BW (+267)</option>
                            <option value="+375">BY (+375)</option>
                            <option value="+501">BZ (+501)</option>
                            <option value="+1">CA (+1)</option>
                            <option value="+236">CF (+236)</option>
                            <option value="+242">CG (+242)</option>
                            <option value="+41">CH (+41)</option>
                            <option value="+682">CK (+682)</option>
                            <option value="+56">CL (+56)</option>
                            <option value="+237">CM (+237)</option>
                            <option value="+86">CN (+86)</option>
                            <option value="+57">CO (+57)</option>
                            <option value="+506">CR (+506)</option>
                            <option value="+381">CS (+381)</option>
                            <option value="+53">CU (+53)</option>
                            <option value="+238">CV (+238)</option>
                            <option value="+357">CY (+357)</option>
                            <option value="+9039">CY (+9039)</option>
                            <option value="+42">CZ (+42)</option>
                            <option value="+49">DE (+49)</option>
                            <option value="+253">DJ (+253)</option>
                            <option value="+45">DK (+45)</option>
                            <option value="+1809">DM (+1809)</option>
                            <option value="+1809">DO (+1809)</option>
                            <option value="+213">DZ (+213)</option>
                            <option value="+593">EC (+593)</option>
                            <option value="+372">EE (+372)</option>
                            <option value="+20">EG (+20)</option>
                            <option value="+291">ER (+291)</option>
                            <option value="+34">ES (+34)</option>
                            <option value="+251">ET (+251)</option>
                            <option value="+358">FI (+358)</option>
                            <option value="+679">FJ (+679)</option>
                            <option value="+500">FK (+500)</option>
                            <option value="+691">FM (+691)</option>
                            <option value="+298">FO (+298)</option>
                            <option value="+33">FR (+33)</option>
                            <option value="+241">GA (+241)</option>
                            <option value="+44">GB (+44)</option>
                            <option value="+1473">GD (+1473)</option>
                            <option value="+7880">GE (+7880)</option>
                            <option value="+594">GF (+594)</option>
                            <option value="+233">GH (+233)</option>
                            <option value="+350">GI (+350)</option>
                            <option value="+299">GL (+299)</option>
                            <option value="+220">GM (+220)</option>
                            <option value="+224">GN (+224)</option>
                            <option value="+590">GP (+590)</option>
                            <option value="+240">GQ (+240)</option>
                            <option value="+30">GR (+30)</option>
                            <option value="+502">GT (+502)</option>
                            <option value="+671">GU (+671)</option>
                            <option value="+245">GW (+245)</option>
                            <option value="+592">GY (+592)</option>
                            <option value="+852">HK (+852)</option>
                            <option value="+504">HN (+504)</option>
                            <option value="+385">HR (+385)</option>
                            <option value="+509">HT (+509)</option>
                            <option value="+36">HU (+36)</option>
                            <option value="+62">ID (+62)</option>
                            <option value="+353">IE (+353)</option>
                            <option value="+972">IL (+972)</option>
                            <option value="+91">IN (+91)</option>
                            <option value="+964">IQ (+964)</option>
                            <option value="+98">IR (+98)</option>
                            <option value="+354">IS (+354)</option>
                            <option value="+39">IT (+39)</option>
                            <option value="+1876">JM (+1876)</option>
                            <option value="+962">JO (+962)</option>
                            <option value="+81">JP (+81)</option>
                            <option value="+254">KE (+254)</option>
                            <option value="+996">KG (+996)</option>
                            <option value="+855">KH (+855)</option>
                            <option value="+686">KI (+686)</option>
                            <option value="+269">KM (+269)</option>
                            <option value="+1869">KN (+1869)</option>
                            <option value="+850">KP (+850)</option>
                            <option value="+82">KR (+82)</option>
                            <option value="+965">KW (+965)</option>
                            <option value="+1345">KY (+1345)</option>
                            <option value="+7">KZ (+7)</option>
                            <option value="+856">LA (+856)</option>
                            <option value="+961">LB (+961)</option>
                            <option value="+417">LI (+417)</option>
                            <option value="+94">LK (+94)</option>
                            <option value="+231">LR (+231)</option>
                            <option value="+266">LS (+266)</option>
                            <option value="+370">LT (+370)</option>
                            <option value="+352">LU (+352)</option>
                            <option value="+371">LV (+371)</option>
                            <option value="+218">LY (+218)</option>
                            <option value="+212">MA (+212)</option>
                            <option value="+377">MC (+377)</option>
                            <option value="+373">MD (+373)</option>
                            <option value="+261">MG (+261)</option>
                            <option value="+692">MH (+692)</option>
                            <option value="+389">MK (+389)</option>
                            <option value="+223">ML (+223)</option>
                            <option value="+95">MN (+95)</option>
                            <option value="+976">MN (+976)</option>
                            <option value="+853">MO (+853)</option>
                            <option value="+596">MQ (+596)</option>
                            <option value="+222">MR (+222)</option>
                            <option value="+1664">MS (+1664)</option>
                            <option value="+356">MT (+356)</option>
                            <option value="+960">MV (+960)</option>
                            <option value="+265">MW (+265)</option>
                            <option value="+52">MX (+52)</option>
                            <option value="+60">MY (+60)</option>
                            <option value="+258">MZ (+258)</option>
                            <option value="+264">NA (+264)</option>
                            <option value="+687">NC (+687)</option>
                            <option value="+227">NE (+227)</option>
                            <option value="+672">NF (+672)</option>
                            <option value="+234">NG (+234)</option>
                            <option value="+505">NI (+505)</option>
                            <option value="+31">NL (+31)</option>
                            <option value="+47">NO (+47)</option>
                            <option value="+670">NP (+670)</option>
                            <option value="+977">NP (+977)</option>
                            <option value="+674">NR (+674)</option>
                            <option value="+683">NU (+683)</option>
                            <option value="+64">NZ (+64)</option>
                            <option value="+968">OM (+968)</option>
                            <option value="+507">PA (+507)</option>
                            <option value="+51">PE (+51)</option>
                            <option value="+689">PF (+689)</option>
                            <option value="+675">PG (+675)</option>
                            <option value="+63">PH (+63)</option>
                            <option value="+48">PL (+48)</option>
                            <option value="+1787">PR (+1787)</option>
                            <option value="+351">PT (+351)</option>
                            <option value="+680">PW (+680)</option>
                            <option value="+595">PY (+595)</option>
                            <option value="+974">QA (+974)</option>
                            <option value="+262">RE (+262)</option>
                            <option value="+40">RO (+40)</option>
                            <option value="+7">RU (+7)</option>
                            <option value="+250">RW (+250)</option>
                            <option value="+966">SA (+966)</option>
                            <option value="+677">SB (+677)</option>
                            <option value="+1758">SC (+1758)</option>
                            <option value="+248">SC (+248)</option>
                            <option value="+249 ">SD (+249 )</option>
                            <option value="+46 ">SE (+46 )</option>
                            <option value="+65">SG (+65)</option>
                            <option value="+290">SH (+290)</option>
                            <option value="+386">SI (+386)</option>
                            <option value="+963">SI (+963)</option>
                            <option value="+421">SK (+421)</option>
                            <option value="+232">SL (+232)</option>
                            <option value="+378">SM (+378)</option>
                            <option value="+221">SN (+221)</option>
                            <option value="+252">SO (+252)</option>
                            <option value="+597 ">SR (+597 )</option>
                            <option value="+239">ST (+239)</option>
                            <option value="+503">SV (+503)</option>
                            <option value="+268 ">SZ (+268 )</option>
                            <option value="+1649">TC (+1649)</option>
                            <option value="+228">TG (+228)</option>
                            <option value="+66">TH (+66)</option>
                            <option value="+7">TJ (+7)</option>
                            <option value="+7">TM (+7)</option>
                            <option value="+993">TM (+993)</option>
                            <option value="+216">TN (+216)</option>
                            <option value="+676">TO (+676)</option>
                            <option value="+90">TR (+90)</option>
                            <option value="+1868">TT (+1868)</option>
                            <option value="+688">TV (+688)</option>
                            <option value="+886">TW (+886)</option>
                            <option value="+380">UA (+380)</option>
                            <option value="+256">UG (+256)</option>
                            <option value="+1">US (+1)</option>
                            <option value="+598">UY (+598)</option>
                            <option value="+7">UZ (+7)</option>
                            <option value="+379">VA (+379)</option>
                            <option value="+58">VE (+58)</option>
                            <option value="+1284">VG (+1284)</option>
                            <option value="+1340">VI (+1340)</option>
                            <option value="+84">VN (+84)</option>
                            <option value="+678">VU (+678)</option>
                            <option value="+681">WF (+681)</option>
                            <option value="+967">YE (+967)</option>
                            <option value="+969">YE (+969)</option>
                            <option value="+269">YT (+269)</option>
                            <option value="+27">ZA (+27)</option>
                            <option value="+260">ZM (+260)</option>
                            <option value="+263">ZW (+263)</option>
                          </select>
                          <label className="ps-4">
                            Country Code <span className="errorText">*</span>
                          </label>
                        </div>
                        <div className="form-floating col-md-12 mb-4">
                          <input
                            type="number"
                            className="form-control border-bottom"
                            {...signUpMobileRegister("mobileNumber")}
                            onChange={(event) =>
                              setUserPhoneNumber(event.target.value)
                            }
                            onInput={(e) =>
                              (e.currentTarget.value =
                                e.currentTarget.value.slice(0, 10))
                            }
                            id="mobilePhoneNumber"
                            placeholder="Phone Number"
                          />
                          <label htmlFor="mobilePhoneNumber" className="ps-4">
                            Phone Number <span className="errorText">*</span>
                          </label>
                          {signUpMobileErrors?.countryCode && (
                            <p className="frmErrors errorText mb-0 mt-0">
                              {signUpMobileErrors?.countryCode?.message}
                            </p>
                          )}
                          {signUpMobileErrors?.mobileNumber && (
                            <p className="frmErrors errorText mb-0 mt-0">
                              {signUpMobileErrors?.mobileNumber?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      {emailStatus !== "200" && (
                        <button
                          type="submit"
                          disabled={otpstate === "pending" ? true : false}
                          className="w-100 submit mobileSubmit mt-3"
                        >
                          <strong>SIGN UP</strong>
                        </button>
                      )}
                      <button
                        className="w-100 mobileSignUp mt-3"
                        type="button"
                        onClick={showLogIn}
                      >
                        <strong>SIGN IN</strong>
                      </button>

                      <p className="mt-5 mb-3 text-muted text-center">
                        © 2024 TTEC, All rights reserved.
                      </p>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </main>
        )}
        {regrequestType === "genOtp" && otpstatus === "200" && (
          <OTPVerification
            details={{
              pwd: userPassword,
              emp_uid: userEmp_uid,
              email: userPersonalEmail,
              phoneNumber: userCountryCode + userPhoneNumber,
            }}
          />
        )}

        {(nonSSOReqType === "login" || nonSSOReqType === "validate") &&
          (nonSSOStatus === "success" || nonSSOStatus === "fail") &&
          nonSSOMsg === "" && (
            <OTPVerification
              details={{
                type: nonSSOReqType,
                state: nonSSOState,
                status: nonSSOStatus,
                mail: mail,
                pwd: password,
              }}
            />
          )}
      </div>
      <div id="loginWeb">
        <HeaderSection />
        {((isLogin &&
          nonSSOReqType === "" &&
          nonSSOState !== "fulfilled" &&
          nonSSOStatus !== "success") ||
          (!isLogin && otpstatus !== "200")) && (
          <div className="mt-5 pt-4 loginContainer">
            <div className="cont loginDropShadow" ref={navRef}>
              <div className="form sign-in">
                {!isForgotPwd && (
                  <form
                    className="Auth-form"
                    onSubmit={signInHandle(handleNonSSOLogin)}
                  >
                    <div id="divSignIn">
                      <h2>Welcome Back!</h2>
                      <p className="mt-4 ms-3"
                      style={{ color: "red"}}>
                        <strong>Login Note : </strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Before joining TTEC: </strong> Please use the
                          <strong> SIGN IN</strong> button. Use the same
                          personal email address you provided to TTEC during
                          your employment application.
                        </li>
                        <li>
                          <strong>After joining TTEC:</strong> Please use
                          <strong> Connect with SSO</strong> button. Use your
                          work email address and login with SSO option.
                        </li>
                        <li>
                          <strong>For Internal transfers:</strong> If you are an
                          internal transfer, you do not need to Sign Up. Please
                          login with your TTEC SSO credentials and use Connect
                          with SSO button.
                        </li>
                      </ul>
                      {!isFirstLoginCall &&
                        nonSSOStatus !== "success" &&
                        nonSSOState === "failed" && (
                          <div className="alert otp-fail">{nonSSOMsg}</div>
                        )}
                      <label className="d-block">
                        <span>Email</span>
                        <input
                          type="email"
                          {...signInRegister("signinEmail")}
                          onKeyUp={(event) =>
                            setLoginMail(event.currentTarget.value)
                          }
                        />
                      </label>
                      {signInErrors?.signinEmail && (
                        <p className="frmErrors mt-2">
                          {signInErrors?.signinEmail?.message}
                        </p>
                      )}
                      <label className="d-block">
                        <span>
                          Password{" "}
                          <span
                            className="pwdtooltip"
                            data-tooltip="Password must contain atleast 8 characters including one uppercase, one lowercase, one number and one special character"
                          >
                            i
                          </span>
                        </span>
                        <input
                          type="password"
                          {...signInRegister("password")}
                          onKeyUp={(event) =>
                            setLoginPassword(event.currentTarget.value)
                          }
                        />
                      </label>
                      {signInErrors?.password && (
                        <p className="frmErrors mt-2">
                          {signInErrors?.password?.message}
                        </p>
                      )}
                      <p className="forgot-pass pb-5">
                        <Link to="" onClick={handleForgotPassword}>
                          Forgot Password?
                        </Link>
                      </p>
                      <button
                        type="submit"
                        disabled={nonSSOState === "pending" ? true : false}
                        className="submit"
                      >
                        <strong>SIGN IN</strong>
                      </button>
                      <br />
                      <button
                        type="button"
                        className="fb-btn"
                        onClick={() => handleSSOLogin(instance)}
                      >
                        <strong>
                          Connect with <span>SSO</span>
                        </strong>
                      </button>
                    </div>
                  </form>
                )}
                {isForgotPwd && (
                  <form
                    className="Auth-form"
                    onSubmit={forgotPwdHandle(handleForgetPassword)}
                  >
                    <div id="divforgotPassword">
                      <h2>Forgot Password?</h2>
                      {!isFirstFPCall &&
                        mailStatus !== "200" &&
                        fpMail !== "" &&
                        state === "fulfilled" && (
                          <div className="alert otp-fail">
                            Please enter the valid email
                          </div>
                        )}
                      {mailStatus !== "200" && (
                        <label className="d-block">
                          <span>Email</span>
                          <input
                            type="email"
                            {...forgotPwdRegister("forgotPwdEmail")}
                            onChange={(event) => setfpMail(event.target.value)}
                          />
                        </label>
                      )}
                      <p className="frmErrors pb-1 mt-2">
                        {forgotPwdErrors?.forgotPwdEmail &&
                          forgotPwdErrors?.forgotPwdEmail?.message}
                      </p>
                      {mailStatus === "200" && (
                        <div className="alert alert-success">
                          Your password reset email has been sent. If you don't
                          receive it please wait few minutes and try again.
                        </div>
                      )}
                      <div className="mb-4 text-center pb-5">
                        <Link to="" onClick={handleBackSignIn}>
                          Back to Sign In
                        </Link>
                      </div>
                      {mailStatus !== "200" && (
                        <button
                          type="submit"
                          disabled={state === "pending" ? true : false}
                          className="submit"
                        >
                          {/* {" "} */}
                          Send Email
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </div>

              <div className="sub-cont">
                <form
                  className="Auth-form-sub"
                  onSubmit={signUpHandle(handleSignUp)}
                >
                  <div className="img">
                    <div className="img__text m--up">
                      <h2><b>New here?</b></h2>
                      <p><strong>
                        Sign Up to our onboarding portal, TTEConnect, where you can view
                        information on next steps, review tasks list, and watch
                        the welcome video!
                      </strong></p>
                    </div>
                    <div className="img__text m--in">
                      <h2><b>One of us?</b></h2>
                      <p><strong>
                        If you already have an account, just sign in. We've
                        missed you!
                      </strong></p>
                    </div>
                    <div className="img__btn" onClick={onToggleClick}>
                      <button className="m--up" style={{color : "whitesmoke"}}>
                        <strong>SIGN UP</strong>
                      </button>
                      <button className="m--in" style={{color : "whitesmoke"}}>
                        <strong>SIGN IN</strong>
                      </button>
                    </div>
                  </div>
                  <div className="form-sign-up">
                    <h5 className="text-center">
                      Please use the same personal email address you provided to
                      TTEC during your employment application.
                    </h5>
                    <div className="row">
                      {emailStatus === "302" && otpstate !== "pending" && (
                        <div className="alert otp-fail mb-1 mt-0">
                          Invalid or already registered email.
                        </div>
                      )}
                    </div>
                    <label className="d-block">
                      <span>
                        Email Address <span className="errorText">*</span>
                      </span>
                      <input
                        type="email"
                        {...signUpRegister("signUpEmail")}
                        onChange={(event) =>
                          setUserPersonalEmail(event.target.value)
                        }
                      />
                    </label>
                    <p className="frmErrors pb-1 mt-2">
                      {(emailStatus === "400" &&
                        otpstate !== "pending" &&
                        "Invalid or already registered email.") ||
                        (signUpErrors?.signUpEmail &&
                          signUpErrors?.signUpEmail?.message)}
                    </p>
                    {emailStatus !== "200" && (
                      <label className="d-block">
                        <span>
                          Verify Email Address{" "}
                          <span className="errorText">*</span>
                        </span>
                        <input
                          type="email"
                          {...signUpRegister("verifyemail")}
                        />
                      </label>
                    )}
                    {signUpErrors?.verifyemail && (
                      <p className="frmErrors mt-2">
                        {signUpErrors?.verifyemail?.message}
                      </p>
                    )}
                    <label className="d-block">
                      <span>
                        Password <span className="errorText">*</span>{" "}
                        <span
                          className="pwdtooltip"
                          data-tooltip="Password must contain atleast 8 characters including one uppercase, one lowercase, one number and one special character"
                        >
                          i
                        </span>
                      </span>
                      <input
                        type="password"
                        {...signUpRegister("signUpPassword")}
                        onChange={(event) =>
                          setUserPassword(event.target.value)
                        }
                      />
                    </label>
                    {signUpErrors?.signUpPassword && (
                      <p className="frmErrors mt-2">
                        {signUpErrors?.signUpPassword?.message}
                      </p>
                    )}
                    <label className="d-block">
                      <span>
                        Verify Password <span className="errorText">*</span>
                      </span>
                      <input
                        type="password"
                        {...signUpRegister("verifypassword")}
                      />
                    </label>
                    {signUpErrors?.verifypassword && (
                      <p className="frmErrors mt-2">
                        {signUpErrors?.verifypassword?.message}
                      </p>
                    )}
                    <div className="row mobilePhoneContainer">
                      <div className="col-md-6 ps-0">
                        <label className="form-label mb-1 mt-0">
                          Country Code <span className="errorText">*</span>
                          <select
                            className="form-select"
                            {...signUpRegister("countryCode")}
                            onChange={(event) =>
                              setUserCountryCode(event.target.value)
                            }
                          >
                            <option value=""></option>
                            <option value="+376">AD (+376)</option>
                            <option value="+971">AE (+971)</option>
                            <option value="+1268">AG (+1268)</option>
                            <option value="+1264">AI (+1264)</option>
                            <option value="+374">AM (+374)</option>
                            <option value="+244">AO (+244)</option>
                            <option value="+54">AR (+54)</option>
                            <option value="+43">AT (+43)</option>
                            <option value="+61">AU (+61)</option>
                            <option value="+297">AW (+297)</option>
                            <option value="+994">AZ (+994)</option>
                            <option value="+387">BA (+387)</option>
                            <option value="+1246">BB (+1246)</option>
                            <option value="+880">BD (+880)</option>
                            <option value="+32">BE (+32)</option>
                            <option value="+226">BF (+226)</option>
                            <option value="+359">BG (+359)</option>
                            <option value="+973">BH (+973)</option>
                            <option value="+257">BI (+257)</option>
                            <option value="+229">BJ (+229)</option>
                            <option value="+1441">BM (+1441)</option>
                            <option value="+673">BN (+673)</option>
                            <option value="+591">BO (+591)</option>
                            <option value="+55">BR (+55)</option>
                            <option value="+1242">BS (+1242)</option>
                            <option value="+975">BT (+975)</option>
                            <option value="+267">BW (+267)</option>
                            <option value="+375">BY (+375)</option>
                            <option value="+501">BZ (+501)</option>
                            <option value="+1">CA (+1)</option>
                            <option value="+236">CF (+236)</option>
                            <option value="+242">CG (+242)</option>
                            <option value="+41">CH (+41)</option>
                            <option value="+682">CK (+682)</option>
                            <option value="+56">CL (+56)</option>
                            <option value="+237">CM (+237)</option>
                            <option value="+86">CN (+86)</option>
                            <option value="+57">CO (+57)</option>
                            <option value="+506">CR (+506)</option>
                            <option value="+381">CS (+381)</option>
                            <option value="+53">CU (+53)</option>
                            <option value="+238">CV (+238)</option>
                            <option value="+357">CY (+357)</option>
                            <option value="+9039">CY (+9039)</option>
                            <option value="+42">CZ (+42)</option>
                            <option value="+49">DE (+49)</option>
                            <option value="+253">DJ (+253)</option>
                            <option value="+45">DK (+45)</option>
                            <option value="+1809">DM (+1809)</option>
                            <option value="+1809">DO (+1809)</option>
                            <option value="+213">DZ (+213)</option>
                            <option value="+593">EC (+593)</option>
                            <option value="+372">EE (+372)</option>
                            <option value="+20">EG (+20)</option>
                            <option value="+291">ER (+291)</option>
                            <option value="+34">ES (+34)</option>
                            <option value="+251">ET (+251)</option>
                            <option value="+358">FI (+358)</option>
                            <option value="+679">FJ (+679)</option>
                            <option value="+500">FK (+500)</option>
                            <option value="+691">FM (+691)</option>
                            <option value="+298">FO (+298)</option>
                            <option value="+33">FR (+33)</option>
                            <option value="+241">GA (+241)</option>
                            <option value="+44">GB (+44)</option>
                            <option value="+1473">GD (+1473)</option>
                            <option value="+7880">GE (+7880)</option>
                            <option value="+594">GF (+594)</option>
                            <option value="+233">GH (+233)</option>
                            <option value="+350">GI (+350)</option>
                            <option value="+299">GL (+299)</option>
                            <option value="+220">GM (+220)</option>
                            <option value="+224">GN (+224)</option>
                            <option value="+590">GP (+590)</option>
                            <option value="+240">GQ (+240)</option>
                            <option value="+30">GR (+30)</option>
                            <option value="+502">GT (+502)</option>
                            <option value="+671">GU (+671)</option>
                            <option value="+245">GW (+245)</option>
                            <option value="+592">GY (+592)</option>
                            <option value="+852">HK (+852)</option>
                            <option value="+504">HN (+504)</option>
                            <option value="+385">HR (+385)</option>
                            <option value="+509">HT (+509)</option>
                            <option value="+36">HU (+36)</option>
                            <option value="+62">ID (+62)</option>
                            <option value="+353">IE (+353)</option>
                            <option value="+972">IL (+972)</option>
                            <option value="+91">IN (+91)</option>
                            <option value="+964">IQ (+964)</option>
                            <option value="+98">IR (+98)</option>
                            <option value="+354">IS (+354)</option>
                            <option value="+39">IT (+39)</option>
                            <option value="+1876">JM (+1876)</option>
                            <option value="+962">JO (+962)</option>
                            <option value="+81">JP (+81)</option>
                            <option value="+254">KE (+254)</option>
                            <option value="+996">KG (+996)</option>
                            <option value="+855">KH (+855)</option>
                            <option value="+686">KI (+686)</option>
                            <option value="+269">KM (+269)</option>
                            <option value="+1869">KN (+1869)</option>
                            <option value="+850">KP (+850)</option>
                            <option value="+82">KR (+82)</option>
                            <option value="+965">KW (+965)</option>
                            <option value="+1345">KY (+1345)</option>
                            <option value="+7">KZ (+7)</option>
                            <option value="+856">LA (+856)</option>
                            <option value="+961">LB (+961)</option>
                            <option value="+417">LI (+417)</option>
                            <option value="+94">LK (+94)</option>
                            <option value="+231">LR (+231)</option>
                            <option value="+266">LS (+266)</option>
                            <option value="+370">LT (+370)</option>
                            <option value="+352">LU (+352)</option>
                            <option value="+371">LV (+371)</option>
                            <option value="+218">LY (+218)</option>
                            <option value="+212">MA (+212)</option>
                            <option value="+377">MC (+377)</option>
                            <option value="+373">MD (+373)</option>
                            <option value="+261">MG (+261)</option>
                            <option value="+692">MH (+692)</option>
                            <option value="+389">MK (+389)</option>
                            <option value="+223">ML (+223)</option>
                            <option value="+95">MN (+95)</option>
                            <option value="+976">MN (+976)</option>
                            <option value="+853">MO (+853)</option>
                            <option value="+596">MQ (+596)</option>
                            <option value="+222">MR (+222)</option>
                            <option value="+1664">MS (+1664)</option>
                            <option value="+356">MT (+356)</option>
                            <option value="+960">MV (+960)</option>
                            <option value="+265">MW (+265)</option>
                            <option value="+52">MX (+52)</option>
                            <option value="+60">MY (+60)</option>
                            <option value="+258">MZ (+258)</option>
                            <option value="+264">NA (+264)</option>
                            <option value="+687">NC (+687)</option>
                            <option value="+227">NE (+227)</option>
                            <option value="+672">NF (+672)</option>
                            <option value="+234">NG (+234)</option>
                            <option value="+505">NI (+505)</option>
                            <option value="+31">NL (+31)</option>
                            <option value="+47">NO (+47)</option>
                            <option value="+670">NP (+670)</option>
                            <option value="+977">NP (+977)</option>
                            <option value="+674">NR (+674)</option>
                            <option value="+683">NU (+683)</option>
                            <option value="+64">NZ (+64)</option>
                            <option value="+968">OM (+968)</option>
                            <option value="+507">PA (+507)</option>
                            <option value="+51">PE (+51)</option>
                            <option value="+689">PF (+689)</option>
                            <option value="+675">PG (+675)</option>
                            <option value="+63">PH (+63)</option>
                            <option value="+48">PL (+48)</option>
                            <option value="+1787">PR (+1787)</option>
                            <option value="+351">PT (+351)</option>
                            <option value="+680">PW (+680)</option>
                            <option value="+595">PY (+595)</option>
                            <option value="+974">QA (+974)</option>
                            <option value="+262">RE (+262)</option>
                            <option value="+40">RO (+40)</option>
                            <option value="+7">RU (+7)</option>
                            <option value="+250">RW (+250)</option>
                            <option value="+966">SA (+966)</option>
                            <option value="+677">SB (+677)</option>
                            <option value="+1758">SC (+1758)</option>
                            <option value="+248">SC (+248)</option>
                            <option value="+249 ">SD (+249 )</option>
                            <option value="+46 ">SE (+46 )</option>
                            <option value="+65">SG (+65)</option>
                            <option value="+290">SH (+290)</option>
                            <option value="+386">SI (+386)</option>
                            <option value="+963">SI (+963)</option>
                            <option value="+421">SK (+421)</option>
                            <option value="+232">SL (+232)</option>
                            <option value="+378">SM (+378)</option>
                            <option value="+221">SN (+221)</option>
                            <option value="+252">SO (+252)</option>
                            <option value="+597 ">SR (+597 )</option>
                            <option value="+239">ST (+239)</option>
                            <option value="+503">SV (+503)</option>
                            <option value="+268 ">SZ (+268 )</option>
                            <option value="+1649">TC (+1649)</option>
                            <option value="+228">TG (+228)</option>
                            <option value="+66">TH (+66)</option>
                            <option value="+7">TJ (+7)</option>
                            <option value="+7">TM (+7)</option>
                            <option value="+993">TM (+993)</option>
                            <option value="+216">TN (+216)</option>
                            <option value="+676">TO (+676)</option>
                            <option value="+90">TR (+90)</option>
                            <option value="+1868">TT (+1868)</option>
                            <option value="+688">TV (+688)</option>
                            <option value="+886">TW (+886)</option>
                            <option value="+380">UA (+380)</option>
                            <option value="+256">UG (+256)</option>
                            <option value="+1">US (+1)</option>
                            <option value="+598">UY (+598)</option>
                            <option value="+7">UZ (+7)</option>
                            <option value="+379">VA (+379)</option>
                            <option value="+58">VE (+58)</option>
                            <option value="+1284">VG (+1284)</option>
                            <option value="+1340">VI (+1340)</option>
                            <option value="+84">VN (+84)</option>
                            <option value="+678">VU (+678)</option>
                            <option value="+681">WF (+681)</option>
                            <option value="+967">YE (+967)</option>
                            <option value="+969">YE (+969)</option>
                            <option value="+269">YT (+269)</option>
                            <option value="+27">ZA (+27)</option>
                            <option value="+260">ZM (+260)</option>
                            <option value="+263">ZW (+263)</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-md-6 pe-0">
                        <label className="form-label mb-0 mt-0">
                          Phone Number <span className="errorText">*</span>
                        </label>
                        <input
                          type="number"
                          {...signUpRegister("mobileNumber")}
                          onChange={(event) =>
                            setUserPhoneNumber(event.target.value)
                          }
                          onInput={(e) =>
                            (e.currentTarget.value =
                              e.currentTarget.value.slice(0, 10))
                          }
                        />
                      </div>
                      <p className="phnOtp mb-0 mt-2">
                        Verification code will be sent to this number
                      </p>
                      {signUpErrors?.countryCode && (
                        <p className="frmErrors errorText mb-0 mt-0">
                          {signUpErrors?.countryCode?.message}
                        </p>
                      )}
                      {signUpErrors?.mobileNumber && (
                        <p className="frmErrors errorText mb-0 mt-0">
                          {signUpErrors?.mobileNumber?.message}
                        </p>
                      )}
                    </div>
                    <br />
                    {emailStatus !== "200" && (
                      <button
                        type="submit"
                        disabled={otpstate === "pending" ? true : false}
                        className="submit"
                      >
                        <strong>SIGN UP</strong>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {regrequestType === "genOtp" && otpstatus === "200" && (
          <OTPVerification
            details={{
              pwd: userPassword,
              emp_uid: userEmp_uid,
              email: userPersonalEmail,
              phoneNumber: userCountryCode + userPhoneNumber,
            }}
          />
        )}

        {(nonSSOReqType === "login" || nonSSOReqType === "validate") &&
          (nonSSOStatus === "success" || nonSSOStatus === "fail") &&
          nonSSOMsg === "" && (
            <OTPVerification
              details={{
                type: nonSSOReqType,
                state: nonSSOState,
                status: nonSSOStatus,
                mail: mail,
                pwd: password,
              }}
            />
          )}
        <FooterSection />
      </div>
    </>
  );
};

export default Login;
