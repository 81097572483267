import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  MIDDLE_WARE_BASE_URL,
  CALL_PPOR_API
} from "../../../../utils/CONSTANTS";

const initialState = {
  state: "pending",
  stagesData: "",
  countData:"",
  welcomeTask: ''
};

export const stagesList = createAsyncThunk(
  "get/stageList",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), 
      data
    );
    return response;
  }
);

const stagesListSlice = createSlice({
  name: "stagesList",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(stagesList.pending, (state) => {
        state.state = "pending";
      })
      .addCase(stagesList.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.state = "fulfilled";
        state.stagesData = tempRes?.response?.stages?.row;
        state.countData=tempRes?.response?.count?.row;

        state.welcomeTask = tempRes?.response?.stages?.row;
       
        
      })
      .addCase(stagesList.rejected, (stageState) => {
        stageState.state = "failed";
      }),
});

export const stagesListDetailsData = (state: any) => state["stagesList"].state;
export const stagesListDetails = (state: any) => state["stagesList"].stagesData;
export const countListDetails= (state: any) => state["stagesList"].countData;
export const welcomeTask = (state: any) => state["stagesList"].welcomeTask? state["stagesList"].welcomeTask :0;
export default stagesListSlice.reducer;