import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import {
  MIDDLE_WARE_BASE_URL,
  CALL_PPOR_API,
} from "../../../utils/CONSTANTS";

const initialState = {
  profileState: "",
  profileUpdate: null,
};
export const ManagerDialogueBox: any = createAsyncThunk(
  "get/ManagerDialogueBox",
  async (data: any) => {
    const response = await axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), 
      data
    );
    return response;
  }
);

const managerProfileSlice = createSlice({
  name: "ManagerDialogueBox",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(ManagerDialogueBox.pending, (state) => {
        state.profileState = "pending";
      })
      .addCase(ManagerDialogueBox.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }
        // const flag = tempRes?.response?.response ;
        state.profileState = "fulfilled";
        state.profileUpdate = tempRes?.response;
        // console.log(tempRes?.response?.managerprofile)
        // console.log(tempRes?.response?.nhDtlView?.root)

      })
      .addCase(ManagerDialogueBox.rejected, (state) => {
        state.profileState = "failed";
      }),
});


export const profile = (state: any) => state.ManagerDialogueBox;

export default managerProfileSlice.reducer;
