import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
  state: "pending",
  managerData: "",
  moodMeterUpdate: "",
};

export const moodMeter = createAsyncThunk(
  "get/moodMeter",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);
const moodMeterSlice = createSlice({
  name: "moodMeter",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(moodMeter.pending, (state) => {
        state.state = "pending";
      })
      .addCase(moodMeter.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.state = "fulfilled";
        state.managerData = tempRes?.response?.ManagerDetails?.row;
        state.moodMeterUpdate = tempRes?.response?.ManagerDetails;
      })
      .addCase(moodMeter.rejected, (stageState) => {
        stageState.state = "failed";
      }),
});

export const managerListDetails = (state: any) =>
  state["moodMeter"].managerData;
export const moodMeterDetails = (state: any) => state["moodMeter"].state;

export default moodMeterSlice.reducer;
