import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../../utils/CONSTANTS";
import axios from "../../../../utils/axios";

let actionType : any = null;
const initialState = {
    status : '',
    state : '',
    ogData: null,
    ogState: '',

};

export const composeEmail = createAsyncThunk("post/composeEmail", async (data: any) => {
  actionType = data.input.request.action;
  const response = axios.postStringRequest(MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), data.input);
  return response;
});

export const composeEmailSlice = createSlice({
    name : 'composeEmail',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(composeEmail.pending, (state : any) =>
       {
            state.state = 'pending';

       })
       .addCase(composeEmail.fulfilled, (state : any,action : any) =>
       {
           let json = JSON.stringify(action?.payload?.data?.response);
           json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');

           if (actionType === 'Email') {
            state.status = JSON.parse(json)?.response?.email_status;
            state.state = 'fulfilled';
           } else if (actionType === 'getRecipientList') {

            if (Array.isArray( JSON.parse(json)?.response?.row)) {
                state.ogData = JSON.parse(json)?.response?.row;
             }
             else{
                state.ogData = [JSON.parse(json)?.response?.row];
             }
            state.state = 'fulfilled';
           }
        })
       .addCase(composeEmail.rejected, (state : any) =>
       {
            state.state = 'failed';
       })
});

export const selectComposeEmail = ((state : any) => state.composeEmail || {});

export default composeEmailSlice.reducer;
