import "./spinner.scss";
import { ProgressSpinner } from 'primereact/progressspinner';

const Spinner = () => {
  return (
    <div className="fp-container">
      <ProgressSpinner style={{ width: '130px', height: '130px' }} className="fp-loader" strokeWidth="3" animationDuration="1s" />
    </div>
  )
}

export default Spinner;