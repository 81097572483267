import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../../utils/CONSTANTS";
import axios from "../../../../utils/axios";


const initialState = {
    status : '',
    state : '',
    inboxData : [],
};

export const inboxEmail = createAsyncThunk("post/inboxEmail", async (data: any) => {
  const response = axios.postStringRequest(MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), data.input);
  return response;
});

export const inboxEmailSlice = createSlice({
    name : 'inboxEmail',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(inboxEmail.pending, (state : any) =>
       {
            state.state = 'pending';

       })
       .addCase(inboxEmail.fulfilled, (state : any,action : any) =>
       {
           let json = JSON.stringify(action?.payload?.data?.response);
           json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
           state.status = JSON.parse(json)?.response?.status;
           state.state = 'fulfilled';
           
            let jsonArray =  Object.keys([JSON.parse(json)?.response][0]).map((key) => [JSON.parse(json)?.response][0][key]);
            if (jsonArray.length === 3){
                if (Array.isArray( JSON.parse(json)?.response?.emailList)) {
                    state.inboxData = JSON.parse(json)?.response?.emailList
                 }
                 else{
                     state.inboxData = [JSON.parse(json)?.response?.emailList]
                 }
            
            }
            else{
                
            }
       })
       .addCase(inboxEmail.rejected, (state : any) =>
       {
            state.state = 'failed';
       })
});

export const selectInboxEmail = ((state : any) => state.inboxEmail || {});

export default inboxEmailSlice.reducer;
