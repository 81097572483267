import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, NON_LOGIN_CALLS_API } from '../../../../utils/CONSTANTS';
import axios from '../../../../utils/axios'; 

let requestType : any = null;

const initialState = {
    emp_uid : null,
    otpstatus : null,
    //validate_message: null,
    otpstate : '',
    regrequestType : '',
};

export const genOTPandSaveUser = createAsyncThunk(
    'post/genOTPandSaveUser', async(data : any) => {
    let response =null;
    requestType = data.requestType;
    response =  axios.postStringRequest(MIDDLE_WARE_BASE_URL() + NON_LOGIN_CALLS_API(), data.input);
    return response;
    }
);

export const genOTPandSaveUserSlice = createSlice({
    name : 'genOTPandSaveUser',
    initialState,
    reducers : {},
    extraReducers : (builder : any) =>
       builder
       .addCase(genOTPandSaveUser.pending, (state : any) =>
       {
            state.otpstate = 'pending';
       }
       )
       .addCase(genOTPandSaveUser.fulfilled, (state : any,action : any) =>
       {
           let json = JSON.stringify(action?.payload?.data?.response);
           json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
           state.emp_uid = JSON.parse(json)?.response?.emp_uid;
           state.otpstatus = JSON.parse(json)?.response?.status;
           state.regrequestType = requestType.type;
           state.otpstate = 'fulfilled';
       })
       .addCase(genOTPandSaveUser.rejected, (state : any) =>
       {
             state.otpstate = 'failed';
       })
});

export const selectOTPandUserDetails = ((state : any) => state.genOTPandSaveUser || {});
 
export default genOTPandSaveUserSlice.reducer;