import React, { useEffect, useState, useRef } from "react";

import "./MyMessages.scss";

import FooterSection from "../shared-components/footerSection";
import HeaderSection from "../shared-components/headerSection";

import { Button } from "primereact/button";

import { Inbox } from "./inbox/Inbox";
import { ComposeEmail } from "./compose/ComposeEmail";
import { ViewEmail } from "./viewEmail/ViewEmail";
import { Toast } from "primereact/toast";
//import { useDispatch } from 'react-redux';
import jwt_decode from "jwt-decode";

export function MyMessages() {
  const toast = useRef<any>(null);

  const [inboxSent, setInboxSent] = useState(true);
  const [composeEmail, setComposeEmail] = useState(false);

  const [selectedMailView, setSelectedMailView] = useState(false);
  const [emailArray, setEmailArray] = useState([]);

  const [discardEmail, setDiscardEmail] = useState(false);
  const [cancelView, setCancelView] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [userOG, setUserOG] = useState("");
  const [oracleId, setOracleId] = useState("0");
  //  const dispatch = useDispatch();

  const removeMessageDetails = () => {
    setEmailArray([]);
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Message sent",
      life: 3000,
    });
  };

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    let userRole: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
      userRole = localStorage.getItem("UserRole");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    setUserOG(userRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);

  useEffect(() => {
    if (discardEmail === true) {
      setComposeEmail(false);
      setInboxSent(true);
      setDiscardEmail(false);
    }

    if (cancelView === true) {
      setSelectedMailView(false);
      setInboxSent(true);
      setCancelView(false);
      removeMessageDetails();
    }
  }, [cancelView, discardEmail]);

  useEffect(() => {
    if (messageSent === true) {
      showSuccess();
    }
  }, [messageSent]);

  return (
    <>
      <HeaderSection />

      <div className="container-fluid px-5 main-container mb-5">
        <h1 className="display-5 mt-3 text-start">Messages</h1>
        <hr />

        <input type="hidden" value={oracleId} />

        {inboxSent && (
          <div>
            {!selectedMailView &&
              userOG !== "OG" &&
              userOG !== "TeamLeader" &&
              userOG !== "Trainer" &&
              userOG !== "Manager" && (
                <div className="row mb-2">
                  <div className="col-md-12 messages-button-demo">
                    <div className="template">
                      <Button
                        className="vimeo p-0 shadow rounded"
                        aria-label="Vimeo"
                        onClick={() => [
                          setInboxSent(false),
                          setComposeEmail(true),
                        ]}
                      >
                        <i className="pi pi-comment px-2"></i>
                        <span className="px-3">New Conversation</span>
                      </Button>
                    </div>
                  </div>
                </div>
              )}

            {!selectedMailView && (
              <Inbox
                sendToParentView={setSelectedMailView}
                sendToParentArray={setEmailArray}
              />
            )}

            {selectedMailView && (
              <ViewEmail
                arrayEmail={emailArray}
                sendToParentCancel={setCancelView}
                sendMessageSent={setMessageSent}
              />
            )}
          </div>
        )}

        {composeEmail && (
          <ComposeEmail
            sendToParent={setDiscardEmail}
            sendToParentViewMail={setSelectedMailView}
            sendMessageSent={setMessageSent}
          />
        )}
      </div>
      <Toast ref={toast} />

      <FooterSection />
    </>
  );
}
