import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import { LOAD } from "../utils/CONSTANTS";
import { useIsAuthenticated } from "@azure/msal-react";



const PublicRoute=(props : any)=>{
    const accesstoken : any = localStorage.getItem('AccessToken-NonSSO');
    let isAuthenticated = useIsAuthenticated();
    return (isAuthenticated || accesstoken) ? <Navigate to={LOAD}/>:<Outlet/>
}

export default PublicRoute;