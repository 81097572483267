import React, {useEffect, useState} from 'react'
import "./otpVerification.scss";

interface ICountdown {
    hours: number;
    minutes: number;
    seconds: number;
}

const CountDownTimer = ({ hours = 0, minutes = 0, seconds = 60 }: ICountdown) => {
    const [time, setTime] = React.useState<ICountdown>({hours, minutes, seconds});
    const [showtimer, setShowTimer] = useState(true);
    
    useEffect(() => {
        const reset = () => setTime({hours: time.hours, minutes: time.minutes, seconds: time.seconds});

        const tick = () => {
   
            if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) 
                reset()
            else if (time.hours === 0 && time.seconds === 0) {
                setTime({hours: 0, minutes: time.minutes - 1, seconds: 59});
            } else if (time.seconds === 0) {
                setTime({hours: time.hours, minutes: time.minutes - 1, seconds: 59});
            } else {
                setTime({hours: time.hours, minutes: time.minutes, seconds: time.seconds - 1});
            }
        };

        const timerId = setInterval(() => tick(), 1000);
        setShowTimer((time.minutes !== 0 || (time.minutes === 0 && time.seconds !== 0)));
        return () => clearInterval(timerId);
    },[time.minutes, time.seconds, time.hours]);

    return (
        <>
         { showtimer &&
            <div className="divOTPTimer">OTP expires in : &nbsp;
            <div className="counter">
            <p className="counter">{`${time.hours.toString().padStart(2, '0')}:${time.minutes
            .toString()
            .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}</p> 
        </div>
        </div>
        }
        { !showtimer &&
            <div className="divOTPExpire">
            OTP expired. Please click on &nbsp;<b>Resend code</b>&nbsp; to send OTP again.
        </div>
        }
        </>
    );
}

export default CountDownTimer;
