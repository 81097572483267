import React from 'react';
/* import FooterSection from '../shared-components/footerSection'; */
import HeaderSection from '../shared-components/headerSection';

import "./iframeStyle.scss";

export function Iframe() {
    return (
        <>
            <HeaderSection />

            <div style={{ minHeight: '90vh' }} className='myIframe'>
                    <iframe title='iframe' width='100%' height='100%' src="https://automation-forms-dev.azurewebsites.net/onboarding/us/prephase/newHire/before5days/?c3RlcF91aWQ9MjM0NTYmZW1wbG95ZWVVSWQ9NDc5NiZvcmFjbGVJRD0zMzc0MjQwJnN0ZXA9TmV3IEhpcmUgNSBkYXlzIGJlZm9yZSZwcmVmZXJyZWROYW1lPUpvc2VwaCZzdGFydERhdGU9MjAgRGVjZW1iZXIgMjAyMSZ3b3JrQXJyVHlwZT1Xb3JrIEF0IEhvbWUmc3VwZXJ2aXNvck5hbWU9QW1pciZicmFuZD1UVEVD" allowFullScreen></iframe>
            </div>

            {/* <FooterSection /> */}

        </>
    )
}
