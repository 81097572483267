import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
  asttState: "pending",
  asttProfileData: null,
};

export const asttEmpProfile = createAsyncThunk(
  "get/asttEmpProfile",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), // change it to CALL_PPOR_API
      data
    );
    return response;
  }
);

const asttEmpProfileSlice = createSlice({
  name: "asttEmpProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(asttEmpProfile.pending, (profileState) => {
        profileState.asttState = "pending";
      })
      .addCase(asttEmpProfile.fulfilled, (profileState, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        profileState.asttState = "fulfilled";
        // profileState.profileData = tempRes.PersonalDet.result;
        profileState.asttProfileData = tempRes?.response;
        // console.log(profileState.profileData);
      })
      .addCase(asttEmpProfile.rejected, (profileState) => {
        profileState.asttState = "failed";
      }),
});

export const asttEmpProfileDetails = (state: any) => state.asttEmpProfile;

export default asttEmpProfileSlice.reducer;
