import React from "react";
import {Outlet, Navigate, useLocation } from "react-router-dom";
import { LOGIN, MYMESSAGES } from '../utils/CONSTANTS';
import { useIsAuthenticated } from "@azure/msal-react";

const ProtectedRoute = (props: any) => {  //ProtectedRouteType
	const accesstoken : any = localStorage.getItem('AccessToken-NonSSO');
	const SSOToken = localStorage.getItem("AccessToken");
    let isAuthenticated = useIsAuthenticated();
	const location = useLocation();
	let userRole:any = ' ' ;

	if (SSOToken){
		if (localStorage.getItem("UserRole") && localStorage.getItem("UserRole") !== '') {
			userRole = localStorage.getItem("UserRole");
		} else{
			userRole = " " 
		}
	}
	
	if (props.roleRequired) {
		return (isAuthenticated || accesstoken) ? (

			props.roleRequired === userRole ? (
				<Outlet />
			) : (
				<Navigate to={MYMESSAGES} />
			)
		) : 
		(
			props.roleRequired !== 'OG' ? (
				<Navigate to={LOGIN} />
			) : (
				<Navigate to={LOGIN} replace state={{ redirectTo: location }} />  
			)
			 	  
		) 
		
	} else {

	return (isAuthenticated || accesstoken)? <Outlet /> : <Navigate to={LOGIN} />
	}

}

export default ProtectedRoute;

