import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';

const initialState = {
    data : null,
    state : 'pending'
};

export const getperson = createAsyncThunk(
    'get/person', async(data) => {
        return axios.getRequest(`https://jsonplaceholder.typicode.com/users`);   //?id=${data.Id}
    }
);

export const personSlice = createSlice({
    name : 'person',
    initialState,
    reducers : {},
    extraReducers : (builder) =>
       builder
       .addCase(getperson.pending, (state) =>
       {
            state.state = 'pending';
       })
       .addCase(getperson.fulfilled, (state,action) =>
       {
            state.data = (action.payload[0]);
            state.state = 'fulfilled';
       })
       .addCase(getperson.rejected, (state) =>
       {
            state.state = 'failed';
       })
});

export const selectPerson = (state => state.person);

export default personSlice.reducer;