import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import {
  MIDDLE_WARE_BASE_URL,
  CALL_PPOR_API,
} from "../../../utils/CONSTANTS";

const initialState = {
  UnsubscribeState: "pending",
  UnsubscribeUpdate: null,
};
export const UnsubscribeTTEConnect: any = createAsyncThunk(
  "get/UnsubscribeTTEConnect",
  async (data: any) => {
    const response = await axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), 
      data
    );
    return response;
  }
);

const UnsubscribeSlice = createSlice({
  name: "UnsubscribeTTEConnect",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(UnsubscribeTTEConnect.pending, (state) => {
        state.UnsubscribeState = "pending";
      })
      .addCase(UnsubscribeTTEConnect.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }
        // const flag = tempRes?.response?.response ;
        state.UnsubscribeState = "fulfilled";
        state.UnsubscribeUpdate = tempRes?.response;

      })
      .addCase(UnsubscribeTTEConnect.rejected, (state) => {
        state.UnsubscribeState = "failed";
      }),
});

export const UnsubscribeUpdate = (state: any) => state.UnsubscribeTTEConnect;
export const getisAlreadyUnsubscribe = (state: any) => state.UnsubscribeTTEConnect;

export default UnsubscribeSlice.reducer;
