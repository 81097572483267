import './LouieData.scss';
import { useEffect, useState } from 'react';
import {
    LOUIE_ENV_TYPE,
    LOUIE_BOT_ID,
    LOUIE_BOT_URL,
} from '../../../../utils/CONSTANTS';
import jwt_decode from "jwt-decode";

const LouieData = () => {

    // const { user } = useAppSelector(selectUser);
    const [oracleId, setOracleId] = useState("0");

    useEffect(() => {
        const SSOToken = localStorage.getItem("AccessToken");
        const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
        let id: any;
        if (SSOToken) {
          id = localStorage.getItem("oracleId");
        }
        if (nonSSOToken) {
          const decodedToken: any = jwt_decode(nonSSOToken);
          id = decodedToken?.oracleID;
        }
        setOracleId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const loadChatBot = () => {
        const chatbotScript = document.getElementById('chatbot-script');

        if (!chatbotScript) {
            const script = document.createElement('script');
            script.src = `${LOUIE_BOT_URL}/UI/kore-load.js?oracle_id=` + oracleId;
            
            script.id = 'chatbot-script';

            document.body.appendChild(script);
        }
    };
    useEffect(()=>loadChatBot());
    return (
        <div className="bot-control-wrap" data-testid="louie-content">
            <div id="ttec-page-info" data-ttec-env-type={LOUIE_ENV_TYPE} style={{ display: "none" }}>
                {oracleId}
            </div>
            <div id="chatContainer" data-kore-bot-id={`st-${LOUIE_BOT_ID}`} />
        </div>
    );
};
export default LouieData;
