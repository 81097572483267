import React, { useEffect, useState } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, InferType } from "yup";
import { useForm } from "react-hook-form";
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { useDispatch, useSelector } from 'react-redux';
import { composeEmail, selectComposeEmail } from './composeEmailSlice';
import Spinner from "../../../../utils/spinner";
import jwt_decode from "jwt-decode";
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';


export function ComposeEmail(props: any) {

    const [text1, setText1] = useState<string>('');
    const [textCount, setTextCount] = useState<any>(0);
    const { status, state, ogData } = useSelector(selectComposeEmail);
    const [sendEmailStatus, setSendEmailStatus] = useState("");
    const dispatch = useDispatch();
    const [emailRecipient, setEmailRecipient] = useState<any>(null);
    const [emailSubject, setEmailSubject] = useState<string>('');
    const [newEmail, setNewEmail] = useState(true);
    const [oracleId, setOracleId] = useState("0");
    const [ogDetails, setOgDetails] = useState([]);
    const [ogValue, setogValue] = useState<any>(null);
    const [fileName, setFileName] = useState<string>('');
    const [fileContent, setFileContent] = useState<any>();
    const [fileType, setFileType] = useState<any>();

    const emailSchema = object({
        emailMessage: string()
            .required("Please select the recipient"),
        subjectMessage: string()
            .required("Please enter email subject"),
    });

    type emailProps = InferType<typeof emailSchema>;

    const { register: emailRegister, handleSubmit: emailHandle, formState: { errors: emailErrors } } =
        useForm<emailProps>({
            resolver: yupResolver(emailSchema),
        });

    useEffect(() => {
        const SSOToken = localStorage.getItem("AccessToken");
        const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
        let id: any;
        if (SSOToken) {
            id = localStorage.getItem("oracleId");
        }
        if (nonSSOToken) {
            const decodedToken: any = jwt_decode(nonSSOToken);
            id = decodedToken?.oracleID;
        }
        setOracleId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("oracleId")]);


    const onDiscardEmail = () => {
        props.sendToParent(true);
        props.sendToParentViewMail(false)
    }

    const handleSubmitEmail = async () => {
        if(fileContent !== null && fileContent !== 0 && fileContent !== undefined){
        const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "NewEmail",
                    "userOracleId": oracleId,
                    "receiverEmpUid": emailRecipient,
                    "emailSubject": emailSubject,
                    "emailContent": text1,
                    "attachment":[ {
                        "file_name": fileName,
                        "attachment_id": "",
                        "file_extension": fileType,
                        "file_content": fileContent
                    } ]
                }
            }
        }
        dispatch<any>(composeEmail(data));
        setNewEmail(false);
    }else {
        const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "NewEmail",
                    "userOracleId": oracleId,
                    "receiverEmpUid": emailRecipient,
                    "emailSubject": emailSubject,
                    "emailContent": text1
                }
            }
        }
        dispatch<any>(composeEmail(data));
        setNewEmail(false);
    }
    };



    useEffect(() => {
        if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
            const data = {
                "input": {
                    "request": {
                        "action": "getRecipientList",
                        "oracleId": oracleId
                    }
                }
            };
            dispatch<any>(composeEmail(data));
        }
    }, [dispatch, oracleId]);

    useEffect(() => {
        setOgDetails(ogData);
    }, [ogData]);


    const onOgChange = (e: { value: any }) => {

        setogValue(e.value);
        setEmailRecipient(e.value.employee_uid);
    }





    useEffect(() => {
        if (state === 'fulfilled' && newEmail === false) {
            setSendEmailStatus(status);
        }
        if (sendEmailStatus === '200') {
            props.sendMessageSent(true);
            props.sendToParent(true);
            props.sendToParentViewMail(false);
            setSendEmailStatus("");
            setNewEmail(true);
        }
    }, [state, newEmail, sendEmailStatus, status, props]);


    const useEmailTemplate = (option: any, props: any) => {

        if (option) {
            if(option.email){
            return (
                <div>{option.role_name} - {option.last_name},{option.first_name}-({option.email})</div>
            );
            }
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }


    const emailOptionTemplate = (option: any) => {
        if (option) {
        if (option.email) {
            return (
                <div className="country-item">
                    <div>{option.role_name} - {option.last_name},{option.first_name}-({option.email})</div>
                </div>
            );
            }}
            else{
                return (
                    <div className="country-item">
                        <div>No recipient found</div>
                    </div>
                );
            }
    }

    
    const customBase64Uploader = async (event:any) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onload = function () {
            var inputData:any = reader.result;
            var replaceValue = (inputData.split(',')[0]);
            var base64data = inputData.replace(replaceValue + ",","");
            setFileContent(base64data);
            setFileName(file.name);
            setFileType(file.type);
        }

    }

    const removeFile = () => {
            setFileContent('');
            setFileName('');
            setFileType('');
        }


    const chooseOptions = {icon: 'pi pi-fw pi-paperclip', className: 'p-button-rounded p-button-success p-button-outlined rounded ms-3 float-left', label:'Upload'};

    const header = (
        <span className="ql-formats">
            <select className="ql-size" defaultValue="">
                <option value="small"></option>
                <option value=""></option>
                <option value="large"></option>
                <option value="huge"></option>
            </select>
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
        </span>
    );


    const onkeyup = (e: any, type: string) => {
        let keycode = e.which | e.keycode;
        if(keycode === 8)
        {
            if (type === "subject") {
                setEmailSubject(e.target.value);
            }
            else if (type === "text") {
                setText1(e.target.innerHTML)
            }
        }

    }

    const onkeypress = (e: any, type: string) => {
        let key = e.key;
        const arr = ["!","#","$","%","^","&","*","(",")","-","_","+","=",";",":","'","\"","<",">","/","|","\\","{","}","[","]","~","`"];
        // Check if key pressed is a special character
        if (arr.includes(key)) {
            // Restrict the special characters
            e.preventDefault();
            return false;
        }
        else {
            if (type === "subject") {
                setEmailSubject(e.target.value + key);
            }
            else if (type === "text") {
                setText1(e.target.innerHTML)
            }
        }
    }

    const onPaste = (e: any) => {
        e.preventDefault();
    }

    return (
        <div>
            {state === "pending" && <Spinner />}
            <form className="Auth-form" onSubmit={emailHandle(handleSubmitEmail)} >
                <div className='row mb-2'>
                    <div className='col-md-12 composeEmail'>

                        {emailErrors?.emailMessage && <small id="username-help" className="p-error d-inline-block ps-2" style={{ fontWeight: 'bold' }}>{emailErrors?.emailMessage?.message}</small>}
                        <div className="input-group mb-2 shadow-lg rounded">
                            <span className="input-group-text border-end"><strong>To</strong></span>

                            <Dropdown className="form-select" {...emailRegister("emailMessage")} value={ogValue} options={ogDetails} onChange={onOgChange} itemTemplate={emailOptionTemplate} valueTemplate={useEmailTemplate} optionLabel="email" placeholder="" />

                        </div>

                        {emailErrors?.subjectMessage && <small id="username-help" className="p-error d-inline-block ps-2" style={{ fontWeight: 'bold' }}>{emailErrors?.subjectMessage?.message}</small>}
                        <div className="input-group mb-2 shadow-lg rounded">
                            <span className="input-group-text"><strong>Topic</strong></span>
                            <input type="text" className="form-control" maxLength={100} autoComplete="off" {...emailRegister("subjectMessage")} onKeyPress={(event) => onkeypress(event, "subject")} onPaste={(event) => onPaste(event)} onKeyUp={(event) => onkeyup(event, "subject")}/>
                        </div>

                    </div>
                </div>

                <div className='row mb-2'>
                    <div className='col-md-12'>
                    <FileUpload name="demo[]" mode = "basic" onSelect={customBase64Uploader} onUpload={removeFile} accept="application/pdf" maxFileSize={5000000} chooseOptions={chooseOptions}  style={{ paddingBottom: "10px" }}  /> 
                        <p className="float-end me-4 " style={{ paddingTop: "10px" }}><strong>{textCount + "/1000"}</strong></p>
                        <Editor headerTemplate={header} maxLength={1000} style={{ height: '320px' }} value={text1} onTextChange={(e) => [setText1(e.htmlValue ? e.htmlValue : ''), e.textValue.length - 1 <= 1000 ? setTextCount(e.textValue.length - 1) : setTextCount(1000)]} onKeyPress={(e: any) => onkeypress(e, "text")} onPaste={(e: any) => onPaste(e)} onKeyUp={(event : any) => onkeyup(event, "text")} className="shadow-lg rounded" />
                    </div>
                </div>
                {<small id="username-help" className="d-inline-block ps-2" style={{ fontWeight: 'bold' }}>Maximum characters allowed are 1000.</small>}


                <div className='row mt-5'>
                    <div className='col-md-12'>
                        <Button label="Send" type="submit" className="p-button-raised p-button-success shadow-lg rounded" disabled={state === ("pending" ? true : false) || !text1} />
                        <Button label="Discard" className="p-button-rounded p-button-success p-button-outlined shadow-lg rounded ms-3" onClick={onDiscardEmail} />
                    </div>
                </div>
            </form>

        </div>
    )
}