export const LOGIN = "/";
export const HOME = "/home";
/* export const PROFILEPAGE = "/profileLanding"; */
export const COUNTER = "counter";
export const RESET = "resetPassword/:id/:email/:token";
export const FORGOTPASSWORD = "forgotPassword";
export const PROFILE = "profile";
export const MYMESSAGES = "messages";
export const CHANGEPASSWORD = "changePwd";
export const VIEWMESSAGES = "messages/:role/:id";
export const LOAD = "loading";
export const MYLOUIETASKS = "mylouietasks";
export const IFRAME = 'iframe';

export const MIDDLE_WARE_BASE_URL = () => `https://ppor-prod.azurewebsites.net/api/v1/`;
export const CALL_PPOR_API = () => `Informatica/callPPORAPIs`;
export const NON_LOGIN_CALLS_API = () => `NonLogin/nonLoginCalls`;
export const NON_LOGIN_REGISTER_API = () => `NonLogin/registerUSer`;
export const NON_LOGIN_RESET_PASSWORD_API = () => `NonLogin/resetPassword`;
export const NON_SSO_LOGIN_API = () => `NonSSOLogin/login`;
export const CHG_PWD_INT_API = () => `NonSSOLogin/cngPSInitial`;
export const NON_SSO_REVALIDATE_LOGIN_API = () => `NonSSOLogin/reValidateLogin`;
export const NON_SSO_LOGIN_REFRESH_TOKEN_API = () =>  `NonSSOLogin/refresh-token`;
export const NON_SSO_CHANGE_PASSWORD = () =>  `NonSSOLogin/changePassword`;

export const LOUIE_ENV_TYPE = process.env.REACT_APP_LOUIE_ENV_TYPE as string;
export const LOUIE_BOT_ID = process.env.REACT_APP_LOUIE_BOT_ID as string;
export const LOUIE_BOT_URL = process.env.REACT_APP_LOUIE_BOT_URL as string;

