import React, { useEffect, useState } from "react";
import './myLouieTasks.scss';
import HeaderSection from "../../shared-components/headerSection";
import FooterSection from "../../shared-components/footerSection";
import { louieSteps, getLouieSteps } from "./myLouieTasksSlice";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import TimelineItem from "../../shared-components/TimeLineItems";
import {empProfileDetails} from "../Profile/ProfileSlice";
function MyLouieTasks() {
  const { louieStepsState, louieStepsData } = useSelector(getLouieSteps);
  const [oracleId, setOracleId] = useState("0");
  const dispatch = useDispatch();
  const { profileData } = useSelector(empProfileDetails);

  const [tasksData, setTasksData] = useState();

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
  }, []);
  let countryName = profileData?.automation?.employee?.location_country;

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0" && 
    countryName !==null && countryName !== undefined && countryName !== "") {
      const data = {
        request: {
          "action": "getLouieSteps",
          "oracleId": oracleId,
          "country": countryName,
          "processType": countryName + " Onboarding"
        }
      };
      dispatch(louieSteps(data));
    }
  }, [dispatch, oracleId, countryName]);

  useEffect(() => {
    if (louieStepsData !== '' && louieStepsState === 'fulfilled') {
      setTasksData(louieStepsData);
    }
  }, [louieStepsData, louieStepsState]);

  return <div>
    <HeaderSection />
    <div style={{ height: '70vh', overflow: 'auto' }} className="mb-3">
      <div><h2 className="text-start ps-4 pb-4 pb-md-5 mt-md-4">
        My Louie Tasks
      </h2></div>
      {
        tasksData?.length > 0 ? (
          <div className="timeline-container">
            {tasksData?.map((data, i) => (
              <TimelineItem data={data} key={i} />
            ))
            }
          </div>)
          : louieStepsData === '' && louieStepsState === 'pending' ?
            (<div className="divLoad">Loading Data....</div>)
            : (<div className="divLoad">No Records Found</div>)
      }
    </div>
    <FooterSection />
  </div>
}


export default MyLouieTasks;