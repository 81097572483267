import React from 'react';
import { RouterConfig } from './routes/routerconfig';
import { BrowserRouter } from "react-router-dom";
import Spinner from "../src/utils/spinner";

function App() {

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <RouterConfig />
        </BrowserRouter>
      </div>
      <div className="divSpinner" style={{ display: "none" }}><Spinner /></div>
    </>
  );
}

export default App;
