import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
  louieStepsState: '',
  louieStepsData: '',
};

export const louieSteps = createAsyncThunk(
  "get/LouieSteps",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const louieStepsSlice = createSlice({
  name: "louieSteps",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(louieSteps.pending, (state) => {
        state.louieStepsState = "pending";
      })
      .addCase(louieSteps.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.louieStepsState = "fulfilled";
        state.louieStepsData = tempRes?.response?.employee;
      })
      .addCase(louieSteps.rejected, (state) => {
        state.louieStepsState = "failed";
      })
});

export const getLouieSteps = (state: any) => state.louieSteps;

export default louieStepsSlice.reducer;
