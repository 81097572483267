import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NON_SSO_LOGIN_API, MIDDLE_WARE_BASE_URL, NON_SSO_REVALIDATE_LOGIN_API, CHG_PWD_INT_API } from '../../../../utils/CONSTANTS';
import axios from '../../../../utils/axios'; 

let requestType : any = null;

const initialState = {
    nonSSOStatus : null,
    nonSSOState : null,
    nonSSOReqType : '',
    accesstoken : null,
    refreshtoken : null,
    nonSSOMsg : ''
};

export const nonSSOLogin = createAsyncThunk(
    'post/nonSSOLogin', async(data : any) =>  {
        let response =null;
        requestType = data.nonSSOReqType;
        try{
        if(requestType.type === 'login')
        {
       response = await axios.postRequest(MIDDLE_WARE_BASE_URL() + NON_SSO_LOGIN_API(), data.input);
        }
        if(requestType.type === 'ChgPwd')
        {
       response = await axios.postRequest(MIDDLE_WARE_BASE_URL() + CHG_PWD_INT_API(), data.input);
        }
        if(requestType.type === 'validate')
        {
       response = await axios.postRequest(MIDDLE_WARE_BASE_URL() + NON_SSO_REVALIDATE_LOGIN_API(), data.input);
        }
       return response;
    }
    catch (error : any) {
        return error.response; 
      }
});


export const nonSSOLoginSlice = createSlice({
    name : 'nonSSOLogin',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(nonSSOLogin.pending, (state : any) =>
       {
            state.nonSSOState = 'pending';
       })
       .addCase(nonSSOLogin.fulfilled, (state : any,action : any) =>
       {
           state.nonSSOState = 'fulfilled';
           state.nonSSOStatus = 'success';
           state.nonSSOReqType = requestType.type;
           state.nonSSOMsg = '';
           if(requestType.type === 'validate')
            {
                state.accesstoken = action?.payload?.accessToken;
                state.refreshtoken = action?.payload?.refreshToken;
            }
            if(requestType.type === 'login' || requestType.type === 'ChgPwd')
            {
               if(action?.payload?.data === 401 || action?.payload?.data === 400)
               {
                state.nonSSOState = 'failed';
                state.nonSSOStatus = 'fail';
                state.nonSSOReqType = '';
                state.nonSSOMsg = action?.payload?.data === 401 ?'Please login with SSO'
                                  : 'Invalid Email or Password';
            }             
            }

            if(requestType.type === 'validate')
            {
               if(action?.payload?.data === 400)
               {
                state.nonSSOState = 'failed';
                state.nonSSOStatus = 'fail';
               }
            }

            if(requestType.type === 'login' && action?.payload === undefined)
            {
                state.nonSSOState = 'failed';
                state.nonSSOStatus = 'fail';
                state.nonSSOReqType = '';
                state.nonSSOMsg = 'Unable to verify your credentials. Please try again' ;
            }
        })
       .addCase(nonSSOLogin.rejected, (state : any) =>
       {
            state.nonSSOReqType = 'Something went wrong';
            state.nonSSOState = 'failed';
            state.nonSSOStatus = 'fail';
       })
});

export const selectNonSSOLoginDetails = ((state : any) => state.nonSSOLogin || {});
 
export default nonSSOLoginSlice.reducer;