import { createSlice , createAsyncThunk} from "@reduxjs/toolkit"
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
  NonLouielogicState: '',
  NonLouielogicData: '',
  NonLouielogicflag: null,
};

export const NonLouielogic = createAsyncThunk(
  "get/NonLouielogic",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const NonLouielogicSlice = createSlice({
  name: "NonLouielogic",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(NonLouielogic.pending, (state) => {
        state.NonLouielogicState = "pending";
      })
      .addCase(NonLouielogic.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }
        const flag = tempRes?.response ;
        state.NonLouielogicState = "fulfilled";
        state.NonLouielogicState = tempRes;
        state.NonLouielogicflag = flag ;
      })
      .addCase(NonLouielogic.rejected, (state) => {
        state.NonLouielogicState = "failed";
      })
});

export const getNonLouielogic = (state: any) => state.NonLouielogic;

export default NonLouielogicSlice.reducer;
