import React, { useRef, useContext, useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { TieredMenu } from "primereact/tieredmenu";
import { logout } from "../../../utils/msal-utils";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import AuthContext from "./authContext";
import "./headerSection.scss";
import { empProfileDetails, empProfile } from "../ProfileLanding/Profile/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { HOME, MYMESSAGES } from "../../../utils/CONSTANTS";
import { Avatar } from "primereact/avatar";

import fortyLogo from "../../../images/logo-40th.png";

export default function HeaderSection() {
  const { profileData } = useSelector(empProfileDetails);

  const userMenu = useRef(null);
  const { nonSSOlogout } = useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const accesstoken = localStorage.getItem("AccessToken-NonSSO");
  const { accounts, instance } = useMsal();
  const dispatch = useDispatch();
  const [userOG, setUserOG] = useState("");

  let familyOG = "OG";
  const [oracleId, setOracleId] = useState("0");
  const role = localStorage.getItem("UserRole");

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestdata = {
        request: {
          action: "getEmpDetails",
          oracleId: oracleId,
          astt: "N",
        },
      };
      dispatch(empProfile(requestdata));
    }
  }, [dispatch, oracleId]);

  const userName = profileData?.oracle?.employee?.first_name
    ? profileData?.oracle?.employee?.first_name +
    " " +
    profileData?.oracle?.employee.last_name
    : "";

  function handleLogout(instance, accounts) {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-circle",
      accept: () => {
        isAuthenticated ? logout(instance, accounts) : nonSSOlogout();
      },
    });
  }
  let countryName = profileData?.automation?.employee?.location_country;

  const [myMessagesIcon, setMyMessagesIcon] = useState()
  useEffect(() => {
    if (profileData?.automation?.employee?.work_arrangement_type === "Work At Home" && profileData?.POD === "TRUE"

    ) {
      setMyMessagesIcon(
        true
      )
    }

  }, [profileData])
  let userItems;

  myMessagesIcon === true ?
    userItems = [
      { label: userName, icon: "pi pi-fw pi-user" },
      {
        separator: true,
      },
      {
        label: "My Messages",
        icon: "pi pi-comments",
        command: (e) => {
          window.location = "/messages";
        },
      },

      {
        separator: true,
      },
      {
        label: "Logout",
        icon: "pi pi-fw pi-power-off",
        command: () => handleLogout(instance, accounts),
      },
    ]
    : userItems = [
      { label: userName, icon: "pi pi-fw pi-user" },
      /* {
        separator: true,
      },
      {
        label: "Profile",
        icon: "pi pi-users",
        command: (e) => {
          window.location = "/profile";
        },
      }, */
      {
        separator: true,
      },
      {
        label: "Logout",
        icon: "pi pi-fw pi-power-off",
        command: () => handleLogout(instance, accounts),
      },
    ];

  if (role === '') {
    userItems.splice(1, 0,
      {
        separator: true,
      },
      {
        label: "Profile",
        icon: "pi pi-users",
        command: (e) => {
          window.location = "/profile";
        },
      },
    )
  }

  const userOGItems = [
    { label: userName, icon: "pi pi-fw pi-user" },
    {
      separator: true,
    },
    {
      label: "My Messsages",
      icon: "pi pi-comments",
      command: (e) => {
        window.location = "/messages";
      },
    },
    {
      separator: true,
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      command: () => handleLogout(instance, accounts),
    },
  ];

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    let userRole = null;
    if (SSOToken) {
      userRole = localStorage.getItem("UserRole");
    }

    setUserOG(userRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);

  const [empImage, setEmpImage] = useState("./images/accountDefaultImage.png");

  useEffect(() => {
    setEmpImage(
      profileData?.image?.row?.image || "./images/accountDefaultImage.png"
    );
  }, [profileData?.image?.row?.image]);

  return (
    <>
      <ConfirmDialog style={{ width: "100vw" }} />
      <header>
        <div className="px-3 bg-dark-header text-white">
          <div className="container-fluid px-0 px-md-3 px-md-5 py-3 pe-0 pe-md-0">
            <div className="d-flex flex-wrap align-items-start justify-content-start justify-content-lg-start">
              <a
                href="https://www.ttec.com/" target="_blank" rel="noreferrer"
                className="my-2 my-lg-0 text-white text-decoration-none"
              >
                <img
                  src="./ttec-logo-white.png"
                  className="img-fluid pt-1"
                  alt=""
                  width={"80px"}
                />
              </a>
              <a
                href="https://www.ttec.com/" target="_blank" rel="noreferrer">
                <img
                  src={fortyLogo}
                  className="img-fluid"
                  alt="TTEC 40 Years of Smile"
                />
              </a>
              <a
                href={role === "OG" ? MYMESSAGES : HOME} className="my-2 me-lg-auto" rel="noreferrer">
                <img
                  src="./Logo_white.png"
                  className="img-fluid ms-md-4 ps-2 ps-md-4 pt-1"
                  alt=""
                  width={"auto"}
                  height={"auto"}
                />
              </a>

              <ul className="nav col-12 col-lg-auto my-2 justify-content-start justify-content-md-end my-md-0 text-small">
                {countryName === "US" && (
                  <li>
                    <a
                      href="https://www.facebook.com/ttecUSA/"
                      className="nav-link text-white px-0 me-2"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i
                        className="pi pi-facebook bi d-block mx-auto mb-1"
                        style={{ fontSize: "1.4em" }}
                      ></i>
                    </a>
                  </li>
                )}
                {countryName === "MX" && (
                  <li>
                    <a
                      href="https://www.facebook.com/TTECmexico/"
                      className="nav-link text-white px-0 me-2"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i
                        className="pi pi-facebook bi d-block mx-auto mb-1"
                        style={{ fontSize: "1.4em" }}
                      ></i>
                    </a>
                  </li>
                )}
                 {countryName === "PL" && (
                  <li>
                    <a
                      href="https://www.facebook.com/TTECEurope"
                      className="nav-link text-white px-0 me-2"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i
                        className="pi pi-facebook bi d-block mx-auto mb-1"
                        style={{ fontSize: "1.4em" }}
                      ></i>
                    </a>
                  </li>
                )}
                {countryName === "PH" && (
                  <li>
                    <a
                      href="https://www.facebook.com/TeleTechPilipinas/"
                      className="nav-link text-white px-0 me-2"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i
                        className="pi pi-facebook bi d-block mx-auto mb-1"
                        style={{ fontSize: "1.4em" }}
                      ></i>
                    </a>
                  </li>
                )}
                {/* <li>
                  <a
                    href="https://twitter.com/tteclife"
                    className="nav-link text-white"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i
                      className="pi pi-twitter bi d-block mx-auto mb-1"
                      style={{ fontSize: "1.4em" }}
                    ></i>
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://twitter.com/tteclife"
                    className="nav-link text-white"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <svg className="pi-x-twitter" width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white" />
                    </svg>

                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/ttec/"
                    className="nav-link text-white"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i
                      className="pi pi-linkedin bi d-block mx-auto mb-1"
                      style={{ fontSize: "1.4em" }}
                    ></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/tteclife/"
                    className="nav-link text-white"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i
                      className="pi pi-instagram bi d-block mx-auto mb-1"
                      style={{ fontSize: "1.4em" }}
                    ></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://bit.ly/ttecyoutube"
                    className="nav-link text-white me-2"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i
                      className="pi pi-youtube bi d-block mx-auto mb-1"
                      style={{ fontSize: "1.4em" }}
                    ></i>
                  </a>
                </li>
                <li>
                  {(isAuthenticated || accesstoken) && userOG !== familyOG && (
                    <div>
                      <TieredMenu
                        model={userItems}
                        popup
                        ref={userMenu}
                        id="overlay_tmenu"
                        className="col-md-3 text-start"
                      />
                      <Avatar
                        image={empImage}
                        className="mr-2"
                        size="large"
                        shape="circle"
                        onClick={(event) => userMenu.current.toggle(event)}
                        aria-controls="popup_menu"
                        aria-haspopup
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                  )}

                  {(isAuthenticated || accesstoken) && userOG === familyOG && (
                    <div>
                      <TieredMenu
                        model={userOGItems}
                        popup
                        ref={userMenu}
                        id="overlay_tmenu"
                        className="col-md-3 text-start"
                      />
                      <Avatar
                        image={empImage}
                        className="mr-2"
                        size="large"
                        shape="circle"
                        onClick={(event) => userMenu.current.toggle(event)}
                        aria-controls="popup_menu"
                        aria-haspopup
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                  )}</li>
              </ul>


            </div>
          </div>
        </div>
      </header>
    </>
  );
}
