import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from "../../../utils/CONSTANTS";

const initialState = {
  AlreadyUnsubscribeState: "",
  AlreadyUnsubscribeflag: null,
};

export const checkAlreadyUnsubscribe: any = createAsyncThunk(
  "get/checkAlreadyUnsubscribe",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const checkAlreadyUnsubscribeSlice = createSlice({
  name: "checkAlreadyUnsubscribe",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(checkAlreadyUnsubscribe.pending, (state) => {
        state.AlreadyUnsubscribeState = "pending";
      })
      .addCase(checkAlreadyUnsubscribe.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }
        const flag = tempRes?.response;
        state.AlreadyUnsubscribeState = "fulfilled";
        state.AlreadyUnsubscribeflag = flag;
      })
      .addCase(checkAlreadyUnsubscribe.rejected, (state) => {
        state.AlreadyUnsubscribeState = "failed";
      }),
});

export const getcheckAlreadyUnsubscribe = (state: any) =>
  state.checkAlreadyUnsubscribe;
export const getAlreadyUnsubscribeflag = (state: any) =>
  state.checkAlreadyUnsubscribe;

export default checkAlreadyUnsubscribeSlice.reducer;
