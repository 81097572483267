import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import React, { useState, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import './viewEmail.scss';
import { DataScroller } from 'primereact/datascroller';
import { replyEmail, selectReplyEmail } from './replyEmailSlice';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { empProfileDetails } from '../../ProfileLanding/Profile/ProfileSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileAlt} from '@fortawesome/free-solid-svg-icons';
import { FileUpload } from 'primereact/fileupload';



export function ViewEmail(props: any) {

    const [text1, setText1] = useState<any>('');
    const [textCount, setTextCount] = useState<any>(0);
    const [messages, setMessages] = useState([]);
    const { status, state } = useSelector(selectReplyEmail);
    const [sendReplyStatus, setSendReplyStatus] = useState(false);
    const dispatch = useDispatch();
    const [oracleId, setOracleId] = useState("0");
    const { profileData } = useSelector(empProfileDetails);
    const [empImage, setEmpImage] = useState("");
    const [fileName, setFileName] = useState<string>('');
    const [fileContent, setFileContent] = useState<any>();
    const [fileType, setFileType] = useState();




    const onCancelView = (e:any) => {
        e.preventDefault()
        props.sendToParentCancel(true);

    }


    const handleSubmitReply = (event:any) => {
        event.preventDefault();
        if(oracleId !== null && oracleId !== undefined && oracleId !== "0" && fileContent !== null && fileContent !== 0 && fileContent !== undefined){
        const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "ReplyToEmail",
                    "senderEmpUid": props.arrayEmail.emailMain?.user_emp_id,
                    "receiverEmpUid": props.arrayEmail.emailMain?.receiver_emp_id,
                    "emailSubject": props.arrayEmail.emailMain?.email_subject,
                    "emailContent": text1,
                    "orMessageUid": props.arrayEmail.emailMain?.or_message_uid,
                    "orMessageDetailUid": props.arrayEmail.emailMain?.emailDetails[0].OR_Message_detail_uid,
                    "oracleId": oracleId,
                    "attachment":[ {
                        "file_name": fileName,
                        "attachment_id": "",
                        "file_extension": fileType,
                        "file_content": fileContent
                    } ]
                }
            }
        }
        dispatch<any>(replyEmail(data));
        setSendReplyStatus(true);
    } else if (oracleId !== null && oracleId !== undefined && oracleId !== "0" && fileContent === undefined){
        const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "ReplyToEmail",
                    "senderEmpUid": props.arrayEmail.emailMain?.user_emp_id,
                    "receiverEmpUid": props.arrayEmail.emailMain?.receiver_emp_id,
                    "emailSubject": props.arrayEmail.emailMain?.email_subject,
                    "emailContent": text1,
                    "orMessageUid": props.arrayEmail.emailMain?.or_message_uid,
                    "orMessageDetailUid": props.arrayEmail.emailMain?.emailDetails[0].OR_Message_detail_uid,
                    "oracleId": oracleId
                }
            }
        }
        dispatch<any>(replyEmail(data));
        setSendReplyStatus(true);
    }
    };

    useEffect(() => {
        const SSOToken = localStorage.getItem("AccessToken");
        const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
        let id: any;
        let decodedToken: any;
        if (SSOToken) {
          decodedToken = jwt_decode(SSOToken);
          id = localStorage.getItem("oracleId");
        }
        if (nonSSOToken) {
          decodedToken = jwt_decode(nonSSOToken);
          id = decodedToken?.oracleID;
        }
        setOracleId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [localStorage.getItem("oracleId")]);
        
    useEffect(() => {
        setMessages(props.arrayEmail.emailMain?.emailDetails.slice(0).reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 



    useEffect(() => {
        if (state === 'fulfilled' && status === '200' && sendReplyStatus === true ){
      
        props.sendMessageSent(true);
        props.sendToParentCancel(true);
        setSendReplyStatus(false);
        }
      }, [props, sendReplyStatus, state, status]);


    useEffect(() => {
        setEmpImage(
          profileData?.image?.row?.image || "./images/accountDefaultImage.png"
        );
      }, [profileData?.image?.row?.image]);

      const onkeyup = (e: any) => {
        let keycode = e.which | e.keycode;
        if(keycode === 8)
        {
          setText1(e.target.innerHTML)
        }

    }

    const onkeypress = (e: any) => {
        let key = e.key;
        const arr = ["!","#","$","%","^","&","*","(",")","-","_","+","=",";",":","'","\"","<",">","/","|","\\","{","}","[","]","~","`"];
        // Check if key pressed is a special character
        if (arr.includes(key)) {
            // Restrict the special characters
            e.preventDefault();
            return false;
        }
        else {
                setText1(e.target.innerHTML);
        }
    }

    const onPaste = (e: any) => {
        e.preventDefault();
    }
  


    const itemTemplate = (data:any) => {
        const datetime = data.sent_on.replace('T', " ").replace('Z', "");
        const email_message = data.email_content.replace('<\\', "<").replace('<\\/p', "<//p");
        const parse = require('html-react-parser');
        const email_content = parse(email_message) ;

        const downloadPDF = (pdf:any, nameFile:any) => {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
        
            downloadLink.href = linkSource;
            downloadLink.download = nameFile;
            downloadLink.click();
        }
    
        const downloadFile = (event:any) => {
            // console.log(event);
            // var pdf = event.toString
            downloadPDF((event),(data.attachmentDetails[0].attachment_name));
        }


        if (data.sender_oracle_id !== oracleId) {

        return (

                <div className='singleConversation mt-3 mb-4'>
                    <Avatar icon="pi pi-user" className="float-start imgshadow mb-3 me-3" size="xlarge" style={{  backgroundColor: '#2196F3', color: '#ffffff', width: '70px', height: '70px' }} />
                    <p><strong>{data.sender_full_name} - ({data.sender_email})</strong></p>
                    <span className='card-text'>{email_content}</span>
                    {data.attachmentDetails && <div className="card-body" style={{ maxHeight: '150px',maxWidth:'absolute', padding:0} }  >
                       <ul> <li className="pdfIcon" key={data.attachmentDetails[0].or_attachment_uid}>
                            <FontAwesomeIcon icon={faFileAlt} /> <p>{data.attachmentDetails[0].attachment_name}</p>
                                <div className="actions">
                                     <FontAwesomeIcon icon={faDownload} onClick={() => downloadFile(data.attachmentDetails[0].attachment_base_64)} />
                                </div>
                            </li>
                        </ul>
                    </div>}
                    <div className='clearfix'></div>
                    <p className="card-text text-end"><small className="text-muted">{datetime}</small></p>
                </div>

            );
        }


        if (data.sender_oracle_id === oracleId) {
                return (
        
                <div className='singleConversation mt-3 mb-4'>
                    <Avatar image= {empImage} className="float-end imgshadow mb-3 ms-3" size="xlarge" style={{ backgroundColor: '#2196F3', width: '70px', height: '70px' }} />
                    <p className='text-end'><strong>{data.sender_full_name} - ({data.sender_email})</strong></p>
                    <span className='card-text text-end'>{email_content}</span>
                    {data.attachmentDetails && <div className="card-body float-end" style={{ maxHeight: '150px',maxWidth:'absolute', padding:0} }  >
                        <ul><li className="pdfIcon" key={data.attachmentDetails[0].or_attachment_uid}>
                            <FontAwesomeIcon icon={faFileAlt} /> <p>{data.attachmentDetails[0].attachment_name}</p>
                                <div className="actions ms-2">
                                     <FontAwesomeIcon icon={faDownload} onClick={() => downloadFile(data.attachmentDetails[0].attachment_base_64)} />
                                </div>
                            </li>
                        </ul>
                    </div>}
                    <div className='clearfix'></div>
                    <p className="card-text"><small className="text-muted">{datetime}</small></p>
                </div> 
        
            );
        }
        
    }

    const customBase64Uploader = async (event:any) => {

        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onload = function () {
            var inputData:any = reader.result;
            var replaceValue = (inputData.split(',')[0]);
            var base64data = inputData.replace(replaceValue + ",","");
            setFileContent(base64data);
            setFileName(file.name);
            setFileType(file.type);
        }

    }



    const chooseOptions = {icon: 'pi pi-fw pi-paperclip', iconOnly: true, style: {paddingLeft: 2 ,paddingTop: '1px', border: 'none', background: 'none', color:'#707070', font: 'Arial'}};

    const header = (
        <div className="flex flex-wrap justify-content-center gap-3">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <FileUpload name="demo[]" mode="basic" onSelect={customBase64Uploader}   accept="application/pdf" maxFileSize={5000000} chooseOptions={chooseOptions}  />
        </div> 
    );



    return (

        <>
            <div className='row'>
                <form className="Auth-form" onSubmit={handleSubmitReply} >
                    <div className='col-md-12'>
                        <div className="card border-0 mt-2 bg-transparent">
                            <div className="card-body p-0">
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <div className="card shadow-lg rounded">
                                            <div className="card-header"><strong>{props.arrayEmail.emailMain?.email_subject}</strong></div>
                                            
                                            <div className="card-body" style={{ maxHeight: '350px', overflow: 'auto', display:'flex', flexDirection:'column-reverse',} }  >
                                                 <div className="card" >
                                                  <DataScroller  value={messages}  itemTemplate={itemTemplate} rows={5} inline />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-12'>
                                        <p className="float-end me-4" style={{ paddingTop: "10px" }}><strong>{textCount + "/1000"}</strong></p>
                                        <Editor headerTemplate={header} placeholder="Enter your message" style={{ height: '100%', maxHeight: '320px', overflow: 'auto' }} value={text1} maxLength={1000} onTextChange={(e) => [setText1(e.htmlValue), e.textValue.length - 1 <= 1000 ? setTextCount(e.textValue.length - 1) : setTextCount(1000)]} onKeyPress={(e: any) => onkeypress(e)} onPaste={(e: any) => onPaste(e)} onKeyUp={(event : any) => onkeyup(event)} className="shadow-lg rounded" />
                                    </div>
                                </div>
                                {<small id="username-help" className="d-inline-block ps-2 mt-3" style={{ fontWeight: 'bold' }}>Maximum characters allowed are 1000.</small>}


                                <div className='row mt-4'>
                                    <div className='col-md-12'>
                                         <Button label="Reply" type="submit" className="p-button-success rounded" disabled={state === ("pending" ? true : false) || !text1} />
                                        <Button label="Cancel" className="p-button-outlined p-button-success rounded ms-3" onClick={onCancelView} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}