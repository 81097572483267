import React from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import "./resources.scss";
import { useSelector } from "react-redux";
import { empProfileDetails } from "../Profile/ProfileSlice";


const DialogBox = () => {

  const { profileData } = useSelector(empProfileDetails);

  let countryName = profileData?.automation?.employee?.location_country;

  return (

    <ScrollPanel style={{ width: "auto", overflow: "auto" }}>
      {countryName === "US" &&
        (
          <div id="US"><p className="text-center">
            <img
              src="./images/ViewPayLouie.png"
              className="img-fluid w-50"
              alt="Your Pay Matters"
            ></img>
          </p>
            <p>Hi, Louie here!</p>
            <p>I have an important thing to talk to you about today. </p>
            <p>
              It involves one of my favorite subjects—payday! I bet it’s yours too.
            </p>
            <p>You may have some questions about how and when you get paid.</p>
            <p><strong>Note</strong>: Your trainer will go over payment details <strong>during Training</strong>. This is also when you will complete payment related tasks.</p>
            <p>
              First, be sure that you have signed up for direct deposit. Direct
              deposit allows TTEC to electronically transfer your pay into your bank
              account so that you get paid easily and on time. To sign up for direct
              deposit:
            </p>
            <ol type="1">
              <li>Log in to Oracle.</li>
              <li>
                Click the ‘TTEC Employee Self Service for US, TeleTech Holdings – US’
                folder.
              </li>
              <li>Click ‘My Payroll-Related Information’.</li>
              <li>Select the ‘Update or Add Direct Deposit Account’ option.</li>
              <li>Enter your banking information.</li>
            </ol>
            <br></br>
            <p>
              You can also check the{" "}
              <a
                href="https://asknow.service-now.com/esc?id=hr_kb_article_view&sys_id=1bc851551b643010ec4cfe25cc4bcbef"
                target={"_blank"}
                rel="noreferrer"
              >
                job aid
              </a>{" "}
              to set up Direct Deposit for more details.
            </p>
            <p>
              You will be paid every other Friday of the month. You can find the
              payroll schedule by searching for “payroll calendar” in the ‘Search’ box
              at the top-right corner of the Mosaic home page.
            </p>
            <p>If you need to view your pay stubs:</p>
            <ul>
              <li>Log in to Oracle.</li>
              <li>
                Click the ‘TTEC Employee Self Service for US, TeleTech Holdings – US’
                folder.
              </li>
              <li>Click ‘My Payroll-Related Information’.</li>
              <li>Click the ‘View Pay Statements’ option.</li>
              <li>Note: You cannot print paystubs from Oracle, however you can view and print paystubs and tax documents using{" "}
                <a
                  href="https://online.adp.com/signin/v1/?APPID=RDBX&productId=80e309c3-70c6-bae1-e053-3505430b5495&returnURL=https://my.adp.com/&callingAppId=RDBX&TARGET=-SM-https://my.adp.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  ADP
                </a>. {" "}
                Here
                is <a
                  href="https://teletechinc.sharepoint.com/teams/Global_Technology/ADP/Forms/AllItems.aspx?id=%2Fteams%2FGlobal%5FTechnology%2FADP%2F%5BADP%5D%20iPay%20Registration%20Job%20Aid%20%28en%29%2Epdf&parent=%2Fteams%2FGlobal%5FTechnology%2FADP"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  job aid
                </a> {" "}
                on ADP iPay Registration Process.
              </li>
            </ul>
            <br></br>
            <p className="mb-5">
              Hopefully this should answer most of your questions about how you get
              paid. If you have any other questions, please talk to your trainer.
            </p>
          </div>)}
      {countryName === "MX" &&
        (
          <div id="MX">
            <p className="text-center">
              <img
                src="./images/ViewPayLouie.png"
                className="img-fluid w-50"
                alt="Your Pay Matters"
              ></img>
            </p>
            <p>Hi, Louie here!</p>
            <p>I have an important thing to talk to you about today.</p>
            <p>It involves one of my favorite subjects—payday! I bet it’s yours too.
            </p>
            <p>You may have some questions about how and when you get paid.</p>
            <ul><li><p><strong>Santander</strong> is the bank that TTEC uses for payroll and this is where we will deposit your salary.</p></li>
              <li><strong>Debit card: </strong>Your trainer will reach out to you to schedule appointment to visit the bank and pick up the debit card. You will also receive an email when your card is available to pick up. Please remember, this visit must be scheduled outside the training class time.</li>
              <li><strong>Pay Day: </strong>You will be paid every <strong>5<sup>th</sup></strong> and <strong>20<sup>th</sup></strong> day of the month. If these days fall in the weekend, you will be paid the Friday before.</li>
              <li><strong>Pay Stubs: </strong>You will receive your pay stubs in the personal email provided to us with when you were hired. In any case the email is changed or you need paper pay stubs, please ensure to open a ticket HXconnect &gt;&gt; <a href="https://asknow.service-now.com/esc?id=hrsd_sc_cat_item&sys_id=ae9bb8231b475c103c2e86e9cd4bcb74&sysparm_category=31c78de49f331200d9011977677fcf6b" target={"_blank"} rel="noreferrer">Payroll Inquiry.</a> Once the pay stubs are ready, the Payroll team will provide an appointment for you to go to the site to collect them at the payroll window.</li>
              <li>How can I get a Infonavit, day care or IMSS letter?</li>
              <ul><li>Open a ticket using the link - HXconnect &gt;&gt; <a href="https://asknow.service-now.com/esc?id=hrsd_sc_cat_item&sys_id=ae9bb8231b475c103c2e86e9cd4bcb74&sysparm_category=31c78de49f331200d9011977677fcf6b" target={"_blank"} rel="noreferrer">Payroll Inquiry.</a></li>
                <li>Specify the type of letter you are requesting.</li>
                <li>Once the letter is available, the Payroll team will let you know so you can go to the site to collect the letter.</li>
              </ul>
            </ul>
            <p>Hopefully this should answer most of your questions about how you get paid. If you have any other questions, please talk to your trainer.</p>
            <p><strong>Consulta los detalles de pago</strong></p>
            <ul><li><p><strong>Santander</strong> es el banco que TTEC utiliza para el pago de nóminas.</p></li>
              <li><strong>Tarjeta de débito:</strong> Tu entrenador se pondrá en contacto contigo para programar una cita para visitar el banco y recoger la tarjeta de débito. Recuerda que esta visita debe programarse fuera del horario de entrenamiento. También recibirás un correo electrónico cuando tu tarjeta esté disponible para recoger.</li>
              <li><strong>Día de pago:</strong> Se te pagará los días 5 y 20 de cada mes. Si estos días caen en fin de semana, se te pagará el viernes anterior.</li>
              <li><strong>Recibos de Pago:</strong> Recibirás tus talones de pago en el correo electrónico personal que nos proporcionaste cuando fuiste contratado. En caso de que el correo electrónico cambie o necesites recibos de pago en papel, por favor asegúrate de crear un ticket HXconnect &gt;&gt; <a href="https://asknow.service-now.com/esc?id=hrsd_sc_cat_item&sys_id=ae9bb8231b475c103c2e86e9cd4bcb74&sysparm_category=31c78de49f331200d9011977677fcf6b" target={"_blank"} rel="noreferrer">Payroll Inquiry.</a> Una vez que los talones de pago estén listos, el equipo de nóminas te proporcionará una cita para que acudas al lugar a recogerlos por la ventanilla de nóminas.</li>
              <li>¿Cómo puedo obtener una carta de Infonavit, guardería o IMSS?</li>
              <ul><li>Crea un ticket a través del enlace HXconnect &gt;&gt; <a href="https://asknow.service-now.com/esc?id=hrsd_sc_cat_item&sys_id=ae9bb8231b475c103c2e86e9cd4bcb74&sysparm_category=31c78de49f331200d9011977677fcf6b" target={"_blank"} rel="noreferrer">Payroll Inquiry.</a></li>
                <li>Especifica el tipo de carta que estás solicitando.</li>
              </ul>
            </ul>
            <p>Una vez que la carta esté disponible, el equipo de nóminas te avisará para que acudas a recogerla.</p>
          </div>)}
      {countryName === "PH" &&
        (
          <div id="PH">
            <p className="text-center">
              <img
                src="./images/ViewPayLouie.png"
                className="img-fluid w-50"
                alt="Your Pay Matters"
              ></img>
            </p>
            <p>Hi, Louie here!</p>
            <p>I have an important thing to talk to you about today.</p>
            <p>It involves one of my favorite subjects—payday! I bet it's yours too.</p>
            <p>You may have some questions about how and when you get paid.</p>
            <ul><li>You will be paid every 10<sup>th</sup> and 25<sup>th</sup> day of the month. You can find the payday schedule in the <a href="https://teletechinc.sharepoint.com/teams/Enterprise_Services/SitePages/Payroll-Calendars.aspx/" target={"_blank"} rel="noreferrer">Payroll Calendar.</a></li>
              <li>If you need to view your pay stubs:</li><ul>
                <li>Log into Oracle.</li>
                <li>Click the 'TTEC Employee Self Service for PHI, TTEC Holdings - PHI' folder.</li>
                <li>Click the 'View Pay Statements' option.</li>
                <li>View details on how to download pay slips <a href="https://asknow.service-now.com/esc?id=hr_kb_article_view&sys_id=361e57d51bd6ec108e5b21fcbc4bcb62" target={"_blank"} rel="noreferrer">here</a></li></ul></ul>
            <p>Hopefully this should answer most of your questions about how you get paid. If you have any other questions, please talk to your trainer.</p>
          </div>
        )}
      {countryName === "PL" &&
        (
          <div id="PL"><p className="text-center">
            <img
              src="./images/ViewPayLouie.png"
              className="img-fluid w-50"
              alt="Your Pay Matters"
            ></img>
          </p>
            <p>Hi, Louie here!</p>
            <p>I have an important thing to talk to you about today. </p>
            <p>
              It involves one of my favorite subjects—payday! I bet it’s yours too.
            </p>
            <p>You may have some questions about how and when you get paid.</p>
            <p>Pay slips are available to view through <strong>TMF Horizon.</strong> You will receive an email to register on the platform.</p>

            <p><strong>Payroll questions</strong>: If you have any questions regarding payroll visit this <a href="https://asknow.service-now.com/esc?id=hrsd_sc_cat_item&sys_id=ae9bb8231b475c103c2e86e9cd4bcb74&sysparm_category=31c78de49f331200d9011977677fcf6b" target={"_blank"} rel="noreferrer">link</a>.</p>
            <p><strong>Pay Disputes</strong>: If you have to report any pay disputes, visit this <a href="https://asknow.service-now.com/esc?id=hrsd_sc_cat_item&sys_id=ada143371b0b1810d7c2b802dd4bcbfb&sysparm_category=31c78de49f331200d9011977677fcf6b" target={"_blank"} rel="noreferrer">link</a>.</p>
            <p>
              Remember, please verify all personal information in Oracle is accurate.
            </p>

            <br></br>
            <p>
              Hopefully this should answer most of your questions about how you get paid. If you have any other questions, please talk to your supervisor
              {" "}
              {" " +
                profileData?.oracle?.manager?.manager_first +
                " " +
                profileData?.oracle?.manager?.manager_last +
                "," +
                " " +
                profileData?.oracle?.manager?.manager_email}
              .
            </p>
          </div>
        )}

    </ScrollPanel>
  );
};

export default DialogBox;
