import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useDispatch, useSelector } from 'react-redux';
import { inboxEmail, selectInboxEmail } from './inboxEmailSlice';
import { updateEmail } from './updateEmailSlice';
import jwt_decode from "jwt-decode";
import Spinner from '../../../../utils/spinner';
import { empProfile } from '../../ProfileLanding/Profile/ProfileSlice';

export function Inbox(props: any) {

    const [messages, setMessages] = useState([]);
    const [selectedMessages, setSelectedMessages] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sender_email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sent_on: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        email_subject: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.BETWEEN }] },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const dispatch = useDispatch();
    const { inboxData } = useSelector(selectInboxEmail);
    const [oracleId, setOracleId] = useState("0");
    const [renderInbox, setRenderInbox] = useState(true);


    const onRowSelect = (event: any) => {
        props.sendToParentView(true);
        props.sendToParentArray(event.data);

        if(oracleId !== null && oracleId !== undefined && oracleId !== "0"){

        const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "UpdateEmailStatus",
                    "orMessageDetailUidList": [event.data.emailMain?.or_message_uid,event.data.emailMain?.emailDetails[0].OR_Message_detail_uid],
                    "emailStatusUpdate": "read",
                    "oracleId": oracleId
                }
            }
        }

        if (event.data.emailMain?.status === "unread" && event.data.emailMain?.emailDetails[0].sender_oracle_id !== oracleId ) {
        dispatch<any>(updateEmail(data));
        }
    }
        
    }



    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const renderHeader = () => {
        return (

            <div className="flex justify-content-between" style={{ display: "flow-root" }}>
                <span className="p-input-icon-left float-end me-4">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>

        );
    };

    const header = renderHeader();


    useEffect(() => {
        setMessages(inboxData);
        if (inboxData) {
        dispatchProfile()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxData]);


  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);

 

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
         const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "EmailInbox",
                    "userOracleId": oracleId
                }
            }
        };
        dispatch<any>(inboxEmail(data));
        
        setRenderInbox(false);
    }
  }, [dispatch, oracleId]);


  const dispatchProfile = () => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestdata = {
        request: {
          action: "getEmpDetails",
          oracleId: oracleId,
          astt: "N",
        },
      };
      dispatch<any>(empProfile(requestdata));
    }
};



     const  statusRowTemplate = (rowData:any) => {
        return {'messages-badge': rowData.emailMain?.status === 'unread' &&  rowData.emailMain?.emailDetails[0].sender_oracle_id !== oracleId    };
        
    }

    const statusDate = (rowData:any) => {
        return <span>{rowData.emailMain?.sent_on.replace('T', " ").replace('Z', "")}</span>;
    }

    const statusSender = (rowData:any) => {

        if (rowData.emailMain?.emailDetails[0].sender_oracle_id === oracleId){
        return <span>You: {rowData.emailMain?.sender_email}</span> 
        } else {
            return <span>{rowData.emailMain?.sender_email}</span>   
        }
    }


    return (
        
        <>

            <div className="card border-top-0 mt-4 border-0">
            { renderInbox === true && messages.length === 0 && <Spinner />}
                <div className="card-body p-0">
                    <DataTable value={messages} selection={selectedMessages} onSelectionChange={(e) => setSelectedMessages(e.value)} dataKey="id" rowClassName={statusRowTemplate}
                        rowHover paginator className="p-datatable-customers shadow-lg rounded" header={header} rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]} emptyMessage="No messages found." globalFilterFields={['emailMain.sender_email', 'emailMain.email_subject', 'emailMain.sent_on']} filters={filters}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" //onRowUnselect={onRowUnSelect}
                        responsiveLayout="stack" breakpoint="960px" onRowClick={onRowSelect} >

                        <Column headerStyle={{ width: '3em' }}  ></Column>
                        <Column body={statusSender} header="Sender"></Column>
                        <Column field="emailMain.email_subject" header="Topic"></Column>
                        <Column body={statusDate} header="Date" ></Column>
                    </DataTable>
                </div>
            </div>


        </>

    )
}