import axios from "axios";

const instance = axios.create({});

const BASE_URL = process.env.REACT_APP_BASE_URL_CONFIG;
const API_Endpoint_Path = () => `sampleAPI/getuserid`;
const oracleId=10;
const SYSTEM_ERROR = "System error. Please try again later!";

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

//Below is the one for sample axios call
export function sampleaxios(){
  return new Promise((resolve, reject) => {
    try {
      instance
        .get(BASE_URL + API_Endpoint_Path(), {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            oracleId: oracleId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
}
