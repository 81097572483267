import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import HeaderSection from "../shared-components/headerSection";
import FooterSection from "../shared-components/footerSection";

import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Profile from "./Profile/Profile";
import Resources from "./Resources/Resources";
import { TaskSection } from "./Tasks/TaskSection";
import { Timeline } from "primereact/timeline";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

import { empProfileDetails } from "../ProfileLanding/Profile/ProfileSlice";
//import { pizzaTracker } from "../ProfileLanding/PizzaTrackerSlice";
import "./ProfileLanding.scss";
//import { useIsAuthenticated } from "@azure/msal-react";
import { asttEmpProfileDetails } from "../ProfileLanding/Profile/asttProfileSlice";

import jwt_decode from "jwt-decode";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown, faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import { moodMeterOnload, managerListDetails } from "./moodMeterOnLoadSlice";
import { Message } from "primereact/message";
import { HomePageCarousel } from "../homePageCarousel/HomePageCarousel";

export function ProfileLanding() {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [oracleId, setOracleId] = useState("0");
  const { profileData } = useSelector(empProfileDetails);

  const { asttProfileData } = useSelector(asttEmpProfileDetails);
  const dispatch = useDispatch();
  //const isAuthenticated = useIsAuthenticated();
  const [isBirthDay, setIsBirthDay] = useState(false);
  const [isAnniDay, setisAnniDay] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isSSOLogin, setIsSSOLogin] = useState(false);
  const [moodMeterOverlay, setMoodMeterOverlay] = useState(false);
  const [moodMeterOverlayClosable, setMoodMeterOverlayClosable] =
    useState(false);
  const [moodMeterValue, setMoodMeterValue] = useState();
  let [remarks, setRemarks] = useState("");
  const { moodMeterState } = useSelector(managerListDetails);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const moodMeterToast = useRef<any>(null);
  const [stateValue, setStateValue] = useState(false);
  const [showHideMoodMeterIcons, setShowHideMoodMeterIcons] = useState(true);
  const [moodMeterOverlayContent, setMoodMeterOverlayContent] = useState(false);
  const [stateValueHappy, setStateValueHappy] = useState(false);

  const dialogFuncMap: any = {
    displayBasic: setDisplayBasic,
    moodMeterOverlay: setMoodMeterOverlay,
  };

  useEffect(() => {
    if (
      profileData?.oracle?.employee?.date_of_birth !== undefined &&
      profileData?.oracle?.employee?.date_of_birth !== null &&
      profileData?.oracle?.employee?.date_of_birth !== ""
    ) {
      const dob = moment(
        profileData?.oracle?.employee?.date_of_birth,
        "YYYY/MM/DD"
      );
      const month = dob.format("M");
      const day = dob.format("D");
      const currentDate = moment(new Date(), "YYYY/MM/DD");
      //const currentDate = moment("2023/09/21", "YYYY/MM/DD");
      const currentMonth = currentDate.format("M");
      const currentDay = currentDate.format("D");
      if (month === currentMonth && day === currentDay) {
        setIsBirthDay(true);
      } else {
        setIsBirthDay(false);
      }
      //console.log(month, day, currentMonth, currentDay);
    }
  }, [profileData]);

  useEffect(() => {
    if (
      profileData?.oracle?.employee?.hire_date !== undefined &&
      profileData?.oracle?.employee?.hire_date !== null &&
      profileData?.oracle?.employee?.hire_date !== ""
    ) {
      const doh = moment(
        profileData?.oracle?.employee?.hire_date,
        "YYYY/MM/DD"
      );
      const month = doh.format("M");
      const day = doh.format("D");
      const currentDate = moment(new Date(), "YYYY/MM/DD");
      //const currentDate = moment("2025/11/15", "YYYY/MM/DD");
      const currentMonth = currentDate.format("M");
      const currentDay = currentDate.format("D");
      if (month === currentMonth && day === currentDay) {
        setisAnniDay(true);
      } else {
        setisAnniDay(false);
      }
    }
  }, [profileData]);
  const currentDate = moment(new Date(), "YYYY/MM/DD");
  //const currentDate = moment("2027/11/15", "YYYY/MM/DD");
  let oracleDate = new Date(profileData?.oracle?.employee?.hire_date);
  const date1 = currentDate;
  const date2 = oracleDate;

  const diff = moment(date1).diff(moment(date2));
  const duration = moment.duration(diff);

  const expYears = duration.years();

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
      setIsSSOLogin(true);
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId"), localStorage.getItem("email")]);

  const onHide = (name: any) => {
    dialogFuncMap[`${name}`](false);
  };

  const marker2 = (item: any) => {
    let progress2 = "completed";
    let backgroundColor;
    let icon;
    let dateString = item.startDate;
    let date = new Date(dateString);
    let options: any = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    let hoverDate = date.toLocaleString("en-US", options);

    let hover;

    if (item.progress === "complete") {
      hover = `Completed \n Start Date: ${hoverDate}`;
      progress2 = "completed";
      backgroundColor = "#B7ED7D";
      icon = " pi pi-check";
    } else if (item.progress === "in-progress") {
      hover = `In-progress  \nStart Date: ${hoverDate}`;
      progress2 = "in-progress";
      backgroundColor = "#f9ea3fd1";
      icon = "pi pi-star-fill";
    } else if (item.progress === "not started") {
      hover = `Not-started`;
      progress2 = "incomplete";
      backgroundColor = "#EEEEEE";
      icon = "pi pi-star-fill";
    } else {
      hover = `Start Date: ${hoverDate}`;
      progress2 = "";
      backgroundColor = "#ECE9DD";
    }

    return (
      <span
        className={"custom-marker shadow-1 " + progress2}
        style={{ backgroundColor: backgroundColor, padding: "30px" }}
        data-pr-tooltip={hover}
      >
        <Tooltip target=".custom-marker" mouseTrack mouseTrackLeft={10} />
        <i className={icon} style={{ fontSize: "20px", color: "#BAB7AE" }}></i>
      </span>
    );
  };

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOracleId]);

  // useEffect(() => {
  //   const accesstoken = localStorage.getItem("AccessToken-NonSSO");
  //   if (isAuthenticated || accesstoken) {
  //     if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
  //       const requestdata = {
  //         request: {
  //           action: "pizzaTrackerStatic",
  //           oracleId: oracleId,
  //         },
  //       };
  //       dispatch<any>(pizzaTracker(requestdata));
  //     }
  //   }
  // }, [dispatch, isAuthenticated, oracleId]);

  useEffect(() => {
    const SSOToken: any = localStorage.getItem("AccessToken");
    const nonSSOToken: any = localStorage.getItem("AccessToken-NonSSO");
    if (SSOToken) {
      setDisplayBasic(false);
    } else if (nonSSOToken) {
      if (localStorage.getItem("ShowWelcomeVideo") === "true") {
        setDisplayBasic(true);
        localStorage.setItem("ShowWelcomeVideo", "false");
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      buttonRef?.current?.click();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let [firstChar, ...restChar] = profileData?.automation?.employee
    ?.preferred_name
    ? profileData?.automation?.employee?.preferred_name
    : "";
  const capitalPrefName = firstChar?.toUpperCase() + restChar.join("");

  let displayName = capitalPrefName
    ? capitalPrefName
    : "" || profileData?.oracle?.employee?.first_name
    ? profileData?.oracle?.employee?.first_name
    : "";

  const onKeypress = (e: any, type: string) => {
    let keycode = e.which || e.keyCode;

    // Check if key pressed is a special character
    if (
      keycode !== 32 &&
      (keycode < 44 ||
        keycode === 45 ||
        keycode === 47 ||
        (keycode > 57 && keycode < 65) ||
        (keycode > 90 && keycode < 97) ||
        keycode > 122)
    ) {
      // Restrict the special characters
      e.preventDefault();
      return false;
    } else {
      if (type === "remarks") {
        const sub = remarks + String.fromCharCode(keycode);
        setRemarks(sub);
      }
    }
  };

  const onPaste = (e: any) => {
    e.preventDefault();
  };

  const onSubmitMoodMeterHappy = () => {
    setStateValueHappy(true);
    dialogFuncMap["moodMeterOverlay"](false);
    const requestdata = {
      request: {
        action: "submitMoodMeter",
        flag: "updateMoodMeter",
        oracleId: oracleId,
        answer: "It's going well so far",
        feedbackVal: "no-remarks",
      },
    };
    dispatch<any>(moodMeterOnload(requestdata));
  };

  const onSubmitMoodMeterSad = () => {
    if (remarks === "" || remarks.trimLeft() === "") {
      setDisplayErrorMessage(true);
      return false;
    } else {
      setStateValue(true);
      dialogFuncMap["moodMeterOverlay"](false);
      const requestdata = {
        request: {
          action: "submitMoodMeter",
          flag: "updateMoodMeter",
          oracleId: oracleId,
          answer: "I have concerns or need help",
          feedbackVal: remarks,
        },
      };
      dispatch<any>(moodMeterOnload(requestdata));
    }
  };

  const onSubmitMoodMeterSadAnon = () => {
    if (remarks === "" || remarks.trimLeft() === "") {
      setDisplayErrorMessage(true);
      return false;
    } else {
      setStateValue(true);
      dialogFuncMap["moodMeterOverlay"](false);
      const requestdata = {
        request: {
          action: "submitMoodMeter",
          flag: "submitAnonymously",
          oracleId: oracleId,
          answer: "I have concerns or need help",
          feedbackVal: remarks,
        },
      };
      dispatch<any>(moodMeterOnload(requestdata));
    }
  };

  useEffect(() => {
    if (profileData?.moodmeter === "True") {
      dialogFuncMap["moodMeterOverlay"](true);
      setMoodMeterOverlayClosable(false);
      moodMeterToast.current.clear();
    } else {
      dialogFuncMap["moodMeterOverlay"](false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.moodmeter]);

  useEffect(() => {
    //console.log("State ... ", state, "stateValue ... ", stateValue);
    if (
      (moodMeterState === "fulfilled" && stateValue === true) ||
      (moodMeterState === "fulfilled" && stateValueHappy === true)
    ) {
      showMoodMeterToast();
      setDisplayErrorMessage(false);
    } else if (
      (moodMeterState === "pending" && stateValue === true) ||
      (moodMeterState === "pending" && stateValueHappy === true)
    ) {
      moodMeterToast.current.clear();
    } else if (
      moodMeterState === "fulfilled" &&
      stateValue === false &&
      stateValueHappy === false
    ) {
      setTimeout(() => {
        moodMeterToast.current.clear();
      }, 1000);
      setTimeout(() => {
        showMoodMeterToast();
      }, 2000);
    } else {
      showMoodMeterToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodMeterState, stateValue, stateValueHappy]);

  /*   useEffect(() => {
    setMoodMeterOverlayContent(false);
    if (moodMeterOverlay && showHideMoodMeterIcons === false) {
      setMoodMeterOverlayContent(true);
    } else {
      setMoodMeterOverlayContent(false);
    }
  }, [moodMeterOverlay, moodMeterOverlayContent, showHideMoodMeterIcons]); */

  const showMoodMeterToastSummary = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <p className="d-inline-block mb-0">
            <FontAwesomeIcon
              icon={faFaceFrown}
              className="faceIcon"
              title="I have concerns or need help"
            />
            <FontAwesomeIcon
              icon={faFaceSmile}
              title="It's going well so far"
              className="ms-2 me-3 faceIcon"
            />
            How are you doing today?
          </p>
        </div>
      </div>
    );
  };

  const showMoodMeterToast = () => {
    setMoodMeterOverlayClosable(true);
    moodMeterToast?.current?.show({
      severity: "warn",
      summary: showMoodMeterToastSummary(),
      closable: false,
      sticky: true,
    });
  };

  return (
    <>
      <HeaderSection />
      <div className="row mx-0">
        <div className="col-md-12 px-0">
          <HomePageCarousel
            profileData={profileData}
            displayName={displayName}
            isBirthDay={isBirthDay}
            isAnniDay={isAnniDay}
            expYears={expYears}
            isSSOLogin={isSSOLogin}
            displayBasic={displayBasic}
            dialogFuncMap={dialogFuncMap}
          />
        </div>
      </div>

      <div className="timeline-demo mb-4 mt-2 me-5">
        <Timeline
          // value={events1}
          value={
            asttProfileData?.asttResult?.astt?.pizzaTrackerResponse?.stages
              ?.stage
          }
          layout="horizontal"
          marker={marker2}
          content={(item) => item.status}
          className="me-5 ms-5"
        />
      </div>

      <div className="container-fluid px-5 heightMin100vh">
        <div className="row row-cols-1 row-cols-md-2 g-4 ">
          <div className="col">
            <h2 className="text-start ps-4 pb-4 pb-md-5">My Profile</h2>
            <Profile></Profile>
          </div>
          <div className="col">
            <h2 className="text-start ps-4 pb-4 pb-md-5">My Resources</h2>
            <Resources></Resources>
          </div>
        </div>

        <div className="clearfix">&nbsp;</div>

        <div className="row mt-md-5">
          <div className="col-md-12 mt-md-5 pt-md-3">
            <TaskSection></TaskSection>
          </div>
        </div>
      </div>

      <FooterSection />

      <Dialog
        header="How are you doing today ?"
        visible={moodMeterOverlay}
        onHide={() => {
          onHide("moodMeterOverlay");
          showMoodMeterToast();
        }}
        style={{ minWidth: "17vw" }}
        className="shadow-lg moodMeterModal"
        closable={moodMeterOverlayClosable}
      >
        <hr className="mt-0" />

        <div className="row mt-2">
          <div className="col-12 text-center" id="moodMeterQuestions">
            {showHideMoodMeterIcons && (
              <>
                <FontAwesomeIcon
                  icon={faFaceSmile}
                  className="faceIconModal faceSmile"
                  data-pr-tooltip="It's going well so far"
                  title="It's going well so far"
                  data-pr-position="bottom"
                  onClick={(e: any) => {
                    setMoodMeterValue(e?.target?.viewportElement?.textContent);
                    onSubmitMoodMeterHappy();
                    moodMeterToast.current.clear();
                  }}
                />
                <Tooltip target=".faceSmile" />

                <FontAwesomeIcon
                  icon={faFaceFrown}
                  data-pr-tooltip="I have concerns or need help"
                  title="I have concerns or need help"
                  className="ms-4 faceIconModal faceFrown"
                  data-pr-position="bottom"
                  onClick={(e: any) => {
                    setMoodMeterOverlayContent(true);
                    setMoodMeterValue(e?.target?.viewportElement?.textContent);
                    setShowHideMoodMeterIcons(false);
                    moodMeterToast.current.clear();
                  }}
                />

                <Tooltip target=".faceFrown" />
              </>
            )}

            {moodMeterOverlayContent && (
              <div
                className="float-left mt-4"
                style={{
                  display:
                    moodMeterValue === "I have concerns or need help"
                      ? "block"
                      : "none",
                }}
              >
                {displayErrorMessage && (
                  <div className="row mb-3">
                    <div className="col-md-12 concernErrorMessage">
                      <Message
                        severity="error"
                        text="Please provide your concerns to proceed."
                        className="w-100 d-block text-start"
                        style={{
                          borderLeft: "5px solid #ff5757",
                          fontSize: "1rem",
                        }}
                      />
                    </div>
                  </div>
                )}
                <InputTextarea
                  placeholder="Please provide your concerns...."
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  rows={4}
                  cols={100}
                  onKeyPress={(event) => onKeypress(event, "subject")}
                  onPaste={(event) => onPaste(event)}
                />{" "}
                <p className="mt-4 text-start">
                  Your answer will be sent to your supervisor. If you have
                  concerns or challenges, you can always talk to
                  <br /> your supervisor{" "}
                  {" " +
                    profileData?.oracle?.manager?.manager_first +
                    " " +
                    profileData?.oracle?.manager?.manager_last +
                    "," +
                    " " +
                    profileData?.oracle?.manager?.manager_email}
                  .
                </p>
                <p className="text-start">Have a good rest of the day!</p>
                <div className="d-flex">
                <Button
                  label="Submit"
                  className="mt-3 d-inline-block"
                  onClick={() => {
                    onSubmitMoodMeterSad();
                    setRemarks("");
                    /* setMoodMeterOverlayContent(false); */
                  }}
                 
                />
                <Button
                  label="Submit Anonymously" 
                  className="mt-3 ms-2"
                  onClick={() => {
                    onSubmitMoodMeterSadAnon();
                    setRemarks("");
                    /* setMoodMeterOverlayContent(false); */
                  }}
                 
                />
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <Toast
        ref={moodMeterToast}
        className="moodMeterToast"
        onClick={() => {
          dialogFuncMap["moodMeterOverlay"](true);
          moodMeterToast.current.clear();
          setShowHideMoodMeterIcons(true);
          setMoodMeterOverlayContent(false);
        }}
      />
    </>
  );
}
