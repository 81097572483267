import React from 'react';
import { useIsAuthenticated } from "@azure/msal-react";

export default function Footer() {
    const isAuthenticated = useIsAuthenticated();
    return (
        <div className="d-none">
            {isAuthenticated &&
                <div style={{ backgroundColor: "#FFF" }} className="footerBoxShadow">
                    {isAuthenticated &&
                        <div className="container">
                            <footer className="pt-3 my-4">
                                <p className="text-center">© 2022 TTEC</p>
                            </footer>
                        </div>
                    }
                </div>
            }
        </div>
    );
}