import "./managerProfile.scss";
import { profile } from "./managerProfileSlice";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


const Popup = (isVisible: any, onClose: () => void) => {
    const getmanagerProfileData = useSelector(profile);
    const [managerImage, setManagerImage] = useState("");
    
    useEffect(() => {
        setManagerImage(
            getmanagerProfileData?.profileUpdate?.managerprofile || "./images/accountDefaultImage.png"
        );
    }, [getmanagerProfileData?.profileUpdate?.managerprofile]);


    return (

        <div className="row">
            <div className="col-md-4 mt-8">
                <div className="row">
                    <div className="col-md-12">
                        <div className="image-cropper mt-md-4 rounded-0">
                            <img src={managerImage} className="img-fluid" alt="..." />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8 mt-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 mt-4 text-break-all">
                                <strong>Full Name </strong> :{" "}
                                {getmanagerProfileData?.profileUpdate?.nhDtlView?.root?.FIRST_NAME}{" "}
                                {getmanagerProfileData?.profileUpdate?.nhDtlView?.root?.LAST_NAME}
                            </div>
                            <div className="col-md-12 mt-2 text-break-all">
                                <strong>Email </strong> :{" "}
                                {getmanagerProfileData?.profileUpdate?.nhDtlView?.root?.WORK_EMAIL}
                            </div>
                            <div className="col-md-12 mt-2 text-break-all">
                                <strong>Location </strong> :{" "}
                                {getmanagerProfileData?.profileUpdate?.nhDtlView?.root?.LOCATION_CODE_DESC}
                            </div>
                            <div className="col-md-12 mt-2 text-break-all">
                                <strong>Phone Number </strong> :{" "}
                                {getmanagerProfileData?.profileUpdate?.nhDtlView?.root?.MOBILE_PHONE}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
};

export default Popup;
