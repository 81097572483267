import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import React, { useState, useEffect, useRef } from 'react';
import { Avatar } from 'primereact/avatar';

import FooterSection from '../../shared-components/footerSection';
import HeaderSection from '../../shared-components/headerSection';
import './viewEmail.scss';
import { DataScroller } from 'primereact/datascroller';
import { replyEmail, selectReplyEmail } from './replyEmailSlice';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { inboxEmail, selectInboxEmail } from '../inbox/inboxEmailSlice';
import { useParams,	Link, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { updateEmail } from '../inbox/updateEmailSlice';
import { empProfile, empProfileDetails } from '../../ProfileLanding/Profile/ProfileSlice';


export function ViewOGEmail(props: any) {

    const [text1, setText1] = useState<any>('');
    const [textCount, setTextCount] = useState<any>(0);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState([]);
    const [newMessage, setNewMessage] = useState<any>([]);
    const { status, state } = useSelector(selectReplyEmail);
    const [sendReplyStatus, setSendReplyStatus] = useState(false);
    const dispatch = useDispatch();
    const [oracleId, setOracleId] = useState("0");
    const { inboxData } = useSelector(selectInboxEmail);
    const params = useParams();
    const navigate = useNavigate();
    const toast = useRef<any>(null);
    const { profileData } = useSelector(empProfileDetails);
    const [empImage, setEmpImage] = useState("");

    

    const header = (
        <span className="ql-formats">
        </span>
    );


    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Message sent', life: 3000 });
    }


    const handleSubmitReply = (event:any) => {
        event.preventDefault();
        if(oracleId !== null && oracleId !== undefined && oracleId !== "0"){
        const data = {
            "input": {
                "request": {
                    "action": "Email",
                    "emailType": "ReplyToEmail",
                    "senderEmpUid": newMessage?.user_emp_id,
                    "receiverEmpUid": newMessage?.receiver_emp_id,
                    "emailSubject": newMessage?.email_subject,
                    "emailContent": text1,
                    "orMessageUid": newMessage?.or_message_uid,
                    "orMessageDetailUid": newMessage?.emailDetails[0].OR_Message_detail_uid,
                    "oracleId": oracleId
                }
            }
        }
        dispatch<any>(replyEmail(data));
        setSendReplyStatus(true);
    }
    };




    useEffect(() => {
        if (state === 'fulfilled' && status === '200' && sendReplyStatus === true ){
        showSuccess ()
        setSendReplyStatus(false);
        setTimeout(() => {
            navigate("/messages")
       }, 3000);
        
        }
      }, [props, sendReplyStatus, state, status,navigate]);


      useEffect(() => {
        const SSOToken = localStorage.getItem("AccessToken");
        const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
        let id: any;
        if (SSOToken) {
          id = localStorage.getItem("oracleId");
        }
        if (nonSSOToken) {
          const decodedToken: any = jwt_decode(nonSSOToken);
          id = decodedToken?.oracleID;
        }
        setOracleId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [localStorage.getItem("oracleId")]);
    
     
    
      useEffect(() => {
        if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
             const data = {
                "input": {
                    "request": {
                        "action": "Email",
                        "emailType": "EmailInbox",
                        "userOracleId": oracleId
                    }
                }
            };
            dispatch<any>(inboxEmail(data));
        }
      }, [dispatch, oracleId]);



      useEffect(() => {
        setMessage(inboxData);
        if (inboxData) {
            dispatchProfile()
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inboxData]);
    
      useEffect(() => {
        const unreadMessages:any = message.filter((item:any) => item.emailMain.or_message_uid === params.id );

        if(unreadMessages){
        setNewMessage(unreadMessages[0]?.emailMain);
        setMessages(unreadMessages[0]?.emailMain?.emailDetails.slice(0).reverse());
        if(oracleId !== null && oracleId !== undefined && oracleId !== "0"){
        if(unreadMessages[0]?.emailMain?.emailDetails !== undefined){
            // updateStatus()
            const data = {
                "input": {
                    "request": {
                        "action": "Email",
                        "emailType": "UpdateEmailStatus",
                        "orMessageDetailUidList": [unreadMessages[0]?.emailMain?.or_message_uid,unreadMessages[0]?.emailMain?.emailDetails[0]?.OR_Message_detail_uid],
                        "emailStatusUpdate": "read",
                        "oracleId": oracleId,
                    }
                }
            }
    
    
            if (unreadMessages[0]?.emailMain?.status === "unread" && unreadMessages[0]?.emailMain?.emailDetails[0]?.sender_oracle_id !== oracleId ) {
            dispatch<any>(updateEmail(data));
            }
            }
        }

        }
        // eslint-disable-next-line
      }, [message,oracleId,params.id]);

      const dispatchProfile = () => {
        if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
          const requestdata = {
            request: {
              action: "getEmpDetails",
              oracleId: oracleId,
              astt: "N",
            },
          };
          dispatch<any>(empProfile(requestdata));
        }
    };
      

      useEffect(() => {
        setEmpImage(
          profileData?.image?.row?.image || "../../images/accountDefaultImage.png"
        );
      }, [profileData?.image?.row?.image]);

      const onkeyup = (e: any) => {
        let keycode = e.which | e.keycode;
        if(keycode === 8)
        {
          setText1(e.target.innerHTML)
        }

    }

    const onkeypress = (e: any) => {
        let key = e.key;
        const arr = ["!","#","$","%","^","&","*","(",")","-","_","+","=",";",":","'","\"","<",">","/","|","\\","{","}","[","]","~","`"];
        // Check if key pressed is a special character
        if (arr.includes(key)) {
            // Restrict the special characters
            e.preventDefault();
            return false;
        }
        else {
                setText1(e.target.innerHTML);
        }
    }

    const onPaste = (e: any) => {
        e.preventDefault();
    }
  


    const itemTemplate = (data:any) => {
        const datetime = data.sent_on.replace('T', " ").replace('Z', "");
        const email_message = data.email_content.replace('<\\', "<").replace('<\\/p', "<//p");
        const parse = require('html-react-parser');
        const email_content = parse(email_message) ;


        if (data.sender_oracle_id !== oracleId) {

        return (

                <div className='singleConversation mt-3 mb-4'>
                    <Avatar icon="pi pi-user" className="float-start imgshadow mb-3 me-3" size="xlarge" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: '70px', height: '70px' }} />
                    <p><strong>{data.sender_full_name} - ({data.sender_email})</strong></p>
                    <span className='card-text'>{email_content}</span>
                    <div className='clearfix'></div>
                    <p className="card-text text-end"><small className="text-muted">{datetime}</small></p>
                </div>

            );
        }


                if (data.sender_oracle_id === oracleId) {
                return (
        
                <div className='singleConversation mt-3 mb-4'>
                   <Avatar image= {empImage} className="float-end imgshadow mb-3 ms-3" size="xlarge" style={{ backgroundColor: '#2196F3', width: '70px', height: '70px' }} />
                    <p className='text-end'><strong>{data.sender_full_name} - ({data.sender_email})</strong></p>
                    <span className='card-text text-end'>{email_content}</span>
                    <div className='clearfix'></div>
                    <p className="card-text"><small className="text-muted">{datetime}</small></p>
                </div> 
        
            );
        }
        


        
    }


    return (

        <>
            <HeaderSection />

            <div className='container-fluid px-5 main-container mb-5'>

                <h1 className="display-5 mt-3">Messages</h1>
                <hr />
                <>
            <div className='row'>
            {/* { state === "pending" && <Spinner />} */}
                <form className="Auth-form" onSubmit={handleSubmitReply} >
                    <div className='col-md-12'>
                        <div className="card border-0 mt-2 bg-transparent">
                            <div className="card-body p-0">
                                <div className='row mt-2'>
                                    <div className='col-md-12'>
                                        <div className="card shadow-lg rounded">
                                            <div className="card-header"><strong>{newMessage?.email_subject}</strong></div>
                                            

                                            <div className="card-body" style={{ maxHeight: '350px', overflow: 'auto', display:'flex', flexDirection:'column-reverse',} }  >
                                                 <div className="card">
                                                     <DataScroller value={messages} itemTemplate={itemTemplate} rows={5} inline />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-12'>
                                        <p className="float-end me-4" style={{ paddingTop: "10px" }}><strong>{textCount + "/1000"}</strong></p>
                                        <Editor headerTemplate={header} placeholder="Enter your message" style={{ height: '100%', maxHeight: '320px', overflow: 'auto' }} value={text1} maxLength={1000} onTextChange={(e) => [setText1(e.htmlValue), e.textValue.length - 1 <= 1000 ? setTextCount(e.textValue.length - 1) : setTextCount(1000)]}  onKeyPress={(e: any) => onkeypress(e)} onPaste={(e: any) => onPaste(e)} onKeyUp={(event : any) => onkeyup(event)} className="shadow-lg rounded" />
                                    </div>
                                </div>
                                {<small id="username-help" className="d-inline-block ps-2 mt-3" style={{ fontWeight: 'bold' }}>Maximum characters allowed are 1000.</small>}


                                <div className='row mt-4'>
                                    <div className='col-md-12'>
                                        <Button label="Reply" type="submit" className="p-button-success rounded"  disabled={state === ("pending" ? true : false) || !text1}/>
                                        <Link to='/messages'><Button label="Cancel" className="p-button-outlined p-button-success rounded ms-3"  /></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            </>
            </div>
            <Toast ref={toast} />

            <FooterSection />
        </>
    )
}