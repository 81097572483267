import { loginRequest,msalConfig } from '../app/components/Auth/mslconfig/MslConfig';
import {
    PublicClientApplication,
    IPublicClientApplication,
    Configuration,
    AccountInfo,
    EndSessionRequest,
} from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { AADStorage, TokenType } from './aad.storage';
import { MIDDLE_WARE_BASE_URL, NON_SSO_LOGIN_REFRESH_TOKEN_API, CALL_PPOR_API } from './CONSTANTS';
import jwt_decode from "jwt-decode";
import axios from "axios";

let REQUEST_SCOPE=process.env.REACT_APP_REQUEST_SCOPE?process.env.REACT_APP_REQUEST_SCOPE:"";

export const handleLoginPopup = (instance: IPublicClientApplication) => {
    return instance.acquireTokenPopup(loginRequest);
};

export const handleLoginRedirect = (instance: IPublicClientApplication) => {
    instance.loginRedirect(loginRequest);
};

export const getInstance = (iconfig?: Configuration) => {
    const config = iconfig ? { ...msalConfig, ...iconfig } : msalConfig;
    return new PublicClientApplication(config);
};

export const showLoader = () => {
    const node : any = document.querySelector('.divSpinner');
    node.removeAttribute("style");
};

export const hideLoader = () => {
   const node : any = document.querySelector('.divSpinner');
   node.style.display = "none";
    };

export const acquireTokenSilent = async (
    instance: IPublicClientApplication,
    accounts: AccountInfo[], isTokenRefresh : boolean = false
): Promise<AADStorage | Error> => {
    const accessTokenRequest = {
        scopes: [REQUEST_SCOPE],
        account: accounts[0],
    }
    let result: AADStorage | Error;

    try {
        
        const resultToken: AuthenticationResult =
            await instance.acquireTokenSilent(accessTokenRequest);
            if(!isTokenRefresh){
            await setLoginAudit("Bearer "+ resultToken.accessToken + '',"Login");
            }
            const tokenType: TokenType = {
            accessToken: resultToken.accessToken,
            expiresOn: resultToken.expiresOn,
            account: resultToken.account,
        };
      
        result = new AADStorage();
        result.set(tokenType);
        return Promise.resolve(result);
    } catch (error) {
        logout(instance,accounts);
        return Promise.reject(new Error());
    }
};

export const setLoginAudit = async (accessToken : any, action : any) => {
    if(accessToken)
    {
     const decodedToken : any =  jwt_decode(accessToken);

     const data = {
      "request" : {
        "action" : action,
        "emailID" : decodedToken.upn
      }
    }

    let req = JSON.stringify(data).toString();
    const replaceStr = /"/gi;
    req = req.replace(replaceStr, '\\"');
    req = '"' + req + '"';
    const headers = {
        "Content-Type" : 'application/json',
        "Authorization": accessToken as string
      }
     const response : any = await axios.post(MIDDLE_WARE_BASE_URL() + CALL_PPOR_API() , req, { headers : headers}).then(function (response) {
        let json = JSON.stringify(response?.data?.data?.response);
        json = json.replace(/\\"/gi, '"').replace('"{', "{").replace('}"','}');
        localStorage.setItem("oracleId",JSON.parse(json)?.response?.oracleID);
        localStorage.setItem("UserRole",JSON.parse(json)?.response?.userRole);
        window.location.reload();
        return (JSON.parse(json)?.response?.oracleID);
      }
    ).catch(function (error) { throw error });
     return response;
    }
}

export const logout = (
    instance: IPublicClientApplication,
    accounts: AccountInfo[]
) => {
    const sessionRequest: EndSessionRequest = {
        account: accounts[0],
        postLogoutRedirectUri: "/",
    };
    setLoginAudit(localStorage.getItem("AccessToken"),"Logout");
    const aadStogare: AADStorage = new AADStorage();
    aadStogare.clear();
    instance.logoutRedirect(sessionRequest);
};


export const refreshToken = async(): Promise<any> => {
  const token : any = localStorage.getItem("AccessToken-NonSSO");
  if(token)
  {
  const decodedToken : any =  jwt_decode(token);
  if(decodedToken.Type === "TNS")
  {
  let date = new Date("1970-01-01 00:00:00:000");
  date.setSeconds(date.getSeconds() + decodedToken.exp);
  const dateString = date.toISOString();
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(dateString);
  const utcDate = new Date(localDate.getTime() - userOffset);
  const currentDateTime = new Date();
  const tokenExpDateTime = new Date(utcDate);
  var isTokenExpired = (currentDateTime >= tokenExpDateTime);
  if(isTokenExpired){
      try{
  const accessToken = localStorage.getItem("AccessToken-NonSSO");
  const refreshToken =  localStorage.getItem("RefreshToken");

    const data ={
            "accessToken" : accessToken,
            "refreshToken" : refreshToken
         }

    const headers = {
        "Content-Type" : 'application/json',
        "Authorization": accessToken as string
      }
     const response = await axios.post(MIDDLE_WARE_BASE_URL() + NON_SSO_LOGIN_REFRESH_TOKEN_API() , data, { headers : headers});

     if(response?.data?.accessToken){
        localStorage.setItem("AccessToken-NonSSO",  response?.data?.accessToken);
        localStorage.setItem("RefreshToken", response?.data?.refreshToken);
        const decodedToken: any = jwt_decode(response?.data?.accessToken);
        localStorage.setItem("UserRole", decodedToken.userRole);
    }
       return Promise.resolve();
    } catch (error) {
        localStorage.removeItem("AccessToken-NonSSO");
        localStorage.removeItem("RefreshToken");
        localStorage.removeItem("UserRole");
        window.location.reload();
        return Promise.reject(
            new Error('checkTokenValidity Something went wrong')
        );
    }
    }
   }
}
}

export const checkTokenValidity = async (): Promise<any> => {
    let res = new AADStorage();
    var exp =res?.get()?.expiresOn?.valueOf();
   if(exp){
    const timeLeftMs: number = exp- new Date().valueOf();
    const timeLeftMinutes = Math.round(timeLeftMs / 60000);
    const hasSavedExpiredTokens = timeLeftMinutes <= 0;
    const token = localStorage.getItem("AccessToken");
  
    try {
        if (hasSavedExpiredTokens) {
          const accounts = JSON.parse(localStorage.getItem("AAD Account") || '{}');
          const account=[];
          account.push(accounts);
          if(token){
             await acquireTokenSilent(getInstance(),account, hasSavedExpiredTokens);
          }
            // this.setSelf(resultToken);
            return Promise.resolve();
        }

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(
            new Error('checkTokenValidity Something went wrong')
        );
    }
}
};