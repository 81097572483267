import { AccountInfo } from '@azure/msal-browser';
//import { AAD_ENUM } from './enum.token';

export interface TokenType {
    accessToken: string | null;
    expiresOn: Date | null;
    account: AccountInfo | null;
}

let instance: AADStorage;

export class AADStorage {
    constructor() {
        if (!instance) {
            instance = this;
        }
    }

    get() {
        const xpry = localStorage.getItem("TokenExpireTime");
        const acc = JSON.parse(localStorage.getItem("AAD Account") || '{}');


        const token: TokenType = {
            accessToken: localStorage.getItem("AccessToken"),
            expiresOn: xpry ? new Date(xpry) : new Date(),
            account: acc,
        };
        return token;
    }

    set(token: TokenType) {
        localStorage.setItem("AccessToken", "Bearer "+token.accessToken + '');
        localStorage.setItem("TokenExpireTime", token.expiresOn ? token.expiresOn.toString() : '');
        localStorage.setItem("AAD Account", JSON.stringify(token.account));
    }

    clear() {
        localStorage.removeItem("AccessToken");
        localStorage.removeItem("TokenExpireTime");
        localStorage.removeItem("AAD Account");
        localStorage.removeItem("persist:counter"); 
        localStorage.removeItem("oracleId");       
        localStorage.removeItem("preferredName");    
        localStorage.removeItem("UserRole");       
    }

    validateToken(): boolean {
        return true;
    }
}



