import { createContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { HOME } from '../../../utils/CONSTANTS';
import jwt_decode from "jwt-decode";
import axios from "axios";
import { MIDDLE_WARE_BASE_URL, CALL_PPOR_API } from '../../../utils/CONSTANTS';

type authContextType = {
  login: (data: any) => void;
  nonSSOlogout: () => void;
};

const authContextDefaultValues: authContextType = {
  login: () => { },
  nonSSOlogout: () => { },
};

type Props = {
  children: ReactNode;
};

const AuthContext = createContext<authContextType>(authContextDefaultValues);

export const AuthContextProvider = ({ children }: Props) => {

  const navigate = useNavigate();

  const login = async (data: any) => {
    if (data.accessToken) {
      localStorage.setItem("AccessToken-NonSSO", data.accessToken);
      localStorage.setItem("RefreshToken", data.refreshToken);
      const decodedToken: any = jwt_decode(data.accessToken);
      localStorage.setItem("UserRole", decodedToken.userRole);
     // window.location.reload();
      navigate(HOME);
      localStorage.setItem("ShowWelcomeVideo",decodedToken.isFirstLogin); 
    }
  }

  const nonSSOlogout = () => {
    const token: any = localStorage.getItem("AccessToken-NonSSO");
    if (token) {
      const decodedToken: any = jwt_decode(token);
      const data = {
        "request": {
          "action": "Logout",
          "emailID": decodedToken.email
        }
      }

      let req = JSON.stringify(data).toString();
      const replaceStr = /"/gi;
      req = req.replace(replaceStr, '\\"');
      req = '"' + req + '"';
      const headers = {
        "Content-Type": 'application/json',
        "Authorization": token as string
      }     
      axios.post(MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(), req, { headers: headers });
      localStorage.removeItem("AccessToken-NonSSO");
      localStorage.removeItem("RefreshToken");
      localStorage.removeItem("oracleId");    
      localStorage.removeItem("UserRole");  
      localStorage.removeItem("preferredName");     
      localStorage.removeItem("ShowWelcomeVideo");     
      window.location.reload();
      //navigate(LOGIN);  
    }
  }

  const value = { login, nonSSOlogout };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};


export default AuthContext;