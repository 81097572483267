import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../utils/axios";
import {
  CALL_PPOR_API,
  MIDDLE_WARE_BASE_URL,
} from "../../../../utils/CONSTANTS";

const initialState = {
  louiehtmlContent: '',
  louieLinkState: '',
  louieLinkhtml: null,
};

export const louieLinkData: any = createAsyncThunk(
  "get/LouieLinkData",
  async (data: any) => {
    const response = axios.postStringRequest(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);

const louieLinkDataSlice = createSlice({
  name: "louieLinkData",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(louieLinkData.pending, (state) => {
        state.louieLinkState = "pending";
      })
      .addCase(louieLinkData.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        const parse = require('html-react-parser');
        
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }
        const email_content = parse(tempRes?.response?.submitted_content) ;
        state.louiehtmlContent =email_content;
        state.louieLinkhtml = tempRes;
        state.louieLinkState = "fulfilled";
        
      })
      .addCase(louieLinkData.rejected, (state) => {
        state.louieLinkState = "failed";
      })
});

export const getLouieLinkData = (state: any) => state.louieLinkData;

export default louieLinkDataSlice.reducer;
