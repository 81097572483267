/* eslint-disable no-useless-concat */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { empProfileDetails, empProfile } from "./ProfileSlice";
import { asttEmpProfile, asttEmpProfileDetails } from "./asttProfileSlice";
import { profile, ManagerDialogueBox } from "../../profilePage/managerProfileSlice";
import "./profile.scss";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Popup from "../../profilePage/ManagerDialogueBox";


const Profile = () => {
  const { profileData, state } = useSelector(empProfileDetails);
  const { asttProfileData } = useSelector(asttEmpProfileDetails);
  const [oracleId, setOracleId] = useState("0");
  const dispatch = useDispatch();
  const [empImage, setEmpImage] = useState("");

  const footerContent = (
    <div>
      <Button label="OK" onClick={() => setVisible(false)} autoFocus />
    </div>
  );
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestdata = {
        request: {
          action: "getEmpDetails",
          oracleId: oracleId,
          astt: "N",
        },
      };
      dispatch<any>(empProfile(requestdata));
    }
  }, [dispatch, oracleId]);

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestdata = {
        request: {
          action: "getASTT",
          // userOracleId: "3419371",
          userOracleId: oracleId,
        },
      };
      dispatch<any>(asttEmpProfile(requestdata));
    }
  }, [dispatch, oracleId]);

  useEffect(() => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      const requestdata = {
        request: {
          action: "managerprofile",
          oracleId: oracleId,
        },
      };
      dispatch<any>(ManagerDialogueBox(requestdata));
    }
  }, [dispatch, oracleId]);
  const managerProfileData = useSelector(profile);

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);

  let empStatus;
  if (profileData?.oracle?.employee?.employee_status === "Active") {
    empStatus = (
      <span className="badge bg-success float-end mt-3 mt-md-0">
        {profileData?.oracle?.employee?.employee_status}
      </span>
    );
  } else if (
    asttProfileData?.asttResult?.astt?.asttResponse?.root?.OracleStatus ===
    "Active Assignment"
  ) {
    empStatus = (
      <span className="badge bg-success float-end mt-3 mt-md-0">
        {asttProfileData?.asttResult?.astt?.asttResponse?.root?.OracleStatus}
      </span>
    );
  } else {
    <span className="badge bg-danger float-end">
      {profileData?.oracle?.employee?.employee_status}
    </span>;
  }

  let roleNPosition = profileData?.oracle?.employee?.designation
    ? profileData?.oracle?.employee?.designation
    : "";
  const role = roleNPosition.split(".");

  const date = new Date();
  const USDateFormat = new Date(
    profileData?.oracle?.employee?.hire_date
      ? profileData?.oracle?.employee?.hire_date
      : ""
  );
  const options: any = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = USDateFormat.toLocaleDateString("en-US", options);

  let oracleDate = new Date(profileData?.oracle?.employee?.hire_date);

  let managerRoleNPosition = profileData?.oracleManager
    ? profileData?.oracleManager
    : "";
  const managerRole = managerRoleNPosition.split(".");

  useEffect(() => {
    setEmpImage(
      profileData?.image?.row?.image || "./images/accountDefaultImage.png"
    );
  }, [profileData?.image?.row?.image]);

  const date1 = date;
  const date2 = oracleDate;

  const diff = moment(date1).diff(moment(date2));
  const duration = moment.duration(diff);

  const expYears = duration.years();
  const expMonths = duration.months();
  const expDays = duration.days();
  let finalTimePeriod;

  if (expYears === 0) {
    if (expMonths === 0) {
      if (expDays === 0) {
        finalTimePeriod = "First day";
      } else if (expDays === 1) {
        finalTimePeriod = expDays + " Day";
      } else {
        finalTimePeriod = expDays + " Days";
      }
    } else if (expMonths === 1) {
      if (expDays === 0) {
        finalTimePeriod = expMonths + " Month, ";
      } else if (expDays === 1) {
        finalTimePeriod = expMonths + " Month, " + expDays + " Day";
      } else {
        finalTimePeriod = expMonths + " Month, " + expDays + " Days";
      }
    } else {
      if (expDays === 0) {
        finalTimePeriod = expMonths + " Months, ";
      } else if (expDays === 1) {
        finalTimePeriod = expMonths + " Months, " + expDays + " Day";
      } else {
        finalTimePeriod = expMonths + " Months, " + expDays + " Days";
      }
    }
  } else if (expYears === 1) {
    if (expMonths === 0) {
      if (expDays === 0) {
        finalTimePeriod = expYears + " Year, ";
      } else if (expDays === 1) {
        finalTimePeriod = expYears + " Year, " + expDays + " Day";
      } else {
        finalTimePeriod = expYears + " Year, " + expDays + " Days";
      }
    } else if (expMonths === 1) {
      if (expDays === 0) {
        finalTimePeriod = expYears + " Year, " + expMonths + " Month, ";
      } else if (expDays === 1) {
        finalTimePeriod =
          expYears + " Year, " + expMonths + " Month, " + expDays + " Day";
      } else {
        finalTimePeriod =
          expYears + " Year, " + expMonths + " Month, " + expDays + " Days";
      }
    } else {
      if (expDays === 0) {
        finalTimePeriod = expYears + " Year, " + expMonths + " Months, ";
      } else if (expDays === 1) {
        finalTimePeriod =
          expYears + " Year, " + expMonths + " Months, " + expDays + " Day";
      } else {
        finalTimePeriod =
          expYears + " Year, " + expMonths + " Months, " + expDays + " Days";
      }
    }
  } else {
    if (expMonths === 0) {
      if (expDays === 0) {
        finalTimePeriod = expYears + " Years, ";
      } else if (expDays === 1) {
        finalTimePeriod = expYears + " Years, " + expDays + " Day";
      } else {
        finalTimePeriod = expYears + " Years, " + expDays + " Days";
      }
    } else if (expMonths === 1) {
      if (expDays === 0) {
        finalTimePeriod = expYears + " Years, " + expMonths + " Month";
      } else if (expDays === 1) {
        finalTimePeriod =
          expYears + " Years, " + expMonths + " Month, " + expDays + " Day";
      } else {
        finalTimePeriod =
          expYears + " Years, " + expMonths + " Month, " + expDays + " Days";
      }
    } else {
      if (expDays === 0) {
        finalTimePeriod = expYears + " Years, " + expMonths + " Months, ";
      } else if (expDays === 1) {
        finalTimePeriod =
          expYears + " Years, " + expMonths + " Months, " + expDays + " Day";
      } else {
        finalTimePeriod =
          expYears + " Years, " + expMonths + " Months, " + expDays + " Days";
      }
    }
  }

  // useEffect(() => {
  //   console.log(
  //     "xxxxxxxxxx",
  //     asttProfileData?.asttResult?.astt?.asttResponse?.root
  //   );
  // }, [asttProfileData]);
  const handleMouseEnter = () => {
    // Show some text on hover
  };

  const handleMouseLeave = () => {
    // Hide the text when mouse leaves
  };
  return (
    <div className="card h-100 shadow-lg heightAuto">
      {oracleId !== null &&
        oracleId !== undefined &&
        oracleId !== "0" &&
        state !== "pending" && (
          <div className="card-body px-4">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="image-cropper mt-md-4 rounded-0">
                      <img src={empImage} className="img-fluid" alt="..." />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    {profileData?.oracle?.employee?.first_name ||
                      asttProfileData?.asttResult?.astt?.asttResponse?.root
                        ?.FirstName ? (
                      <h5 className="card-title mt-3 mt-md-3">
                        {profileData?.oracle?.employee?.first_name +
                          " " +
                          profileData?.oracle?.employee.last_name ||
                          asttProfileData?.asttResult?.astt?.asttResponse?.root
                            ?.FirstName +
                          " " +
                          asttProfileData?.asttResult?.astt?.asttResponse
                            ?.root?.LastName}
                        {empStatus}
                      </h5>
                    ) : (
                      ""
                    )}
                    <div className="row mt-5 mt-md-4">
                      {profileData?.oracle?.employee?.designation ? (
                        <div className="col-md-6 text-break-all">
                          <strong>Role</strong> : {role[1]}
                        </div>
                      ) : (
                        ""
                      )}
                      {profileData?.oracle?.employee?.designation ? (
                        <div className="col-md-6 mt-3 mt-md-0 ps-md-3 text-break-all">
                          <strong>Position</strong> : {role[0]}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row mt-3">
                      {profileData?.oracleAyehuDb?.work_email ? (
                        <div className="col-md-12 text-break-all">
                          <strong>Email</strong> :{" "}
                          {profileData?.oracleAyehuDb?.work_email}
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="row mt-3">
                      {profileData?.oracle?.employee?.department ? (
                        <div className="col-md-12 text-break-all">
                          <strong>Department</strong> :{" "}
                          {profileData?.oracle?.employee?.department}
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="row mt-3">
                      {profileData?.oracle?.employee?.client_name ||
                        asttProfileData?.asttResult?.astt?.asttResponse?.root
                          ?.ClientName ? (
                        <div className="col-md-6 text-break-all">
                          <strong>Client</strong> :{" "}
                          {asttProfileData?.asttResult?.astt?.asttResponse?.root
                            ?.ClientName ||
                            profileData?.oracle?.employee?.client_name}
                        </div>
                      ) : (
                        ""
                      )}
                      {profileData?.oracle?.employee?.program_name ||
                        asttProfileData?.asttResult?.astt?.asttResponse?.root
                          ?.ProgramName ? (
                        <div className="mt-4 mt-md-0 col-md-6 text-break-all">
                          <strong>Program</strong> :{" "}
                          {profileData?.oracle?.employee?.program_name ||
                            asttProfileData?.asttResult?.astt?.asttResponse
                              ?.root?.ProgramName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row mt-3">
                      {profileData?.oracle?.employee?.project ||
                        asttProfileData?.asttResult?.astt?.asttResponse?.root
                          ?.ProjectName ? (
                        <div className="col-md-12 text-break-all">
                          <strong>Project</strong> :{" "}
                          {profileData?.oracle?.employee?.project ||
                            asttProfileData?.asttResult?.astt?.asttResponse
                              ?.root?.ProjectName}
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {/* {profileData?.oracle?.manager?.manager_first &&
                        profileData?.oracleManager ? (
                        <div className="col-md-12">
                          <strong>Report To</strong> :{" "}
                          {" " +
                            profileData?.oracle?.manager?.manager_first +
                            " " +
                            profileData?.oracle?.manager?.manager_last +
                            " (" +
                            profileData?.oracle?.manager?.manager_email +
                            ") - " +
                            managerRole[1]}
                        </div>
                      ) : (
                        ""
                      )} */}
                    {/* {profileData?.oracleManager ? (
                        <div className="col-md-6 mt-3 mt-md-0 ps-md-3">
                          <strong>Role</strong> : {managerRole[1]}
                        </div>
                      ) : (
                        ""
                      )} */}
                    <div className="row mt-3">
                      {profileData?.oracle?.manager?.manager_first && managerProfileData?.profileState === "fulfilled" &&
                        profileData?.oracleManager ? (
                        <div className="col-md-12">
                          <strong>Report To</strong> :{" "}
                          <span onClick={() => setVisible(true)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            title="Click here to see more details"
                            style={{
                              color: "var(--bs-link-color)",
                              cursor: "pointer",
                            }}
                          >
                            {profileData?.oracle?.manager?.manager_first}{" "}
                            {profileData?.oracle?.manager?.manager_last}
                          </span>{" "}
                          ({profileData?.oracle?.manager?.manager_email}) -{" "}
                          {managerRole[1]}
                        </div>
                      ) : null}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <Dialog
              header="Manager Profile"
              visible={visible}
              style={{ width: "40vw" }}
              onHide={() => setVisible(false)}
              footer={footerContent}
            >
              <Popup></Popup>
            </Dialog>
            <div className="row">
              <div className="col-md-12">
                {asttProfileData?.asttResult?.astt?.asttResponse?.root
                  ?.OGName ? (
                  <div className="row mt-3">
                    {asttProfileData?.asttResult?.astt?.asttResponse?.root
                      ?.OGName ? (
                      <div className="col-md-12 text-break-all">
                        <strong>Onboarding guide</strong> :
                        {" " +
                          asttProfileData?.asttResult?.astt?.asttResponse?.root
                            ?.OGName +
                          " " +
                          "(" +
                          asttProfileData?.asttResult?.astt?.asttResponse?.root
                            ?.OGEmail +
                          ")"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {profileData?.oracle?.recruiter?.recruiter_first ? (
                  <div className="row mt-3">
                    {profileData?.oracle?.recruiter?.recruiter_first ? (
                      <div className="col-md-12 text-break-all">
                        <strong>Recruited by</strong> :
                        {" " +
                          profileData?.oracle?.recruiter?.recruiter_first +
                          " " +
                          profileData?.oracle?.recruiter?.recruiter_last +
                          " " +
                          "(" +
                          profileData?.oracle?.recruiter?.recruiter_email +
                          ")"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row mt-4 ps-2">
              {profileData?.oracle?.employee?.hire_date ? (
                <div className="col-12 col-md-4 text-break-all">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#04AA6D",
                      fontWeight: "600",
                    }}
                    className="ps-1"
                  >
                    Hire Date
                  </span>
                  <span
                    style={{
                      backgroundColor: "#1A2749",
                      color: "#FFF",
                      borderRadius: "6px",
                      display: "flex",
                    }}
                    className="p-3 mt-2"
                  >
                    {formattedDate}
                  </span>
                </div>
              ) : (
                ""
              )}
              {profileData?.oracle?.employee?.hire_date ? (
                <div className="col-12 col-md-4 text-break-all mt-3 mt-md-0">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#04AA6D",
                      fontWeight: "600",
                    }}
                    className="ps-1"
                  >
                    Worked For
                  </span>
                  <span
                    style={{
                      backgroundColor: "#1A2749",
                      color: "#FFF",
                      borderRadius: "6px",
                      display: "flex",
                    }}
                    className="p-3 mt-2"
                  >
                    {finalTimePeriod}
                  </span>
                </div>
              ) : (
                ""
              )}
              {profileData?.oracle?.employee?.oracle_id ? (
                <div className="col-12 col-md-4 text-break-all mt-3 mt-md-0">
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#04AA6D",
                      fontWeight: "600",
                    }}
                    className="ps-1"
                  >
                    Employee ID
                  </span>
                  <span
                    style={{
                      backgroundColor: "#1A2749",
                      color: "#FFF",
                      borderRadius: "6px",
                      display: "flex",
                    }}
                    className="p-3 mt-2"
                  >
                    {profileData?.oracle?.employee?.oracle_id}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="row mt-3 pb-3">
              {profileData?.oracle?.employee?.hire_date ? (
                <div className="col-4 col-md-4 text-break-all">
                  <span
                    style={{
                      backgroundColor: "#1A2749",
                      color: "#FFF",
                      borderRadius: "6px",
                      display: "flex",
                    }}
                    className="p-3"
                  >
                    {formattedDate}
                  </span>
                </div>
              ) : (
                ""
              )}
              {profileData?.oracle?.employee?.hire_date ? (
                <div className="col-4 col-md-4 text-break-all">
                  <span
                    style={{
                      backgroundColor: "#1A2749",
                      color: "#FFF",
                      borderRadius: "6px",
                      display: "flex",
                    }}
                    className="p-3"
                  >
                    {finalTimePeriod}
                  </span>
                </div>
              ) : (
                ""
              )}
              {profileData?.oracle?.employee?.oracle_id ? (
                <div className="col-4 col-md-4 text-break-all">
                  <span
                    style={{
                      backgroundColor: "#1A2749",
                      color: "#FFF",
                      borderRadius: "6px",
                      display: "flex",
                    }}
                    className="p-3"
                  >
                    {profileData?.oracle?.employee?.oracle_id}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div> */}
          </div>
        )}
      {(oracleId === null ||
        oracleId === undefined ||
        oracleId === "0" ||
        (oracleId !== null &&
          oracleId !== undefined &&
          oracleId !== "0" &&
          state === "pending")) && (
          <div className="card-body clsLoad px-4">Loading...</div>
        )}
    </div>

  );
};


export default Profile;
