import React from 'react';
import { Route, Routes } from "react-router-dom";
import { Home } from "../Home/Home";
import { ResetPassword } from "../Auth/login/resetPassword";
import { NotFound } from "../shared-components/NotFound";
import { HOME, LOGIN, RESET, MYMESSAGES, PROFILE, VIEWMESSAGES, LOAD, IFRAME, MYLOUIETASKS } from "../../../utils/CONSTANTS";
import Login from "../Auth/login/login";
import ProtectedRoute from "../../../routes/protectedRoutes";
import PublicRoute from "../../../routes/publicRoutes";
import { AuthContextProvider } from "../shared-components/authContext";
import { InitialLoadPage } from "./initialLoadPage";
import { MyMessages } from '../myMessages/MyMessages';
import { PrOfilePage } from '../profilePage/profilePage';
import { ViewOGEmail } from '../myMessages/viewEmail/ViewOGEmail';
import { Iframe } from '../iFrame/Iframe';
import MyLouieTasks from "../ProfileLanding/Resources/myLouieTasks";

export default function Header() {

  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route exact path={LOGIN} element={<Login />} />
          </Route>
          <Route element={<ProtectedRoute roleRequired=" "/>}>
            <Route exact path={LOAD} element={<InitialLoadPage />} />
            <Route exact path={HOME} element={<Home />} />
            <Route exact path={PROFILE} element={<PrOfilePage />} />
            <Route exact path={IFRAME} element={<Iframe />} />
            <Route exact path={MYLOUIETASKS} element={<MyLouieTasks />} />
            <Route exact path="*" element={<NotFound />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route exact path={MYMESSAGES} element={<MyMessages />} />
          </Route>
          <Route element={<ProtectedRoute roleRequired="OG" />}>
            <Route exact path={VIEWMESSAGES} element={<ViewOGEmail />} />
          </Route>
          <Route>
            <Route exact path={RESET} element={<ResetPassword />} />
          </Route>
        </Routes>
      </AuthContextProvider>
    </>
  );
}

