import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { welcomeTask } from "./StageSlice";
import { tasksList, tasksListDetails, tasksDetails } from "./TaskSlice";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { getNhtpLinkDetails, Nhtp } from "../Tasks/getNHTPSlice";

import {
  managerListDetails,
  moodMeter,
  moodMeterDetails,
} from "./MoodMeterSlice";
import jwt_decode from "jwt-decode";

import "./task.scss";
import { empProfileDetails } from "../Profile/ProfileSlice";

export function Tasks(props: any) {
  const [MoodMeterdisplayBasic, setMoodMeterDisplayBasic] = useState(false);
  const [moodMeterCheck, setMoodMeterCheck] = useState(false);
  const [ttecValuesCheck, setttecValuesCheck] = useState(false);

  const dispatch = useDispatch();

  const dialogFuncMap = {
    MoodMeterdisplayBasic: setMoodMeterDisplayBasic,
  };
  const NhtpDisplay = useSelector(getNhtpLinkDetails);
  let intialStageId = useSelector(welcomeTask);
  if (intialStageId.length > 1) {
  intialStageId = intialStageId[0]
    ? intialStageId[0].automation_process_employee_uid
    : "";}
    else{intialStageId = intialStageId
      ? intialStageId.automation_process_employee_uid
      : "";}

  const clickedStageIdValue = props.clickedStageIdValue;
  const { profileData } = useSelector(empProfileDetails);

  const [oracleId, setOracleId] = useState("0");

  useEffect(() => {
    const SSOToken = localStorage.getItem("AccessToken");
    const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
    let id: any;
    if (SSOToken) {
      id = localStorage.getItem("oracleId");
    }
    if (nonSSOToken) {
      const decodedToken: any = jwt_decode(nonSSOToken);
      id = decodedToken?.oracleID;
    }
    setOracleId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("oracleId")]);
  let firstName = profileData?.automation?.employee?.first_name;
  let lastName = profileData?.automation?.employee?.last_name;
  function b64EncodeUnicode(str: string) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      })
    );
  }
  var unicodeString =
    "oracleID=" +
    oracleId +
    "&firstName=" +
    firstName +
    "&lastName=" +
    lastName;
  var base64EncodedString = b64EncodeUnicode(unicodeString);

  const getLink = () => (
    <a
      href={`https://ph-blank-statutory-emp-form-dev.azurewebsites.net/empform/?${base64EncodedString}`}
      target="_blank"
      rel="noreferrer"
    >
      Complete Statutory Requirements
    </a>
  );

  useEffect(() => {
    let requestdata;
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      if (clickedStageIdValue === 0 || clickedStageIdValue === undefined) {
        requestdata = {
          request: {
            action: "getTasks",
            processEmployeeId: intialStageId,
            oracleId: oracleId,
          },
        };
      } else {
        requestdata = {
          request: {
            action: "getTasks",
            processEmployeeId: clickedStageIdValue,
            oracleId: oracleId,
          },
        };
      }
    }
    dispatch<any>(tasksList(requestdata));
  }, [dispatch, intialStageId, clickedStageIdValue, oracleId]);

  const listsdata = useSelector(tasksListDetails);
  const taskArray = Array.isArray(listsdata) ? listsdata : [listsdata];


  let [value, setValue] = useState(null);
  let [remarks, setRemarks] = useState("");
  const [stepId, setStepId] = useState("");
  const [sName, setName] = useState(null);
  const [taskName, settaskName] = useState("");
  const isFirstRender = useRef(true);
  const toast = useRef<any>(null);

  const onClick = (name: any, sid: any, sName: any, taskName: any) => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      if (sName === "Mood_Meter") {
        dialogFuncMap["MoodMeterdisplayBasic"](true);
        setStepId(sid);
        setName(sName);
        if (isFirstRender.current === true) {
          const requestdata = {
            request: {
              action: "moodMeter",
              oracleId: oracleId,
              flag: "getTeamLeader",
              answer: "",
              id: "",
              feedbackVal: "",
            },
          };
          isFirstRender.current = false;
          dispatch<any>(moodMeter(requestdata));
        }
      }
      else if (sName === "NHTP_1" || sName === "NHTP_2" || sName === "NHTP_3" || sName === "Day_1 Readiness Survey_Equipment" || sName === "Day_1 Readiness Survey_Training " || sName === "Day_1 Readiness Survey_System") {
        dialogFuncMap["MoodMeterdisplayBasic"](true);
        setStepId(sid);
        setName(sName);
        settaskName(taskName);
        const requestdata = {
          request: {
            action: "getNhtpLink",
            oracleId: oracleId,
            sched_step_uid: sid,
          },
        };
        dispatch(Nhtp(requestdata));
      }

      else {
        dialogFuncMap["MoodMeterdisplayBasic"](true);
        setStepId(sid);
        setName(sName)
      }
    }
  };
  const onHide = (name: any) => {
    dialogFuncMap["MoodMeterdisplayBasic"](false);
    setValue(null);
  };

  const dialogPopup = (rowData: any) => {
    //  for (let i = 0; i < taskArray.length; i++) {
    return (
      <span
        className={
          rowData.status === "Completed" || rowData.status === "Not-Started"
            ? "text-start"
            : "anchorText text-start"
        }
        onClick={() =>
          rowData.status === "Completed" || rowData.status === "Not-Started"
            ? ""
            : onClick(
              "MoodMeterdisplayBasic",
              rowData?.automation_sched_step_uid,
              rowData?.step_name_uid, rowData?.task
            )
        }

      >
        {rowData?.task}
      </span>
    );
    // }
  };

  const managerData = useSelector(managerListDetails);

  const updateTtecValues = (idS: any) => {
    const submit = {
      request: {
        action: "ttecValues",
        flag: "updateTtecValues",
        oracleId: oracleId,
        id: idS.stepId,
      },
    };
    dispatch<any>(tasksList(submit));
    dialogFuncMap["MoodMeterdisplayBasic"](false);
    setttecValuesCheck(true);
  };
  let ttecValuesData = [useSelector(tasksDetails)];
  const state = ["fulfilled", "failed"];
  if (ttecValuesCheck === true) {
    if (JSON.stringify(ttecValuesData[0]) === JSON.stringify(state[0])) {
      toast.current.show({
        severity: "success",
        summary: "Success Message",
        detail: "Successfully submitted",
        life: 3000,
      });
      window.location.reload();
    } else if (JSON.stringify(ttecValuesData[0]) === JSON.stringify(state[1])) {
      toast.current.show({
        severity: "error",
        summary: "error Message",
        detail: "Something went wrong while submiting",
        life: 3000,
      });
    }
  }
  const updatemoodMeter = (idS: any) => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      if (value) {
        if (
          value !== null &&
          idS !== null &&
          value === "It's going well so far"
        ) {
          const data = {
            request: {
              action: "moodMeter",
              flag: "updateMoodMeter",
              oracleId: oracleId,
              answer: value,
              id: idS.stepId,
              feedbackVal: remarks ? remarks : "no-remarks",
            },
          };
          dispatch<any>(moodMeter(data));
          dialogFuncMap["MoodMeterdisplayBasic"](false);
          setValue(null);
          setRemarks("");
          setMoodMeterCheck(true);
        } else if (
          value !== null &&
          idS !== null &&
          value === "I have concerns or need help" &&
          remarks !== null &&
          remarks !== "" &&
          remarks.charAt(0) !== " "
        ) {
          const data = {
            request: {
              action: "moodMeter",
              flag: "updateMoodMeter",
              oracleId: oracleId,
              answer: value,
              id: idS.stepId,
              feedbackVal: remarks ? remarks : "no-remarks",
            },
          };
          dispatch<any>(moodMeter(data));
          dialogFuncMap["MoodMeterdisplayBasic"](false);
          setValue(null);
          setRemarks("");
          setMoodMeterCheck(true);
        } else if (
          value === "I have concerns or need help" &&
          (remarks === null || remarks === "" || remarks.charAt(0) === " ")
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please provide your concern",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Please select any of the option",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select any of the option",
        life: 3000,
      });
    }
  };

  const submitAnonymously = (idS: any) => {
    if (oracleId !== null && oracleId !== undefined && oracleId !== "0") {
      if (value) {
        if (
          value !== null &&
          idS !== null &&
          value === "It's going well so far"
        ) {
          const data = {
            request: {
              action: "moodMeter",
              flag: "submitAnonymously",
              oracleId: oracleId,
              answer: value,
              id: idS.stepId,
              feedbackVal: remarks ? remarks : "no-remarks",
            },
          };
          dispatch<any>(moodMeter(data));
          dialogFuncMap["MoodMeterdisplayBasic"](false);
          setValue(null);
          setRemarks("");
          setMoodMeterCheck(true);
        } else if (
          value !== null &&
          idS !== null &&
          value === "I have concerns or need help" &&
          remarks !== null &&
          remarks !== "" &&
          remarks.charAt(0) !== " "
        ) {
          const data = {
            request: {
              action: "moodMeter",
              flag: "submitAnonymously",
              oracleId: oracleId,
              answer: value,
              id: idS.stepId,
              feedbackVal: remarks ? remarks : "no-remarks",
            },
          };
          dispatch<any>(moodMeter(data));
          dialogFuncMap["MoodMeterdisplayBasic"](false);
          setValue(null);
          setRemarks("");
          setMoodMeterCheck(true);
        } else if (
          value === "I have concerns or need help" &&
          (remarks === null || remarks === "" || remarks.charAt(0) === " ")
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail: "Please provide your concern",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Please select any of the option",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Please select any of the option",
        life: 3000,
      });
    }
  };

  let moodMeterData = [useSelector(moodMeterDetails)];
  const states = ["fulfilled", "failed"];
  if (moodMeterCheck === true) {
    if (JSON.stringify(moodMeterData[0]) === JSON.stringify(states[0])) {
      toast.current.show({
        severity: "success",
        summary: "Success Message",
        detail: "Successfully submitted",
        life: 3000,
      });
      window.location.reload();
    } else if (JSON.stringify(moodMeterData[0]) === JSON.stringify(states[1])) {
      toast.current.show({
        severity: "error",
        summary: "error Message",
        detail: "Something went wrong while submiting",
        life: 3000,
      });
    }
  }
  const statusBodyTemplate = (rowData: any) => {
    return (
      <span className={`customer-badge status-${rowData?.status}`}>
        {rowData?.status}
      </span>
    );
  };
  const renderFooter = (name: any) => {
    for (let i = 0; i < taskArray.length; i++) {
      if (taskArray[i]?.step_name_uid === "View_TTEC_Values") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );
      } else if (taskArray[i]?.step_name_uid === "Mood_Meter") {
        return (
          <div className="d-flex">
            <Button
              label="Submit"
              aria-label="Submit"
              className="mt-3 d-inline-block"
              onClick={() => updatemoodMeter({ stepId })}
            />
            <Button
              label="Submit Anonymously"
              aria-label="Submit Anonymously"
              className="mt-3 ms-2 d-inline-block"
              onClick={() => submitAnonymously({ stepId })}
            />
          </div>
        );
      } else if (taskArray[i]?.step_name_uid === "View_PC_Installation_Video") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );
      } else if (taskArray[i]?.step_name_uid === "TTEConnect_feedback_survey") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );
      }
      else if (taskArray[i]?.step_name_uid === "View_PC_Installation_Video_1") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );

      }
      else if (taskArray[i]?.step_name_uid === "View_PC_Installation_Video_2") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );

      }
      else if (taskArray[i]?.step_name_uid === "View_PC_Installation_Video_3") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );

      }
      else if (taskArray[i]?.step_name_uid === "View_Okta_Verification_Video") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );

      }
      else if (taskArray[i]?.step_name_uid === "View_Ping_ID_Set_Up_on_Mobile_Device_Video") {
        return (
          <div>
            <Button
              label="OK"
              aria-label="OK"
              className="float-end"
              onClick={() => updateTtecValues({ stepId })}
            />
          </div>
        );

      }
    }
  };
  const onKeypress = (e: any, type: string) => {
    let keycode = e.which || e.keyCode;

    // Check if key pressed is a special character
    if (
      keycode !== 32 &&
      (keycode < 44 ||
        keycode === 45 ||
        keycode === 47 ||
        (keycode > 57 && keycode < 65) ||
        (keycode > 90 && keycode < 97) ||
        keycode > 122)
    ) {
      // Restrict the special characters
      e.preventDefault();
      return false;
    } else {
      if (type === "remarks") {
        const sub = remarks + String.fromCharCode(keycode);
        setRemarks(sub);
      }
    }
  };

  const onPaste = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      {sName === "Mood_Meter" ? (
        <>
          <Dialog
            header="Mood Meter - How are you doing"
            visible={MoodMeterdisplayBasic}
            style={{ width: "60vw" }}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <div
              className="row"
              id="moodMeter"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              <div className="col-12">
                <div className="card border-0">
                  <div className="card-body">
                    <p className="text-center">
                      <img
                        src="./images/57.png"
                        className="img-fluid imageMM"
                        alt="Mood Meter Question"
                      ></img>
                    </p>
                    <p className="card-text mt-5">
                      Hi <b>{managerData?.preferred_name}!</b>
                    </p>
                    <p className="card-text">
                      Your feedback is invaluable in helping us improve the new
                      hire onboarding experience.
                    </p>
                    <p className="card-text">
                      Please take a moment to answer the question below:
                    </p>
                    <div className="row mt-3">
                      <div className="col-12" id="moodMeterQuestions">
                        <p>How are you feeling about your experience so far?</p>
                        <div className="custom-control custom-radio">
                          <div className="field-radiobutton">
                            <RadioButton
                              value="It's going well so far"
                              inputId="moodMeterRadioBtn-1"
                              name="moodMeterRadio"
                              onChange={(e) => setValue(e.value)}
                              checked={value === "It's going well so far"}
                            />
                            <label
                              htmlFor="moodMeterRadioBtn-1"
                              className="text-start ms-3"
                            >
                              🙂 - It's going well so far
                            </label>
                          </div>

                          <div className="field-radiobutton mt-sm-0 mt-3">
                            <RadioButton
                              value="I have concerns or need help"
                              inputId="moodMeterRadioBtn-2"
                              name="moodMeterRadio"
                              onChange={(e) => setValue(e.value)}
                              checked={value === "I have concerns or need help"}
                            />
                            <label
                              htmlFor="moodMeterRadioBtn-2"
                              className="text-start ms-3"
                            >
                              😟 - I have concerns or need help
                            </label>
                          </div>
                        </div>
                        <div
                          className="float-left mt-4"
                          style={{
                            display:
                              value === "I have concerns or need help"
                                ? "block"
                                : "none",
                          }}
                        >
                          <InputTextarea
                            placeholder="Please provide your concerns...."
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            rows={5}
                            cols={100}
                            onKeyPress={(event) => onKeypress(event, "subject")}
                            onPaste={(event) => onPaste(event)}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="mt-4">
                      Your answer will be sent to your Team Lead. If you have
                      concerns or challenges, you can always talk to your Team
                      Lead,{" "}
                      <b>
                        {managerData?.manager_preferred_name},
                        {managerData?.manager_email_work}
                      </b>
                      …in fact, I encourage it!
                    </p>
                    <p className="mt-4">Have a good rest of the day!</p>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_TTEC_Values" ? (
        <>
          <Dialog
            header="TTEC Values: Our Company Values"
            visible={MoodMeterdisplayBasic}
            style={{ width: "60vw" }}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <div
              className="row"
              id="moodMeter"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              <div className="col-12">
                <div className="card border-0">
                  <div className="card-body">
                    <p className="text-center">
                      <img
                        src="./images/TTEC_Values.png"
                        className="img-fluid imageMM"
                        alt="View TTEC Values"
                      ></img>
                    </p>{" "}
                    <p className="card-text mt-5">
                      At TTEC, we aspire to deliver experiences that are simple,
                      inspired and more human for both our clients and
                      employees. We do this, in part, by living our company
                      values.
                    </p>
                    <p className="card-text">
                      TTEC has six values that help us do business ethically
                      with our customers globally.
                    </p>
                    <p className="card-text">
                      Wondering what our values are? You'll discover them in the
                      next section.
                    </p>
                    <p className="card-text">
                      View TTEC Values{" "}
                      <a
                        href="https://spark.adobe.com/page/CDVb13EmoOJu4/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_PC_Installation_Video" ? (
        <>
          <Dialog
            header="View PC Installation Video"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <iframe
              title="PC installation video"
              style={{ width: "60vw", height: "60vw" }}
              src="https://ttecvideos.wistia.com/medias/83hcx8he8o"
            ></iframe>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_PC_Installation_Video_1" ? (
        <>
          <Dialog
            header="View PC Installation Video 1"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <iframe
              title="PC installation video 1"
              style={{ width: "60vw", height: "60vw" }}
              src="https://ttecvideos.wistia.com/medias/83hcx8he8o"
            ></iframe>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_PC_Installation_Video_2" ? (
        <>
          <Dialog
            header="View PC Installation Video 2"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <iframe
              title="PC installation video 2"
              style={{ width: "60vw", height: "60vw" }}
              src="https://ttecvideos.wistia.com/medias/h6nbfz1fpk"
            ></iframe>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_PC_Installation_Video_3" ? (
        <>
          <Dialog
            header="View PC Installation Video 3"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <iframe
              title="PC installation video 3"
              style={{ width: "60vw", height: "60vw" }}
              src="https://ttecvideos.wistia.com/medias/0h0opqreao"
            ></iframe>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_Okta_Verification_Video" ? (
        <>
          <Dialog
            header="View Okta Verification Video"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <iframe
              title="PC installation video 4"
              style={{ width: "60vw", height: "60vw" }}
              src="https://ttecvideos.wistia.com/medias/1csqarq7bk"
            ></iframe>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "View_Ping_ID_Set_Up_on_Mobile_Device_Video" ? (
        <>
          <Dialog
            header="View Ping ID Set Up on Mobile Device Video"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <iframe
              title="PC installation video 5"
              style={{ width: "60vw", height: "60vw" }}
              src="https://ttecvideos.wistia.com/medias/81avdf8vrb"
            ></iframe>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {sName === "Complete_Statutory_Requirements" ? (
        <>
          <Dialog
            header="Philippines Statutory Requirements Form"
            visible={MoodMeterdisplayBasic}
            style={{ width: "40vw" }}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            {getLink()}
          </Dialog>
        </>
      ) : (
        ""
      )}
      {(sName === "NHTP_1" || sName === "NHTP_2" || sName === "NHTP_3") && NhtpDisplay?.getNhtpState === "fulfilled" ? (
        <>
          <Dialog
            header={taskName}
            visible={MoodMeterdisplayBasic}
            style={{ width: "60vw" }}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <div className="col-12">
              <div className="card border-0">
                <div className="card-body">
                  <div>{NhtpDisplay?.getNhtpData}</div>
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        ""
      )}

      {(sName === "Day_1 Readiness Survey_Equipment" || sName === "Day_1 Readiness Survey_Training " || sName === "Day_1 Readiness Survey_System") && NhtpDisplay?.getNhtpState === "fulfilled" ? (
        <>
          <Dialog
            header={taskName}
            visible={MoodMeterdisplayBasic}
            style={{ width: "60vw" }}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <div className="col-12">
              <div className="card border-0">
                <div className="card-body">
                  <div>{NhtpDisplay?.getNhtpData}</div>
                </div>
              </div>
            </div>

          </Dialog>
        </>
      ) : (
        ""
      )}

      {sName === "TTEConnect_feedback_survey" ? (
        <>
          <Dialog
            header="TTEConnect feedback survey"
            visible={MoodMeterdisplayBasic}
            footer={renderFooter("displayMaximizable")}
            onHide={() => onHide("MoodMeterdisplayBasic")}
          >
            <div
              className="row"
              id="moodMeter"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              <div className="col-12">
                <div className="card-body">
                  <p>
                    <a
                      href="https://forms.office.com/Pages/ResponsePage.aspx?id=UJpcXVTIo06wXRY4ixTVs23typP1IGpPvyAswc-VKw9UMlFFNU44U005U1FNUUQ4M0xJRVdPUU5QVC4u"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Click here to take the survey
                    </a>
                  </p>
                </div>
                <p>
                  Note: After completing the survey, please make sure to come
                  back and click on the OK button below.
                </p>
              </div>
            </div>
          </Dialog>
          {/* */}
        </>
      ) : (
        ""
      )}

      <div className="col-md-9">
        <div className="card h-100 border rounded-0">
          <div className="card-body">
            <DataTable value={taskArray} responsiveLayout="scroll">
              <Column
                field="automation_sched_step_uid"
                header="Code"
                hidden
              ></Column>
              <Column field="task" header="Task" body={dialogPopup}></Column>
              <Column field="sched_date" header="Due Date"></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Toast ref={toast} />
    </>
  );
}
