import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { CALL_PPOR_API, MIDDLE_WARE_BASE_URL } from "../../../utils/CONSTANTS";

const initialState = {
  moodMeterState: "pending",
  /* managerData: "",
  moodMeterUpdate: "", */
};

export const moodMeterOnload = createAsyncThunk(
  "get/moodMeter",
  async (data: any) => {
    const response = axios.postStrReqNoLoader(
      MIDDLE_WARE_BASE_URL() + CALL_PPOR_API(),
      data
    );
    return response;
  }
);
const moodMeterOnloadSlice = createSlice({
  name: "moodMeterOnload",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(moodMeterOnload.pending, (state) => {
        state.moodMeterState = "pending";
      })
      .addCase(moodMeterOnload.fulfilled, (state, action) => {
        let tempRes = action.payload.data?.response;
        if (typeof tempRes === "string") {
          tempRes = JSON.parse(tempRes);
        }

        state.moodMeterState = "fulfilled";
        /* state.managerData = tempRes?.response?.ManagerDetails?.row;
        state.moodMeterUpdate = tempRes?.response?.ManagerDetails; */
      })
      .addCase(moodMeterOnload.rejected, (stageState) => {
        stageState.moodMeterState = "failed";
      }),
});

export const managerListDetails = (moodMeterState: any) =>
  moodMeterState.moodMeterOnload;

export default moodMeterOnloadSlice.reducer;
